.rcm-panel .ant-tabs {
  overflow: visible !important;
}

/* To solve calender visibilty issue in diff diff resolutions*/
.rcm-panel .ant-radio-wrapper,
.rcm-panel .ant-checkbox-wrapper {
  font-weight: 800;
  font-size: 16px;
}

.toggle-radio .ant-radio-wrapper,
.toggle-radio .ant-checkbox-wrapper {
  font-weight: 600 !important;
  font-size: 16px;
}

.rcm-panel .ant-checkbox-wrapper {
  color: black;
  align-items: start;
}

/* .rcm-panel  .multi-select-checkbox .ant-checkbox-wrapper{
  align-items: flex-start!important;
} */

.rcm-panel .enrollment-form .add-contact label {
  cursor: pointer !important;
}

/* .rcm-panel .enrollment-form .form-group textarea.not-empty+label {
  top: -5px !important;
} */

.rcm-panel .enrollment-form .form-group .ant-time-picker {
  width: unset !important;
  display: block !important;
}

.rcm-panel .enrollment-form .form-group .ant-time-picker-input {
  border: 1px solid #ffffff !important;
}

.rcm-panel .enrollment-form .form-group .ant-time-picker-input:focus {
  border-color: #fff;
  border-right-width: 0px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.rcm-panel .enrollment-form .form-group .ant-time-picker-input:focus {
  border-color: #fff;
  border-right-width: 0px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(255 255 255);
}

.event-time {
  /* display: -webkit-inline-box;
  max-width: 50px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
  font-weight: bolder;
}

.event-title {
  /* display: -webkit-inline-box;
  max-width: 100px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.rcm-panel .rcm-tools-table .ant-table-row:hover {
  cursor: default;
}

.rcm-panel .survey-question {
  max-width: unset !important;
}

.rcm-panel .survery-question-box>div.type-border {
  border-bottom: 1px solid #e4e1e1 !important;
}

.rcm-panel .answer-yes img,
.rcm-panel .answer-no img {
  width: 20px !important;
  height: 20px !important;
}

.rcm-panel .answer-options .answer-box .icon {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.rcm-panel .answer-options .answer-box {
  width: 100px;
  height: 100px;
}

.rcm-panel .enrollment-form .form-group .ant-time-picker-input[disabled] {
  color: black !important;
  border: unset !important;
}

.table-btn {
  min-width: 100px;
  padding: 5px 20px !important;
  font-weight: 500 !important;
  font-family: 'AvenirLTStdMedium';
}

.rcm-panel .searchDiv input {
  padding-left: 10px !important;
}

.rcm-panel .searchDiv .fa-search {
  padding-right: 10px !important;
}

.rcm-tab input[type='email']:disabled,
.rcm-tab input[type='text']:disabled,
.rcm-tab input[type='number']:disabled,
.rcm-tab textarea:disabled,
.rcm-tab .select__control--is-disabled,
.rcm-tab input[role='combobox']:disabled {
  /* border-bottom: solid 1px rgba(149, 149, 149, 0.5) !important; commented because we do not want border in disable mode */
  margin-bottom: 0.5rem;
}

.rcm-panel .select--is-disabled .select__indicators {
  display: none;
}

.rcm-tab input[type='text']:disabled {
  border-bottom: none !important;
}

.rcm-panel .selected {
  background: linear-gradient(90deg,
      #00f2fe 0%,
      #03effe 2.08%,
      #24d2fe 29.31%,
      #3cbdfe 55.38%,
      #4ab0fe 79.56%,
      #4facfe 100%);
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.0467384);
  border-radius: 40px !important;
  background-size: 200% 100% !important;
  color: white;
  height: auto !important;
  border: none !important;
}

.rcm-panel .normal {
  border: 1px solid #979797;
  box-sizing: border-box;
  /* filter: drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.0467384)); */
  border-radius: 40px !important;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* line-height: 19px; */
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.09625px;

  /* color: #979797; */
}

.rcm-panel .textarea-type {
  min-height: 70px !important;
  width: 100%;
  border: 0;
  border-bottom: solid 1px rgba(149, 149, 149, 0.5);
  border-radius: 0;
  -webkit-appearance: none;
  padding: 8px 30px 8px 0px;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  color: #000;
  font-family: 'AvenirLTStdMedium';
  resize: vertical !important;
}

.rcm-panel .alphanumeric-type {
  min-height: 55px;
  border: 0;
  border-bottom: solid 1px rgba(149, 149, 149, 0.5);
  border-radius: 0;
  -webkit-appearance: none;
  padding: 8px 30px 8px 0px;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  color: #000;
  font-family: 'AvenirLTStdMedium';
  width: 371px !important;
}

.border .alphanumeric-type {
  width: 100% !important;
}

.ant-collapse .ant-collapse .rcm-panel .type-border button {
  margin-left: -5px !important;
}



.ant-collapse .ant-collapse .type-border .print-textarea-component .dictation-space div {
  margin-right: 7px !important;
}

.rcm-question {
  /* color: #959595 !important; */
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}

.rcm-tab .survery-question-box h5 {
  font-size: 16px;
  margin-bottom: 0 !important;
  padding-top: 15px;
  font-weight: 700;
}

.rcm-tab .answer-options .answer-box h6 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.482353px;
  color: #000;
}

.rcm-tab .custom-radio label {
  position: unset !important;
}

.rcm-tab .selectedOption {
  color: #46b4fe;
}

.rcm-panel .screening-tool .assessment-main-title {
  padding: 10px 5px;
  background-color: rgb(70, 180, 254, 0.1);
  text-align: left;
  color: #46b4fe !important;
}

.rcm-panel .screening-tool .assessment-sub-title {
  padding: 10px 5px;
  /* background-color: blue; */
  text-align: left;
  color: #46b4fe !important;
}

.rcm-panel .screening-tool .main-title-text {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 35px;
  /* identical to box height */
  color: #46b4fe;
}

/* 
.mic-icon{
  width: 13px;
} */
.rcm-panel .screening-tool .sub-title-text {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height */
  color: #46b4fe;
}

#assessmentSitecontent .textarea-type {
  width: 100%;
}

.rcm-panel .fc-header-toolbar .fc-toolbar-chunk:first-of-type .fc-button-group {
  width: 280px;
  background: #ffffff !important;
  box-shadow: 0px 11px 16px rgba(0, 0, 0, 0.0620356);
  border-radius: 12px;
  padding: 6px 12px;
}

.rcm-panel .fc .fc-button-primary {
  background-color: #fff;
  border-color: #fff;
  border: none !important;
  color: #8c97b2;
  margin: 0px 5px;
}

.fc .fc-button-primary:focus,
.fc .fc-button:focus {
  box-shadow: unset !important;
}

.fc-today-button {
  background: linear-gradient(90deg,
      #00f2fe 0%,
      #03effe 2.08%,
      #24d2fe 29.31%,
      #3cbdfe 55.38%,
      #4ab0fe 79.56%,
      #4facfe 100%) !important;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.0467384) !important;
  border-radius: 25px !important;
  color: white !important;
  width: 82px !important;
  opacity: 1 !important;
}

.rcm-panel .fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #fff;
  border-color: #fff;
  background: #4ab0fe;
  border-radius: 20px;
}

/* .rcm-panel .fc-button-group {
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 11px 16px rgba(0, 0, 0, 0.0620356);
  border-radius: 12px;
}

.rcm-panel .fc .fc-button-primary {
  color: #8c97b2;
  color: var(--fc-button-text-color, #8c97b2);
  background: #fff;
  background-color: var(--fc-button-bg-color, #fff);
  border-color: #fff;
  border-color: var(--fc-button-border-color, #fff);
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background: #4ab0fe;
  background-color: var(--fc-button-bg-color, #4ab0fe);
  border-color: #4ab0fe;
  border-color: var(--fc-button-border-color, #4ab0fe);
  border-radius: 20px;
} */
.rcm-document-search {
  padding-right: 50px !important;
  border-radius: 26px !important;
  border: none !important;
  color: #bcbcbc !important;
  font-size: 16px !important;
  height: 52px !important;
  box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.04) !important;
  padding-left: 25px !important;
  min-height: 44px !important;
}

.sigContainer {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
  border: 1px gray;
  border-style: dotted;
}

.sigPad {
  /* width: 100%;
  height: 100%; */
  /* width: 400px;
  height: 200px; */
}

.can-buttons {
  /* width: 100%; */
  width: auto;
  height: 30px;
  border: 1px solid ghostwhite;
  background: #4ab0fe;
  color: #ffffff;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.rcm-panel .ant-time-picker-input {
  padding: unset;
}

td.fc-day.fc-past {
  background-color: red !important;
}

.upload-profile-modal .ant-tabs-bar {
  /* width: fit-content; */
}

.upload-profile-modal .ant-tabs-nav-container {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
}

.upload-profile-modal .ant-tabs-nav-container .ant-tabs-nav-wrap {
  margin-bottom: -5px;
  margin-top: -5px;
}

.upload-profile-modal .ant-tabs-nav>div .ant-tabs-tab,
.dashboard-tabcol .ant-tabs-nav>div .ant-tabs-tab {
  flex: 0 0 25%;
}

.upload-profile-modal .ant-tabs-tab-active {
  background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%);
  border: 1px solid linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%);
  background-size: 300% 100%;
  color: #fff !important;
  /* padding: 10px 20px;
  border-radius: 26px; */
}

.rcm-panel .tools-grid {
  display: flex;
  flex-flow: nowrap;
}

.rcm-panel .tools-grid .start-btn {
  font-size: 14px;
  font-weight: 600;
  color: #4ab0fe;
  cursor: pointer;
}

.rcm-panel .tools-grid .delete-btn {
  font-size: 14px;
  font-weight: 600;
  color: #e87a7a;
  cursor: pointer;
}

.rcm-panel .recurring-appointment {
  display: flex;
}

.rcm-panel .recurring-appointment .recurring-block {
  display: flex;
  flex-direction: column;
  margin-right: 2.5rem;
}

.rcm-panel .recurring-appointment .recurring-block .recurring-block-label {
  color: #959595;
  font-size: 16px;
  white-space: nowrap;
}

.rcm-panel .recurring-appointment .recurring-block .recurring-block-value {
  color: #000000;
  font-size: 16px;
}


.rcm-panel .recurring-appointment .recurring-block .bi-week-note {
  background: #e9f1fc;
  padding: 20px;
}

.rcm-panel .recurring-appointment .recurring-block .note-text {
  font-size: 14px;
  line-height: 19px;
  color: #959595;
}

.slick-prev:before,
.slick-next:before {
  color: #bfbfbf;
}

.ant-cascader-menus {
  height: 350px;
  width: 305px;
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-cascader-menu-item {
  padding: 5px 12px;
  line-height: 22px;
  white-space: break-spaces !important;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-bottom: 0.5px solid #f5f5f5;
}

.enrollment-page .ant-tabs-nav>div .ant-tabs-tab {
  /* flex: 1 1; */
  margin: 0 !important;
  padding: 20px 10px !important;
  text-align: center;
  align-items: center !important;
  align-self: unset !important;
  display: flex !important;
  /* width: 230px !important; */
  width: 200px !important;
}

.dimesion-rating {
  font-size: 14px;
  border-radius: 40px;
  color: white;
  height: 20px;
  display: inline-block;
  font-weight: 900;
}

.dimesion-rating-accordian {
  font-size: 14px;
  border-radius: 40px;
  color: white;
  height: 20px;
  display: inline-block;
  font-weight: 900;
}

.rcm-sub-tab-btn {
  color: #46b4fe;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 10px;
  border: solid 1px #46b4fe;
  padding: 3px 30px;
  border-radius: 25px;
  background: white;
}

.rcm-subtab-table-block {
  background: #f8f8f8 !important;
  padding: 25px 10px;
}

.rcm-subtab-table-block .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters,
.rcm-subtab-table-block .ant-table-thead>tr .ant-table-row-cell-last {
  background: #f8f8f8 !important;
  border-bottom: none !important;
}

.rcm-subtab-table-block .ant-table-thead>tr>th {
  background: #f8f8f8 !important;
  border-bottom: 0px !important;
}

.rcm-subtab-table-block .ant-table-cell {
  background: #f8f8f8 !important;
}

/* .rcm-panel .consent-form-main h5 {
  margin-bottom: 0px;
  font-size: 16px;
} */
.rcm-panel .consent-form-main .instruction-type {
  margin-bottom: 0px;
  font-size: 16px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.rcm-panel .consent-form-main .survery-question-box .answer-options {
  margin: 0 0 10px 0 !important;
  /* padding: 0;
    list-style: none; */
}

.rcm-panel .consent-form-main .survery-question-box .answer-options>li {
  margin-bottom: 0px;
}

.individualNotes h5 {
  color: #b8b8b8 !important;
  font-size: 13px !important;
}

.individualNotesEdit h5 {
  color: #b8b8b8;
  font-size: 16px !important;
}

.h5btn {
  color: #4ab0fe !important;
}

.rcm-panel .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: pink !important;
}

.disable-cursor {
  cursor: not-allowed;
}

.edit-fee-schedule {}

.edit-fee-schedule .fee-schedule {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  margin-top: 20px;
}

.edit-fee-schedule .title {
  font-weight: bold;
  font-size: 21px;
  line-height: 29px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}

.edit-fee-schedule .block {
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
}

.edit-fee-schedule .block .label {
  color: #959595;
  font-size: 14px;
}

.edit-fee-schedule .block .value {
  font-size: 16px;
  font-weight: 700;
}

.edit-fee-schedule .block .description {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.custom-drawer .ant-drawer-header {
  background: #4ab0fe !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.0620356) !important;
}

.ant-drawer-title {
  font-size: 22px;
  color: white;
  font-weight: 700;
}

/* .ant-drawer-close {
  color: white;
  font-size: 24px;
  position: absolute;
  top: -13px;
} */
.ant-drawer-body {
  background: #f8f8f8;
}

.service-line {}

.service-line .title {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */
  text-transform: uppercase;
  color: #000000;
}

.service-line .block {
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  padding: 0px 15px;
  border-bottom: 1px solid #d8d8d8;
}

.service-line .block .name {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}

.service-line .block .enrollment {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #828282;
}

.service-line .service-info-block {
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  padding: 10px 15px;
  border-bottom: 1px solid #d8d8d8;
}

.service-line .info-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 5px;
  margin-top: 20px;
}

.service-line .info-block .block {
  display: flex;
  flex-direction: column;
  margin: 0.2rem 0rem;
}

.service-line .info-block .block .label {
  color: #959595;
  font-size: 14px;
}

.service-line .info-block .block .value {
  font-size: 16px;
  font-weight: 700;
}

.rcm-panel .service_line_code .css-1szy77t-control,
.rcm-panel .service_line_code .css-1s2u09g-control,
.rcm-panel .service_line_code .css-bg1rzq-control {
  background-color: #f8f8f8;
}

.rcm-panel .service_line_code .select--is-disabled .select__indicators {
  display: none;
}

.rcm-panel .service_line_code .select--is-disabled .select__single-value {
  font-size: 14px;
}

.ant-picker.ant-picker-borderless {
  border-bottom: solid 1px rgba(149, 149, 149, 0.5) !important;
}

.error-text {
  color: red;
}

.mb-0>a {
  display: block;
  position: relative;
}

.mb-0>a:after {
  content: '\f078';
  /* fa-chevron-down */
  font-family: 'FontAwesome';
  position: absolute;
  right: 0;
}

.mb-0>a[aria-expanded='true']:after {
  content: '\f077';
  /* fa-chevron-up */
}

.ant-drawer-body .form-group {
  display: block !important;
}

.accounting-tab .ant-tabs-nav-wrap,
.rcm-group-tab .ant-tabs-nav-wrap {
  min-width: 185px;
}

.attendees-section .ant-collapse {
  border: unset !important;
}

.attendees-section .ant-collapse-item {
  background-color: #ffffff !important;
  border-radius: 11px !important;
  margin-bottom: 15.99px !important;
}

.attendees-section .ant-collapse-content-box {
  background-color: #ffffff !important;
  border-radius: 11px !important;
}

.attendees-section .ant-collapse>.ant-collapse-item {
  border-bottom: 1px solid #fff;
}

.attendees-section .ant-collapse-content {
  border-top: 1px solid #fff;
  border-radius: 11px;
}

.attendees-section .content-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  display: flex;
  align-items: center;
}

.attendees-section .content-content {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000;
}

/* Reset antd tab padding-left:24px */
.rcm-group-tab .ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane,
.ant-tabs-left>div>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
  padding-left: 0px;
}

/* Group Service Line */
.group-service-detail {}

.group-service-detail .service-detail-header {
  /* background: #F0F9FF; */
  background: rgb(240, 249, 255, 1);
  padding: 15px 25px;
}

.group-service-detail .service-row .css-1szy77t-control,
.group-service-detail .service-row .css-1s2u09g-control,
.group-service-detail .service-row .css-bg1rzq-control,
.group-service-detail .service-detail-header .css-1szy77t-control,
.group-service-detail .service-detail-header .css-1s2u09g-control {
  background-color: unset;
  /* color: #ffffff !important; */
}

.group-service-detail .service-header {
  /* -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: stretch;
  align-items: stretch; */
}

.group-service-detail .service-header .text {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;

  color: #828282;
}

.group-service-detail .service-header .value {
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;

  color: #000000;
}

.group-service-detail .service-detail-body {
  padding: 20px;
}

.group-service-detail .service-detail-body .actions {
  margin-bottom: 10px;
}

.group-service-detail .service-detail-body .actions .item {
  padding: 2px 5px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  color: #009EF7;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.482px;
  text-decoration-line: underline;
}

.group-service-detail .manage-attendees-header {
  background: #e8e8e8;
}

.group-service-detail .manage-attendees {
  display: flex;
  justify-content: space-around;
  padding: 10px 5px 0px 5px;
}

.group-service-detail .manage-attendees .block {}

.group-service-detail .manage-attendees .block .attendees-name {
  font-weight: bold;
  font-size: 16px;
  color: #000000;
}

.group-service-detail .manage-attendees .block .attendees-id {
  font-weight: normal;
  font-size: 14px;
  color: #828282;
}

.group-service-detail .service-row .block .name,
.group-service-detail .manage-attendees .block .name {
  font-size: 12px;
  text-transform: uppercase;
  color: #828282;
}

.group-service-detail .service-row .block .value,
.group-service-detail .manage-attendees .block .value {
  font-weight: normal;
  font-size: 14px;
  color: #000000;
}

.individualNotesEdit .css-1s2u09g-control,
.individualNotesEdit .css-bg1rzq-control {
  background: transparent !important;
}

.rcm-panel .timePickerActive .form-control {
  padding-right: 9px !important;
}

.rcm-panel .service-code {
  background: #f8f8f8;
  height: 100px;
}

.rcm-panel .service-code .code-description-lable {
  color: #959595;
  font-size: 11px;
  margin-bottom: 0px;
  font-weight: 400;
}

.rcm-panel .service-code .code-description-val {
  overflow-wrap: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.rcm-panel .survery-question-box .answer-options>li>span {
  font-size: 16px;
  font-weight: 600;
  background: #f8f8f8;
  display: initial !important;
  border: 1px solid #959595;
  border-radius: 12px;
  padding: 20px;
  transition: all 300ms ease-in-out;
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.rcm-panel .vertical-tabs .ant-tabs-tabpane.ant-tabs-tabpane-active.tab-content.enrollment-form {
  height: unset !important;
}

.individualNotesEdit textarea,
.newclientview textarea {
  resize: vertical !important;
  overflow-wrap: break-word !important;
  /* border-radius: 12px !important; */
  /* border: none !important; */
  padding: 10px !important;
  min-height: 100px !important;
}

/* print-survery-question-page */
#pagewidth {
  margin: 0px auto 0px auto;
  overflow: hidden;
  width: auto;
  float: none;
  position: static;
  overflow: visible;
  /* margin: 0 5%; */
  /* width: 900px ; */
}

#assessmentPagewidth {
  margin: 0px auto 0px auto;
  overflow: hidden;
  width: auto;
  float: none;
  position: static;
  overflow: visible;
  /* margin: 0 5%; */
  /* width: 900px ; */
}

.print-survery-question-page {}

.print-survery-question-page .survey-question {
  max-width: unset !important;
}

@media print {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div {
    color: #000 !important;
    font-weight: bold !important;
  }

  .ant-picker-input>input[disabled] {
    color: #000 !important;
  }

  /* .ant-btn-disabled {
    background-color: #000 !important;
  } */
  .page-break {
    display: block;
    page-break-after: always;
  }

  #pagewidth {
    display: inline;
    margin: 0px auto 0px auto;
    overflow: hidden;
    width: auto;
    float: none;
    position: static;
    overflow: visible;
  }

  #assessmentPagewidth {
    display: inline;
    margin: 0px auto 0px auto;
    overflow: hidden;
    width: auto;
    float: none;
    position: static;
    overflow: visible;
  }

  /* .individualNotes {
    margin: 20px !important;
  } */
  .pageStyle {
    margin: 50px 50px !important;
  }

  .page-break {
    margin-top: 1rem !important;
    display: block !important;
    page-break-before: always !important;
  }

  @page {
    size: portrait;
  }

  /* page-break-after: auto|always|avoid|left|right|initial|inherit; */
}

/* Bundle CSS */
.claims-tab .ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
  padding-left: 0px;
}

.bundle-code-detail .bundle-detail-header {
  /* background: #F0F9FF; */
  background: rgb(240, 249, 255, 1);
  padding: 15px 35px;
}

.bundle-code-detail .bundle-code {
  background: #f2f2f2;
  padding: 0px 0px;
}

.bundle-code-detail .bundle-code-info {
  background: #f8f8f8;
  padding: 15px 35px;
}

.bundle-code-detail .bundle-code-info .text {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* text-transform: uppercase; */

  color: #828282;
}

.bundle-code-detail .bundle-code-info .value {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* text-transform: uppercase; */

  color: #000000;
}

.bundle-code-detail .service-detail-body {
  /* background: #f0f9ff; */
  padding: 15px 30px;
}

.bundle-code-detail .bundle-code-header {
  /* -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: stretch;
  align-items: stretch; */
}

.bundle-code-detail .bundle-code-header .text {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* text-transform: uppercase; */

  color: #828282;
}

.bundle-code-detail .bundle-code-header .value {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* text-transform: uppercase; */

  color: #000000;
}

.bundle-code-detail .service-info-block {
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  padding: 10px 15px;
}

.dropdown-bg .css-1szy77t-control,
.dropdown-bg .css-1s2u09g-control,
.dropdown-bg .css-bg1rzq-control {
  background-color: #f8f8f8;
}

.cw-dashboard {}

.cw-dashboard .daily-schedule h5,
.cw-dashboard .caseload h5,
.cw-dashboard .alerts h5 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;
  color: #000000;
}

.cw-dashboard .custom-header .ant-table-thead>tr>th {
  background: #33a0fc !important;
  color: #fff !important;
  line-height: 20px;
  /* text-align: center; */
}

.cw-dashboard .custom-header .ant-table-thead>tr:nth-of-type(2)>th {
  background: #33a0fc !important;
  color: #fff !important;
  line-height: 1px;
  text-align: center;
  border: 1px solid #e0dcdc;
}

.cw-dashboard .custom-header .ant-table-thead,
.ant-table-row {
  white-space: unset !important;
}

.cw-dashboard .custom-header .ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
  padding: 5px 16px;
  overflow-wrap: break-word;
}

.cw-dashboard .alerts ul.alert-list {
  list-style-type: circle;
  padding-left: 15px !important;
}

.cw-dashboard .alerts ul.alert-list li {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */

  color: #828282;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0px 10px 0px;
}

.cw-dashboard .alerts ul.alert-list li a:hover {
  text-decoration: underline;
}

.dashboard-alert {
  cursor: pointer;
}

.dashboard-alert:hover {
  color: #000000 !important;
}

.rcm-patient-table .select__control {
  border-bottom: 1px solid hsl(0, 0%, 80%) !important;
  /* border-bottom: 0px !important; */
  /* height: 39px !important; */
  height: 60px !important;
  background-color: #f8f8f8 !important;
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  /* border-radius: 9px !important; */
  color: #000;
  font-weight: bolder;
  /* padding-left: 7px; */
}

.print-header {
  display: none !important;
}

.discharge-acc-section {
  background-color: #f8f8f8;
  padding: 25px;
  margin: 0px 25px;
}

.print-textarea-text {
  display: none;
}

@media print {
  .print-header {
    display: block !important;
  }

  #assessmentSitecontent .textarea-type {
    width: 100%;
  }

  .discharge-acc-section {
    background-color: #fff !important;
  }

  .print-textarea-text {
    display: block;
    border-bottom: 1px solid hsl(0, 0%, 80%) !important;
    padding-bottom: 5px;
  }

  .print-textarea-component {
    display: none;
  }
}

.appointment-group-member {
  display: flex;
  align-items: center;
}

.appointment-group-member .group-member-label {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  margin-right: 10px;
}

.appointment-group-member .icon-container {
  border: 2px solid #ededed;
  border-radius: 4px;
  padding: 3px 5px;
  display: flex;
}

.appointment-group-member .icon-container .include {
  display: flex;
  margin-right: 6px;
}

.appointment-group-member .icon-container .include span {
  font-size: 16px;
  color: '#828282' !important;
}

.appointment-group-member .icon-container .exclude {
  display: flex;
  margin-left: 6px;
}

.appointment-group-member .icon-container .exclude span {
  font-size: 16px;
  color: '#828282' !important;
}

.lab-order .enrollment-form .form-group .form-control {
  min-height: 60px;
}

.client-center-info {
  display: none;
}

@media print {
  .client-center-info {
    display: flex;
    justify-content: center;
  }

  .client-center-info .item {
    margin-right: 1.5rem;
  }

  .client-center-info .item .text {
    margin-right: 0.5rem;
  }

  .client-center-info .item .value {
    margin-right: 0.5rem;
  }
}

.sortableHelper {
  z-index: 10;
}

/* payer-info-section */
.payer-info-section {
  /* display: flex; */
}

.payer-info-section .section-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.payer-info-section .section-col .payer-info-item {
  display: grid;
  /* margin-right: 2rem; */
}

.payer-info-section .section-col .payer-info-item .label {
  color: #000000;
  font-weight: 600;
  font-size: 1.2rem;
}

.payer-info-section .section-col .payer-info-item .value {
  color: #000000;
  font-weight: 400;
  font-size: 1rem;
}

/* payer-info-section */
.sortableHelper {
  z-index: 102;
  cursor: grab;
}

/* bed management css */
.bed-management .room-number {
  color: #182838;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  margin-right: 20px;
  width: 247px;
  display: inline-block;
}

.bed-management .room-total {
  color: #182838;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}

.bed-management .room-status {
  color: #7e8299;
  font-size: 14px;
  font-weight: noraml;
}

.available {
  background-color: #e8fff5;
  border-radius: 4px;
  width: 200px;
  height: 130px;
  position: relative;
}

.occupied {
  background-color: #f7f8fa;
  border-radius: 4px;
  width: 200px;
  height: 130px;
  position: relative;
}

.reserved {
  background-color: #fff2df;
  border-radius: 4px;
  width: 200px;
  height: 130px;
  position: relative;
}

.notavailable {
  background-color: #f7f8fa;
  border-radius: 4px;
  width: 200px;
  height: 130px;
  position: relative;
}

.bed-card div:nth-child(3) {
  position: absolute;
  left: 0;
  bottom: 0;
}

.availableStatus,
.occupiedStatus,
.notavailableStatus,
.reservedStatus {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 600;
}

.availableStatus {
  color: #2fe192;
}

.reservedStatus {
  color: #ff9700;
}

.occupiedStatus {
  color: #7e8299;
}

.notavailableStatus {
  color: #7e8299;
}

.bed-drawer .ant-drawer-body {
  background-color: #fff !important;
}

.bed-management .slick-track {
  margin-left: 0px !important;
}

.rmdp-panel-body li .b-deselect {
  color: #000 !important;
}

.rmdp-container {
  width: 100% !important;
}

.rmdp-container input {
  min-height: 60px;
  border: 0;
  border-bottom: solid 1px rgba(149, 149, 149, 0.5);
  border-radius: 0;
  -webkit-appearance: none;
  padding: 8px 30px 8px 0px;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  color: #000;
  font-family: 'AvenirLTStdMedium';
  width: 100% !important;
}

.rolling {
  /* width: 132px;
  height: 32px; */
  left: 1698px;
  top: 511px;
  background: linear-gradient(90deg, #f70068 29.55%, #f76800 95.08%);
  box-shadow: 0px 4px 4px rgba(247, 33, 71, 0.1);
  border-radius: 45px;
  border: none;
  margin: 5px;
  display: inline-block;
  cursor: pointer;
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 26px;
  transition: all 0.3s ease-out;
  white-space: nowrap;
}

.rolling:hover {
  width: 132px;
}

.customImageComponent span {
  display: inline-block;
  border: 5px solid #fff;
}

.customImageComponent .img-selected {
  border: 5px solid #46b4fe;
}

.moods .ant-avatar-lg {
  width: 32px !important;
  height: 32px !important;
}

/* #pdf-download {
  display: none !important;
}
a #pdf-download {
  display: none !important;
} */
#proxy-renderer #pdf-renderer #pdf-download {
  display: none !important;
}

a#pdf-download {
  display: none !important;
}

/* .select--is-disabled .select__indicators {
  display: none;
  border-bottom: none;
}

.select--is-disabled .select__single-value {
  font-size: 14px;
} */
.rcm-patient-table .select__control--is-disabled{
    border-bottom: none!important;
  }