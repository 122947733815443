.appUsers .header .navbar-light .navbar-nav .nav-link {
  color: rgb(190, 221, 241);
  font-size: 16px;
  font-weight: 800;
}
.appUsers .header .navbar-light .navbar-nav .nav-link:hover {
  color: #ffffff;
}
.appUsers .header .navbar-light .navbar-nav li.active .nav-link {
  color: #ffffff;
}

.appUsers .preference-btn .preference-title {
  margin-right: -195px;
  margin-top: -30px;
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.appUsers .preference-btn .preference-link:first-child {
  margin-top: 80px;
  border-radius: 100px 0 0 100px;
  margin-right: -1px;
}
.appUsers .preference-btn .preference-link:last-child {
  margin-top: 80px;
  border-radius: 0 100px 100px 0;
  margin-left: -1px;
}
.appUsers .preference-btn .preference-link {
  font-size: 16px;
  font-weight: 700;
  border: solid 1px #46b4fe;
  margin-right: -60px;
  border-radius: 100px;
  color: #000;
  height: 52px;
  width: 152px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.appUsers {
  background: #e4f4ff !important;
  /* height: 100vh; */
}
.appUsers .bg-blue {
  background-color: #4db6fb;
}
.appUsers .btn-explore {
  margin-left: 1046px;
  margin-top: -159px;
  width: 304px;
  height: 52px;
}
.appUsers .preference-btn .preference-link.active {
  box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.04);
  background-image: linear-gradient(
    to left,
    #00f2fe,
    #03effe 2%,
    #24d2fe 29%,
    #3cbdfe 55%,
    #4ab0fe 80%,
    #4facfe
  );
  color: #fff;
  border-color: transparent;
  border: 0px;
}

.appUsers .preference-btn .preference-link.active:hover {
  color: inherit;
}
