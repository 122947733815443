.modal-content .modal-text {
  /* font-size: 24px;
  font-weight: bold;
  text-align: center; */
  text-transform: lowercase;
}

.modal-content .custom-modal-text {
  /* font-size: 24px;
  font-weight: bold;
  text-align: center; */
  text-transform: initial;
}

.modal-content .modal-text:first-letter {
  text-transform: capitalize;
}

.center-tabs .ant-tabs-bar {
  border-bottom: none !important;
}

.center-tabs .ant-tabs-ink-bar {
  color: #85cdfe !important;
}

.center-style {
  display: flex;
  align-items: center;
}

.dashboard-center {
  /* width: 220px; */
  display: flex;
  align-items: baseline;
  margin-right: 10px;
}

.dashboard-center-2 {
  /* width: 220px; */
  display: flex;
  align-items: baseline;
  margin-right: 10px;
}

.dashboard-center-2 .css-1s2u09g-control,
.dashboard-center-2 .css-bg1rzq-control {
  background-color: #4db6fb;
  color: #ffffff !important;
}

.dashboard-center-2 .css-1szy77t-control {
  background-color: #4db6fb;
  color: #ffffff !important;
}

.dashboard-center-2 .css-dvua67-singleValue .select__single-value,
.dashboard-center-2 .css-1s2u09g-control .select__single-value,
.dashboard-center-2 .css-bg1rzq-control .select__single-value,
.dashboard-center-2 .css-1s2u09g-control .css-16pqwjk-indicatorContainer,
.dashboard-center-2 .css-bg1rzq-control .css-16pqwjk-indicatorContainer {
  color: #000 !important;
  font-size: 16px;
  font-weight: 800;
}

.dashboard-center .select__control {
  /* border-bottom: 1px solid #fff !important; */
  border-bottom: 0px !important;
  height: 39px !important;
  background: #d8d8d8;
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  border-radius: 9px !important;
  color: #000;
  font-weight: bolder;
  /* padding-left: 7px; */
}

.dashboard-center-2 .select__control {
  /* border-bottom: 1px solid #fff !important; */
  border-bottom: 0px !important;
  height: 39px !important;
  background: #f8f8f8;
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  border-radius: 9px !important;
  color: #000;
  font-weight: bolder;
  padding-left: 7px;
}

.center-tabs .ant-tabs-nav-wrap {
  display: inline-block !important;
}

.center-tabs .ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: none;
}

.center-tabs .ant-tabs-tab-active.ant-tabs-tab {
  color: #46b4fe;
  border: 0;
  font-weight: 600;
  position: relative;
}

.vertical-tabs .ant-tabs-tab {
  justify-content: unset;
}

.ant-tabs-tab {
  justify-content: center;
}

.center-tabs .ant-tabs-tab {
  font-size: 16px;
  font-weight: 600;
  padding: 8px 2px !important;
  margin: 0px 12px !important;
}

.center-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  content: '';
  border-radius: 2.5px;
  background-image: linear-gradient(to right,
      #00f2fe,
      #03effe 2%,
      #24d2fe 29%,
      #3cbdfe 55%,
      #4ab0fe 80%,
      #4facfe);
  height: 5px;
  display: block;
  margin-top: 20px;
  transition: all 200ms;
}

.center-tabs .ant-tabs-nav-container {
  line-height: 2;
}

.center-tabs .ant-tabs-nav-scroll {
  display: flex;
  justify-content: center;
}

.badge {
  color: black;
  background-color: #ececec;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-top: -3px;
  padding: 0.5em 0.5em;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  margin-left: 5px;
}

.active-badge {
  color: white;
  background-image: linear-gradient(to right,
      #00f2fe,
      #03effe 2%,
      #24d2fe 29%,
      #3cbdfe 55%,
      #4ab0fe 80%,
      #4facfe);
}

.ant-collapse-header {
  color: #828282 !important;
}

.accordian-tp .ant-collapse-header {
  color: grey !important;
}

.task-head {
  color: black;
}

.student-name {
  color: black;
  margin-left: 10px;
}

.student-status {
  color: #828282;
  margin-left: 10px;
  display: block;
  font-style: normal;
}

.emptyAlert {
  margin-bottom: 10px;
  padding: 4px 11px;
  font-size: 14px;
  width: auto;
  display: inline-block;
  vertical-align: top;
}

.announcements-card .images-placeholder {
  box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.17);
  background-color: #eee;
}

.addnew-button {
  color: #46b4fe !important;
}

.addnew-button:hover {
  color: white !important;
}

.ant-modal-header {
  display: flex;
  justify-content: center;
  border-bottom: none;
}

.ant-modal-footer {
  display: flex;
  justify-content: center;
  border-top: none;
}

.ant-modal-footer .btn {
  min-width: 162px;
  height: 44px;
}

.ant-modal-body {
  padding: 0px !important;
}

.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
  background-color: white !important;
}

.modal-content {
  -webkit-animation-name: none !important;
  -webkit-animation-duration: none !important;
  animation-name: none !important;
  animation-duration: none !important;
  /* margin-top: 10px; */
}

.ant-modal {
  padding-bottom: 0px !important;
}

.vertical-tabs .ant-tabs-nav .ant-tabs-tab {
  color: #b8b8b8;
  font-weight: bold !important;
  font-size: 16px;
  padding: 23px 30px 23px 20px;
  margin: 0;
  text-align: left;
}

.vertical-tabs .ant-tabs-nav .ant-tabs-tab-active {
  background: #fff !important;
  color: #46b4fe !important;
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
}

.vertical-tabs .ant-tabs-content {
  background: #fff !important;
  min-height: 840px !important;
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06) !important;
}

.vertical-tabs .ant-tabs-tabpane.ant-tabs-tabpane-active.tab-content.enrollment-form {
  height: calc(100% + 30px) !important;
}

.vertical-tabs.enrollment .tab-content.enrollment-form {
  padding-right: 24px;
}

.vertical-tabs .ant-tabs-ink-bar {
  background-color: #46b4fe !important;
}

.vertical-tabs .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  left: 1px !important;
}

.vertical-tabs .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  left: 1px !important;
}

.vertical-tabs .ant-tabs-left>.ant-tabs-nav .ant-tabs-ink-bar,
.client-reels .ant-tabs-left>.ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
  width: 2px;
}

.ant-tabs .ant-tabs-left-content {
  padding-left: 0px !important;
}

.ant-tabs.ant-tabs-left.ant-tabs-vertical.ant-tabs-line {
  height: inherit !important;
}

studListBg {
  background-color: #ffffff;
  padding-top: 10px;
  margin-top: 10px;
}

.reduxSelectNew .select__control {
  border: 0px !important;
  border-radius: 0px;
  border-bottom: 1px solid rgba(149, 149, 149, 0.5) !important;
  background-color: #f8f8f8 !important;
  color: black !important;
}

.reduxSelectNew .select__indicator-separator {
  display: none;
}

.ant-table .ant-table-thead,
.ant-table .ant-table-row {
  font-size: 14px;
}

.ant-table-thead>tr {
  background: #fff !important;
}

.ant-table-thead>tr>th {
  color: #828282;
  font-weight: 600;
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background-color: transparent !important;
}

.ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters::before {
  opacity: 0;
}

.paginate_button.next:before,
.paginate_button.previous:before {
  content: '\f060';
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 9px;
}

.paginate_button.next:before {
  content: '\f061';
}

.paginate_button {
  transition: all 300ms ease-in-out !important;
}

.ant-pagination-item {
  background: none !important;
  border: none;
  font-family: 'Nunito', sans-serif;
}

.ant-pagination-item-active .paginate_button {
  background: linear-gradient(to right,
      #00f1fe 0%,
      #4facfe 50%,
      #ebebeb 50%,
      #ebebeb 100%) !important;
  background-size: 200% 100% !important;
  color: #fff !important;
}

.ant-pagination-disabled .paginate_button.previous,
.ant-pagination-disabled .paginate_button.next {
  opacity: 0.5;
  transition: none !important;
  background: #f2f2f2 !important;
  color: black !important;
}

.card-text {
  height: 70px;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
  overflow-y: auto;
  overflow-x: hidden;
}

.student-name {
  /* width: 90px; */
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
}

.course-name {
  width: 225px;
}

.due-date {
  min-width: 200px;
}

.select__control {
  border: 0px !important;
  border-bottom: 1px solid hsl(0, 0%, 80%) !important;
  border-radius: 0px !important;
  height: 60px !important;
}

.select__indicator-separator {
  background-color: unset !important;
}

.enrollment-form .select__placeholder,
.enrollment-form .select__single-value {
  /* color: rgba(0, 0, 0, 0.7) !important; */
  color: #959595 !important;
  font-size: 16px;
  font-family: 'AvenirLTStdMedium';
  font-weight: 500;
  position: absolute;
  top: 15px;
  transition: all 300ms ease-in-out;
  pointer-events: none;
}

.enrollment-form .select__single-value {
  color: #000 !important;
}

.select__single-value {
  top: 5px;
}

.select__single-value {
  color: rgba(0, 0, 0, 0.95) !important;
}

.select__control--is-focused {
  box-shadow: unset !important;
}

/* required for removing href tag and add color to text  */
.enrollment-form .add-contact {
  color: #46b4fe !important;
}

.enrollment-form .delete-contact,
.delete-contract {
  color: #e87a7a !important;
}

.polar {
  padding-top: 75px;
}

.ant-switch-small::after {
  background-color: #adb5bd;
}

.ant-switch {
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.ant-switch.ant-switch-checked {
  /* background-color: #1890ff; */
  border: #ffffff solid 1px;
  /* background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%); */
  background: linear-gradient(to right,
      #00f2fe 0%,
      #03effe 2%,
      #24d2fe 29%,
      #3cbdfe 55%,
      #4ab0fe 80%,
      #4facfe 100%);
}

.show-overdue-survey {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin-right: 5px;
}

.ant-switch-checked::after {
  background-color: #fff;
}

.custom-control-label::before {
  display: none !important;
}

.custom-control-label {
  font-size: 17px;
  color: rgb(0, 0, 0);
}

.form-control {
  font-size: 1.05rem;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.select__menu {
  position: absolute !important;
  z-index: 10 !important;
  height: 180px;
}

.select__menu-list {
  height: inherit;
}

.react-datepicker__input-container .form-control {
  background-color: unset;
}

.react-datepicker-popper {
  z-index: 4;
}

.select__value-container {
  height: -webkit-fill-available;
}

/* .ant-table-row:hover {
  cursor: pointer;
} */

.select__value-container--is-multi {
  overflow: auto !important;
}

.deactivate-link {
  color: #e87a7a !important;
}

.reactivate-link {
  color: #46b4fe !important;
}

.deactivate-link:hover,
.reactivate-link:hover {
  color: black !important;
}


.ant-radio-inner,
.ant-checkbox .ant-checkbox-inner {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  border: solid 1px #bcbcbc;
  /* display: block; */
  cursor: pointer;
  position: relative;
}

.radio-nested-tables .ant-radio-inner,
.radio-nested-tables .ant-checkbox .ant-checkbox-inner,
.nested-tables .ant-radio-inner,
.nested-tables .ant-checkbox .ant-checkbox-inner {
  border-radius: 50% !important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0);
}

.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after,
.ant-checkbox-group-item .ant-checkbox .ant-checkbox-inner:after {
  content: '';
  width: 17px;
  height: 8px;
  border-right: none;
  border-bottom: 3px solid #46b4fe;
  border-left: 3px solid #46b4fe;
  display: block;
  transform: rotate(-45deg);
  position: absolute;
  top: 8px;
  left: 5px;
  /* opacity: 0; */
  transition: all 300ms ease-in-out;
}

.ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #46b4fe;
  border: unset;
  top: 8px;
  left: 9px;
  /* border-bottom: 3px solid #46b4fe; */
  /* border-left: 3px solid #46b4fe;  */
  transform: unset;
}

.ant-checkbox input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.ant-checkbox input[type='checkbox']:checked~.ant-checkbox-inner:after {
  opacity: 1;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: unset;
}

.ant-radio-wrapper,
.ant-checkbox-wrapper {
  font-weight: 800 !important;
  color: #474747;
  display: flex;
  align-items: end;
  margin-bottom: 10px;
}

.lab-test .ant-checkbox-group .ant-checkbox-wrapper {
  font-weight: 600 !important;
  color: #474747;
  display: flex;
  align-items: end;
}

.credit-card-detail .ant-radio-wrapper {
  display: inline-flex;
  align-items: center;
}

.modal-content .ant-radio-inner::after {
  content: '' !important;
  width: 18px !important;
  height: 8px !important;
  border-bottom: 3px solid #46b4fe !important;
  border-left: 3px solid #46b4fe !important;
  display: block !important;
  -webkit-transform: rotate(-45deg) !important;
  transform: rotate(-45deg) !important;
  position: absolute !important;
  top: 8px !important;
  left: 5px !important;
  /* opacity: 0;  */
  -webkit-transition: all 300ms ease-in-out !important;
  transition: all 300ms ease-in-out !important;
  background-color: white !important;
  border-radius: 0px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.ant-radio-checked::after {
  border-radius: 4px;
}

textarea {
  padding-top: 15px !important;
}

.ant-table-thead,
.ant-table-row {
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  color: rgb(33, 37, 41);
  white-space: nowrap;
}

.ant-table-content {
  overflow: auto;
}

.EditImage {
  background: #fff !important;
  border-radius: 0 0 11px 11px !important;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0) !important;
  overflow: hidden;
  margin-bottom: 30px;
  width: 83.99px !important;
}

.EditImage .images-placeholder2 {
  height: 84px !important;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0) !important;
  margin-bottom: 20px !important;
  position: relative !important;
}

.EditImage .EditImageHover {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  transform: translateY(-100%) !important;
  display: flex !important;
  flex-wrap: wrap !important;
  background: rgba(0, 0, 0, 0.71) !important;
  padding: 5px 18px !important;
  justify-content: center !important;
  align-items: center !important;
  opacity: 0 !important;
  transition: all 300ms ease-in-out !important;
  visibility: hidden !important;
  border-radius: 4px !important;
}

.EditImage .EditImageHover .btn {
  width: 100% !important;
  min-width: 0 !important;
  /* margin: 4px 0!important;  */
  font-size: 15px !important;
  font-weight: 500 !important;
  padding: 5px 20px !important;
  color: #fff !important;
  border-color: #fff !important;
  height: 34px !important;
}

.images-placeholder2 {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.EditImage:hover .EditImageHover {
  opacity: 1 !important;
  transform: translateY(0) !important;
  visibility: visible !important;
}

.selected {
  background-image: linear-gradient(to right,
      rgb(0, 241, 254) 0%,
      rgb(79, 172, 254) 50%,
      rgb(235, 235, 235) 50%,
      rgb(235, 235, 235) 100%) !important;
  background-size: 200% 100% !important;
  color: white;
}

.slick-slide .card {
  min-height: 450px !important;
}

.ant-switch {
  margin-right: 5px;
}

.user-role-tabs .ant-tabs-nav {
  width: inherit;
}

.miscellaneous-page-tabs .ant-tabs-nav {
  width: 50%;
}

.call-schedule-tab .ant-tabs-nav-wrap {
  display: inline-block !important;
}

.call-schedule-tab .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-small-bar {
  margin-bottom: 20px !important;
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
}

.call-session-tab .ant-tabs-nav {
  width: auto !important;
}

.call-session-tab .ant-tabs-nav div .ant-tabs-tab {
  flex: 1 !important;
  margin: 0 !important;
  padding: 8px 10px !important;
  align-items: center;
  font-size: 18px;
  background-color: #f8f8f8;
}

.call-session-tab .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-small-bar {
  margin-bottom: 20px !important;
  box-shadow: unset;
}

.call-session-tab .ant-tabs-tab {
  width: unset !important;
  border: 0;
  font-weight: 600;
  color: #828282;
  position: relative;
  height: 51px !important;
  font-size: 16px;
  font-weight: normal !important;
  letter-spacing: 0.1px;
}

.user-role-tabs .ant-tabs-tab {
  width: 100%;
  text-align: center;
  background-color: white;
  padding: 20px 32px 20px 20px !important;
  height: 70px;
  vertical-align: middle;
  font-family: Nunito, sans-serif;
  font-size: 20px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: linear-gradient(to right,
      rgb(0, 241, 254) 0%,
      rgb(79, 172, 254) 50%,
      rgb(235, 235, 235) 50%,
      rgb(235, 235, 235) 100%) !important;
}

.user-role-tabs .ant-tabs-nav-scroll {
  width: inherit;
  overflow: hidden;
}

.user-role-tabs .ant-tabs-nav-wrap {
  overflow: hidden;
  width: 100%;
}

.user-role-tabs .ant-tabs-tab-arrow-show,
.user-role-tabs .ant-tabs-ink-bar {
  display: none !important;
}

.user-role-tabs .ant-tabs-tab {
  border: 0;
  font-weight: 600;
  color: #828282;
  position: relative;
}

.user-role-tabs .ant-tabs-tab-active {
  color: #46b4fe;
}

.user-role-tabs .ant-tabs-tab-active .border-image:after {
  content: '';
  border-radius: 2.5px;
  background-image: -webkit-linear-gradient(left,
      #00f2fe,
      #03effe 2%,
      #24d2fe 29%,
      #3cbdfe 55%,
      #4ab0fe 80%,
      #4facfe);
  background-image: linear-gradient(to right,
      #00f2fe,
      #03effe 2%,
      #24d2fe 29%,
      #3cbdfe 55%,
      #4ab0fe 80%,
      #4facfe);
  width: 30%;
  margin: 0 auto;
  height: 5px;
  display: block;
  -webkit-transition: all 200ms ease-in-out !important;
  transition: all 200ms ease-in-out !important;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.user-role-tabs .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-small-bar {
  margin-bottom: 50px;
}

.user-role-tabs .ant-tabs {
  font-size: 16px !important;
  font-family: 'Nunito', sans-serif !important;
  color: #000 !important;
}

.validate-pin .error-text {
  display: block;
  text-align: right;
  color: #ff3b3b;
  font-size: 14px;
  font-family: 'AvenirLTStdMedium';
}

.survery-question-page .validate-pin .validate-pin-box .text-box {
  margin-bottom: 0px !important;
}

.contact-box {
  max-width: 350px;
  margin: 120px auto 0;
  width: 100%;
}

.contact-box .contact-label {
  font-size: 18px;
  font-weight: 600;
  color: #828282;
}

.contact-box .separator {
  border-top: 1px solid #ededed;
  text-align: center;
  position: relative;
  margin: 40px 0 20px 0;
}

.contact-box .separator>span {
  background: #fff;
  text-align: center;
  display: inline-block;
  position: relative;
  top: -15px;
  padding: 0 15px;
  font-size: 18px;
  color: #828282;
  font-weight: 800;
}

.contact-box .contact-time {
  margin-bottom: 30px;
}

.contact-box .contact-time>h5 {
  font-size: 22px;
}

.contact-box .contact-number {
  background: url(/images/phone.svg) no-repeat 95% center;
  background-size: 26px;
  padding-right: 42px;
}

.contact-box .contact-email {
  background: url(/images/mail.svg) no-repeat 95% center;
  background-size: 26px 20px;
  padding-right: 42px;
}

.contact-box .card-body .link2 {
  font-size: 20px;
  font-weight: 700;
}

.help-videos-grid {
  display: flex;
  margin: 0 10px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.help-videos-grid>.video-col {
  width: calc(33.3% - 10px);
  border-radius: 15px;
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

.help-videos-grid>.video-col iframe {
  border: 0;
  display: block;
  width: 100%;
  height: 244px;
}

.stn-btn {
  min-width: 146px !important;
  padding: 5px 20px !important;
}

.setting-gap {
  padding-top: 25px;
  padding-left: 50px;
}

.help-vid {
  margin: 30px !important;
}

.ant-switch-small.ant-switch .ant-switch-inner {
  color: black;
}

.ant-switch-small.ant-switch.ant-switch-checked .ant-switch-inner {
  color: white;
}

.select__value-container--is-multi {
  /* height: 40px; */
  height: -webkit-fill-available !important;
  /* margin-top: 13px; */
}

.enrollment-form .select__value-container--is-multi {
  margin-top: 13px;
}

/*
.enrollment-form input {
  padding-left: 8px !important;
}

.enrollment-form textarea {
  padding-left: 8px !important;
}*/

button:focus {
  outline: none !important;
}

.students-profile input:disabled,
a[disabled],
button:disabled:not([role='switch']),
.students-tab-col input:disabled {
  display: inline-block !important;
  background: #cccccc;
}

.btn-newCon {
  background: unset;
  border: unset;
  color: #46b4fe;
  font-size: 16px;
  font-weight: 800;
  margin-left: auto;
  display: table;
  text-transform: uppercase;
  position: absolute;
  bottom: 85px;
  display: inline-flex;
  width: 210px;
  white-space: nowrap;
}

.btn-disabled {
  background: unset;
  border: unset;
  color: #7f8188;
  font-size: 16px;
  font-weight: 800;
  margin-left: auto;
  display: table;
  text-transform: uppercase;
  position: absolute;
  bottom: 85px;
  display: inline-flex;
  width: 210px;
  white-space: nowrap;
}

.ant-popover {
  /* position: relative;
      left: 80%!important;
      top: 357px;
     transform-origin: 0px 4px; */
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters {
  background: white !important;
}

.ant-table-thead>tr>th {
  background: white !important;
}

.ant-table-tbody>tr>td.ant-table-column-sort {
  background: white !important;
}

.templates-pub {
  margin: 30px !important;
}

.ant-popover,
.ant-popover-content,
.ant-popover-inner {
  width: 160px;
  margin: 0px !important;
}

.ant-popover-inner-content p:hover {
  cursor: pointer;
}

.students-profile .students-action-button>.btn {
  margin-right: 10px !important;
}

.newcontract-textarea {
  padding-top: 15px !important;
  height: 100px !important;
}

input:disabled,
input[type='email']:disabled,
input[type='text']:disabled,
input[type='number']:disabled,
textarea:disabled,
.select__control--is-disabled,
input[role='combobox']:disabled,
.timePickerDisable .form-control {
  border: unset !important;
  background-color: unset !important;
  margin-bottom: -10px;
}

.disabled-field input[type='text']:disabled {
  border-bottom: 1px solid rgba(149, 149, 149, 0.5) !important;
  cursor: not-allowed !important;
}

input:disabled~.placeholder-label,
textarea:disabled~.placeholder-label {
  font-size: 14px !important;
  top: -5px !important;
}

.toggle-radio .ant-radio-wrapper-checked,
.payment-dashboard .ant-radio-wrapper-checked {
  color: #46b4fe !important;
}

.toggle-radio .text {
  font-weight: 400;
  font-size: 18px;
  color: #182838;
  margin-bottom: 10px;

}

.credit-card-detail .ant-radio {
  top: '0em'
}

.payment-dashboard .ant-radio-inner::after {
  /* width: 15px;
  height: 15px; */
  margin-left: 0;
  margin-top: 0;
  transform: translate(-50%, -50%);
}

.payment-dashboard .ant-radio-inner {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  /* border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px; */
}

.payment-dashboard .ant-radio-checked::after {
  border-radius: 4px;
}

.announcement-card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
  height: 190px !important;
}

.announcement-card-body .card-title {
  min-height: '24px';
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.announcement-card-body .card-body-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #828282;
}

.multi-auto {
  display: inline-block;
}

ul li.multi-auto:not(:last-child) {
  background-color: hsl(0, 0%, 90%);
  margin: 3px 3px;
  padding: 0px 3px;
}

.Activities .ant-tabs-nav-wrap {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
}

/* .Activities .ant-tabs-nav-wrap {
  margin-bottom: -5px;
  margin-top: -5px;
} */
.Activities .ant-tabs-nav>div .ant-tabs-tab,
.dashboard-tabcol .ant-tabs-nav>div .ant-tabs-tab {
  flex: 0 0 25%;
}

.Activities .ant-tabs-tab-active {
  /* background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%);
  border: 1px solid linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%); */
  background-size: 300% 100%;
  color: #fff !important;
  /* padding: 10px 20px;
  border-radius: 26px; */
}

/* subscriber View page */
.subscriber-page .app-img-timeline {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 222px;
  position: relative;
  border-radius: 11px 11px 0px 0px;
  /* background-color: seagreen; */
  /* -webkit-filter: blur(5px); Safari 6.0 - 9.0 */
  /* filter: blur(5px); */
}

.subscriber-page .app-img-timeline div {
  backdrop-filter: blur(5px);
  height: 100%;
  width: 100%;
  border-radius: 11px 11px 0px 0px;
}

.app-img-profile {
  position: absolute;
  top: -52px;
}

.app-img-profile img {
  width: 147px;
  height: 147px;
  border-radius: 50%;
  box-shadow: 0px 15px 14px #f2f2f2;
  border: 2px solid #ffffff;
  object-fit: cover;
  box-shadow: 0px 11px 18px rgba(0, 0, 0, 0.2);
}

.profile-name {
  background-color: white;
  display: flex;
  padding: 0px 35px;
  font-family: 'AvenirLTStdMedium';
}

.profile-name .stu-name {
  font-size: 2em;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.profile-name .stu-duration {
  font-size: 1.3em;
}

.profile-name-logo {
  display: flex;
  justify-content: flex-end;
  color: #1890ff;
  font-size: 1.5em;
  align-items: center;
  margin-left: 60px;
}

.profile-name-logo i {
  margin-right: 10px;
}

.subscriber-page .contact-container {
  background-color: white;
  display: flex;
  padding: 15px 35px 0px;
}

.profile-contact {
  font-size: 1.3rem;
  padding: 30px 15px 0px;
  font-family: 'AvenirLTStdMedium';
}

.subscriber-page .contact-container .profile-contact p {
  border-bottom: 1px solid rgba(184, 184, 184, 0.33);
  padding-bottom: 31px;
}

.view-profile-title {
  font-size: 1.3rem;
  margin: 0px 15px 0px;
  /* padding-top: 10px; */
  font-family: 'AvenirLTStdMedium';
}

.view-profile-title h4 {
  color: #bcbcbc;
}

.view-profile-title p {
  font-weight: 600;
}

.view-profile .post-text {
  font-weight: 700;
  font-size: 19px;
  font-family: 'AvenirLTStdMedium';
}

.view-profile-title .case-detail {
  display: flex;
}

.view-profile-title .timeline-title {
  font-size: 20px !important;
  line-height: 18px;
}

.view-profile-title .case-detail .case-detail-block {
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
}

.view-profile-title .case-detail .case-detail-block .case-detail-label {
  color: #959595;
  font-size: 18px;
}

.view-profile-title .case-detail .case-detail-block .case-detail-value {
  font-size: 18px;
}

.profile-contact span {
  color: #b6b6b6;
  margin-right: 5px;
  padding: 8px;
}

.view-profile .album-list-modal {
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: center center;
}

.view-profile .modal-content .modal-body div {
  display: flex;
  justify-content: center;
}

.view-profile .posts-list-item {
  background-color: rgb(248, 248, 248);
  border: 1px solid rgb(248, 248, 248);
  border-radius: 10px;
  padding: 30px;
}

.view-profile .posts-list-item span {
  color: rgb(158, 158, 158);
  padding: 7px 0px;
  font-size: 14px;
  font-weight: 700;
}

.view-profile .posts-list-item img {
  width: 25%;
  margin: 1px;
}

.view-profile .posts-list-item .modal-container {
  margin-top: 20px;
}

.view-profile .posts-list-item .modal-container input {
  border-radius: 5px;
  border: 2px solid rgb(159, 159, 159);
  background-image: none;
  color: rgb(159, 159, 159);
}

.like-btn-modal-item {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding: 10px 0px;
}

.like-btn-modal-item .frnd-name,
.comment-btn-modal-item .frnd-name {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 700;
  color: rgb(0, 0, 0);
}

.like-btn-modal-item .frnd-badge,
.comment-btn-modal-item .frnd-badge {
  margin-left: 10px;
  padding: 2px 6px;
}

.guide {
  border: 1px solid orange;
  color: orange;
}

.coach {
  border: 1px solid blue;
  color: blue;
}

.comment-btn-modal-item {
  display: flex;
  margin: 10px 0px;
  padding: 10px 0px;
}

.view-profile .friend-list {
  display: flex;
  align-items: center;
  padding: 18.25px 10px;
}

.view-profile .friend-list .f-friend-name-box {
  display: flex;
  justify-content: flex-start;
  flex-basis: 100%;
  align-items: center;
  padding: 0px 15px;
}

.view-profile .friend-list .f-friend-name {
  font-size: 18px;
  font-weight: 800;
  color: rgb(0, 0, 0);
  text-transform: capitalize;
}

.view-profile .friend-list .f-friend-badge {
  font-size: 12px;
  font-weight: 600;
  padding: 3px 15px;
  margin: 0px 1em;
}

/* Chat CSS */
.view-profile .chat-container .c-box {
  cursor: pointer;
  display: flex;
  padding: 18.25px 10px;
  background-color: rgba(158, 158, 158, 0.07);
  border-bottom: 2px solid rgb(46, 202, 254);
  margin-bottom: 10px;
}

.view-profile .chat-container .c-box .c-containt {
  justify-content: flex-start;
  padding: 0px 15px;
  flex: 1 1 0%;
  width: 75%;
}

.view-profile .chat-container .c-box .c-friend-name {
  font-size: 18px;
  font-weight: 800;
  color: rgb(0, 0, 0);
  text-transform: capitalize;
}

.view-profile .chat-container .c-box .c-friend-badge {
  font-size: 12px;
  font-weight: 600;
  padding: 3px 15px;
  margin: 0px 1em;
}

.view-profile .chat-container .c-box .c-last-msg {
  margin-top: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: justify;
  font-weight: 600;
  font-size: 1.1em;
}

.view-profile .chat-container .c-box .c-last-msg-time {
  font-weight: 600;
  font-size: 0.8em;
}

.chatBox {
  border: 1px solid rgb(248, 248, 248);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 1rem !important;
  cursor: default;
}

.c-comment {
  background-color: rgb(248, 248, 248);
  color: #000;
  margin-right: 5px;
}

.c-reply {
  background-color: rgb(0, 121, 253);
  color: #ffffff;
  margin-right: 5px;
}

.chat-title {
  display: flex;
  align-items: center;
  margin-bottom: 0.4em;
}

.chat-status {
  display: flex;
  justify-content: flex-end;
}

.chat-title img {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-right: 0.5em;
}

.chat-title div {
  display: grid;
}

.chat-title .chat-name {
  font-size: 1.1em;
  font-weight: 800;
  text-transform: capitalize;
}

.chat-text {
  text-align: justify;
  font-weight: 600;
  font-size: 1em;
}

.chat-form {
  display: flex;
  align-items: flex-end;
  padding: 30px 5px 5px 5px;
  border-top: 1px solid lightgray;
}

.chat-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.chat-form .form-group .placeholder-label {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: 'AvenirLTStdMedium';
  font-weight: 500;
  position: absolute;
  top: 15px;
  transition: all 300ms ease-in-out;
  pointer-events: none;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.chat-form .form-group .form-control:focus~.placeholder-label,
.chat-form .form-group .form-control.not-empty~.placeholder-label {
  font-size: 14px;
  top: -5px;
}

.chat-form .form-group .form-control {
  min-height: 55px;
  border: 0;
  /* border-bottom: solid 1px rgba(149, 149, 149, 0.5); */
  border-radius: 0;
  -webkit-appearance: none;
  /* padding: 5px 10px 8px 0px; */
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  color: #000;
  font-family: 'AvenirLTStdMedium';
  resize: none;
  overflow: hidden;
}

.manage-req {
  display: flex;
  background-color: #f0f7fb;
  background-position: 9px 0px;
  background-repeat: no-repeat;
  border-left: solid 4px #3498db;
  line-height: 18px;
  overflow: hidden;
  padding: 15px 37px;
  color: #48b2fe;
}

/* Progressssss */

.otherParams .ant-tabs-nav-scroll {
  display: flex;
  justify-content: start;
}

.one-way-slider {
  margin-left: 0px;
  margin-right: 0px;
}

.one-way-slider .ant-slider-track {
  height: 15px !important;
  background-color: #46b4fe !important;
  z-index: 1;
  border-radius: 10px;
}

.one-way-slider .ant-slider-handle {
  display: none;
}

.one-way-slider .ant-slider-rail {
  height: 15px;
  border-radius: 10px;
}

.one-way-slider .ant-slider-step {
  height: 15px;
  border-radius: 10px;
  background-color: #f0f9ff;
}

.one-way-slider .ant-slider-dot {
  display: none;
}

.one-way-slider .ant-slider-mark {
  top: 20px;
}

.one-way-slider .ant-slider-mark-text {
  font-weight: 700;
  font-size: 15px;
}

.one-way-slider .ant-slider-mark-text:not(:last-child):not(:first-child) {
  color: #46b4fe;
}

.one-way-slider .ant-slider-mark-text:first-child {
  margin-left: 5px;
}

.one-way-slider .ant-slider-mark-text:last-child {
  margin-left: -10px;
}

.two-way-slider {
  margin-left: 0px;
  margin-right: 0px;
}

.two-way-slider .ant-slider-track.ant-slider-track-1 {
  height: 15px !important;
  background-color: #46b4fe !important;
  z-index: 1;
}

.two-way-slider .ant-slider-handle {
  background-color: #46b4fe;
  border-color: #46b4fe;
  margin-top: 0px;
  height: 15px;
  font-size: 11px;
  color: #fff;
  line-height: 15px;
  text-align: center;
  z-index: 1;
}

.two-way-slider .ant-slider-handle:before {
  width: 0;
  content: '';
  height: 0;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin: auto;
  height: 9px !important;
}

.two-way-slider .ant-slider-handle.ant-slider-handle-1:before {
  border-bottom: solid 7px #ff5d5d;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

.two-way-slider .ant-slider-handle.ant-slider-handle-2:before {
  border-bottom: solid 7px #12c475;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

.two-way-slider .ant-slider-rail {
  height: 15px;
  border-radius: 10px;
}

.two-way-slider .ant-slider-step {
  height: 15px;
  border-radius: 10px;
  background-color: #f0f9ff;
}

.two-way-slider .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #46b4fe !important;
}

.two-way-slider .ant-slider-handle:focus {
  border-color: #46b4fe !important;
  outline: none;
  -webkit-box-shadow: none;
  background-color: #46b4fe !important;
  box-shadow: none;
}

.two-way-slider .ant-slider-handle.ant-tooltip-open {
  background-color: #46b4fe !important;
  border-color: #46b4fe !important;
}

.otherParams {}

@media (min-width: 480px) and (max-width: 991px) {
  .otherParams .system_observation {
    flex-wrap: wrap;
  }

  .otherParams .system_sec {
    margin-bottom: 20px;
    margin-left: 150px;
    margin-right: 150px;
  }
}

.otherParams .shadow_progress {
  padding: 20px 16px;
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
}

.otherParams h3.system_title {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  padding: 0;
  margin-bottom: 15px;
}

.otherParams .system_observation {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  flex-wrap: wrap;
}

.otherParams .system_sec {
  padding: 25px;
  background-color: #f3f3f3;
  border-radius: 9px;
  flex: 1 auto;
  width: 100%;
  margin-bottom: 20px;
}

.otherParams .system_sec h5 {
  color: #000;
  font-size: 16px;
  margin-bottom: 8px;
  font-family: 'AvenirLTStdMedium';
}

.otherParams .system_sec p {
  color: #000;
  font-size: 14px;
  margin-bottom: 2px;
}

.otherParams .main_progress {
  padding: 58px 16px 40px 16px;
  background-color: #fff;
}

.otherParams .shadow_progress {
  padding: 20px 16px;
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
}

.otherParams .measure {
  padding: 8px 16px;
  /* flex: 1 auto;
  margin-bottom: 14px;
  background-color: #fff;
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
  border-radius: 12px; */
}

.otherParams .other_measure .progress {
  height: 6px;
}

.otherParams .other_measure .progress-label {
  height: 6px;
}

.otherParams .measure h5 {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 32px;
}

.otherParams .other_measure .measure h5 {
  margin-bottom: 27px;
}

.otherParams .progress_bar {
  margin-bottom: 56px;
}

.otherParams .other_measure .progress_bar {
  margin-bottom: 52px;
}

.otherParams .progress_bar p {
  font-size: 13px;
  color: #000;
  font-weight: 700;
  margin-bottom: 2px;
  font-weight: 400;
}

.otherParams .other_measure .progress_bar p {
  margin-bottom: 2px;
}

.otherParams .progress {
  background-color: #f0f9ff;
  height: 14px;
  border-radius: 20px;
  position: relative;
  width: 100%;
  overflow: visible;
}

.otherParams .progress-label {
  background-color: #46b4fe;
  position: absolute;
  left: 0;
  right: 0;
  height: 14px;
  border-radius: 20px;
}

.otherParams .progress-label .progress-label-current-indicator {
  right: 0;
  position: absolute;
}

.otherParams .progress-label .progress-label-start-indicator {
  left: 0;
  position: absolute;
}

.otherParams .progress-label span {
  font-size: 11px;
  color: #fff;
  line-height: 15px;
  text-align: center;
  width: 20px;
}

.otherParams .progress_bar_label {
  position: relative;
  bottom: 23px;
}

.otherParams .progress_bar_label span {
  display: inline-block;
  position: absolute;
  color: #0e0e0e;
  font-size: 16px;
  font-weight: 600;
}

.otherParams .progress_bar_label .progress_bar_label_text {
  left: 0;
}

.otherParams .indicator-text {
  position: relative;
}

.otherParams .indicator-text span {
  display: inline-block;
  position: absolute;
  color: #828282;
  font-size: 13px;
  font-weight: 400;
}

.otherParams .indicator-text span.indicator-left-text {
  left: 0;
  /* top: 15px; */
}

.otherParams .other_measure .indicator-text span.indicator-left-text,
.otherParams .other_measure .indicator-text span.indicator-right-text {
  top: 0;
}

.otherParams .indicator-text span.indicator-right-text {
  right: 0;
  /* top: 15px; */
}

.otherParams .progress-img img {
  position: absolute;
}

.otherParams .progress-img img.red-arrow-img {
  left: 30px;
}

.otherParams .progress-img img.green-arrow-img {
  right: 204px;
}

.otherParams .progress-2 .progress-label {
  left: 0;
  right: 70px;
}

.otherParams .other_measure .progress-label {
  left: 0;
}

.otherParams .progress-label span.progress-label-start-indicator:before {
  width: 0;
  content: '';
  height: 0;
  border-bottom: solid 7px #ff5d5d;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
}

.otherParams .progress-label span.progress-label-current-indicator:after {
  width: 0;
  content: '';
  height: 0;
  border-bottom: solid 7px #12c475;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin: auto;
}

.otherParams .arrow a {
  text-decoration: none;
  font-size: 15px;
  position: relative;
}

.otherParams .arrow a.starting {
  color: #ff5d5d;
  margin-right: 34px;
}

.otherParams .arrow a.current {
  color: #12c475;
}

.otherParams .arrow {
  position: absolute;
  text-align: end;
  top: 33px;
  right: 20px;
}

.otherParams .arrow a.starting:before {
  width: 0;
  content: '';
  height: 0;
  border-bottom: solid 7px #ff5d5d;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  top: 6px;
  left: -15px;
  position: absolute;
}

.otherParams .arrow a.current:after {
  width: 0;
  content: '';
  height: 0;
  border-bottom: solid 7px #12c475;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  top: 6px;
  left: -16px;
  position: absolute;
}

.otherParams .other_measure .progress-label span.progress-label-start-indicator:before,
.otherParams .other_measure .progress-label span.progress-label-current-indicator:after {
  display: none;
}

.otherParams .other_measure .progress-label-current-indicator {
  color: #46b4fe;
  font-weight: 700;
  font-size: 13px;
  top: calc(100% + 4px);
}

.newGrid {
  width: 100%;
  min-height: 50vh;
  background: #fff;
  position: relative;
  display: grid;
  /* grid-template-columns: repeat(8, minmax(100px, 1fr)); */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* grid-template-rows: 1fr 1fr; */
  grid-auto-rows: 200px;
  grid-gap: 0px;
}

.album-modal-close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  position: absolute;
  right: 19px;
  color: black;
  background: white;
  width: 25px;
  height: 25px;
  padding: 5px;
  /* border-radius: 43%; */
  /* margin-right: 1px; */
  top: 20px;
  box-shadow: 3px 4px #6c757d;
}

.album-modal-close i {
  margin: 0 auto;
}

.profile-contact i {
  color: #9e9e9e;
}

.post-text a {
  color: rgba(0, 0, 0, 0.65);
}

.face-fit-cover {
  object-fit: cover;
}

.friends-avatar {
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: center center;
  width: 80px;
  height: 80px;
  box-shadow: rgb(242, 242, 242) 0px 11px 6px;
  max-width: unset;
  flex-shrink: 0;
}

.trigger-view {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.btn-trigger-map {
  border-top-left-radius: 26px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 26px !important;
}

.btn-trigger-list {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 26px !important;
  border-bottom-right-radius: 26px !important;
  border-bottom-left-radius: 0px !important;
}

.btn-trigger-map-off,
.btn-trigger-list-off {
  font-weight: bold;
  border-radius: 26px;
  border: 0;
  color: black;
  padding: 7px 20px;
  line-height: 24px;
  background-size: 300% 100%;
  transition: all 300ms ease-in-out;
  padding-bottom: 9px !important;
}

.trigger-map-btn-simple {
  border-radius: 26px;
  border: 0;
  color: black;
  padding: 7px 0px;
  line-height: 24px;
  background-size: 300% 100%;
  transition: all 300ms ease-in-out;
  margin: 0 10px;
  width: 119px;
  font-size: 14px;
  font-weight: 600;
}

.trigger-map-btn-simple-active {
  border-radius: 26px;
  border: 0;
  color: white;
  padding: 7px 0px;
  line-height: 24px;
  background-size: 300% 100%;
  transition: all 300ms ease-in-out;
  margin: 0 10px;
  width: 119px;
  font-size: 14px;
  background-color: #0079fd;
}

.trigger-content-section {
  overflow: hidden;
  margin-top: 12px;
  border-radius: 10px;
  height: 375px;
  box-shadow: 0px 6px 9px #d4d1d18a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listView-Container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  font-size: 14px;
}

.icon-area {
  height: 30px;
  width: 30px;
  text-align: center;
  display: inline-block;
}

.listView-item {
  display: flex;
  margin-top: 12px;
  background-color: rgb(248, 248, 248);
  border-radius: 14px;
  justify-content: space-between;
  padding: 21px 18px 15px 18px;
  align-items: center;
  cursor: pointer;
}

.subText {
  color: grey;
  display: block;
  font-size: 12px;
}

.modalTriggerTitle {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0 5px;
}

.modalTriggerTitle .head {
  display: flex;
}

.modalTriggerHeader {
  margin-bottom: 15px;
}

.modalTriggerBody {
  /* overflow-y: scroll; */
  padding-right: 10px;
}

.modalTriggerTitle .dateTime,
.dateTimeHeader {
  display: flex;
  margin: 10px 0px;
  border-bottom: 1px solid #88888838;
  justify-content: space-between;
  padding-bottom: 10px;
  text-align: center;
}

.modalTriggerTitle .dateTime div,
.dateTimeHeader div {
  flex-grow: 0;
  flex-basis: 25%;
}

.modalTriggerTitle .dateTime div:first-child {
  color: black;
  font-weight: 700;
  font-size: 16px;
}

.myMap {
  height: 370px;
  width: 100%;
  margin: 0px auto;
}

.custom-marker {
  color: rgb(49, 198, 254);
  /* padding: 15px 10px; */
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  /* background: white; */
  /* width: 50px;
  height: 50px; */
  font-size: 12px;
  cursor: pointer;
}

.custom-marker .number-circle {
  background: #ff5d5d;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px 6px;
  border: 2px solid #ff5d5d;
  color: #fff;
  text-align: center;
  font: 14px Arial, sans-serif;
  position: absolute;
  top: -15px;
  left: 32px;
}

.map-info-popup {
  position: absolute;
  bottom: 0;
  left: -405px;
  color: white;
  width: 315px;
  height: auto;
  background-color: #0079fd;
  border-radius: 11px;
  padding: 9px 11px;
  font-size: 14px;
  cursor: pointer;
}

.map-info-popup .subText {
  color: #a4c1e0;
  text-align: left;
  display: block;
  font-size: 12px;
}

.map-info-popup .popText {
  color: #ffffff;
  font-size: 11px;
  padding-left: 5px;
  margin-top: 3px;
}

.map-info-popup .popText2 {
  color: #ffffff;
  font-size: 12px;
  padding-left: 5px;
  margin-top: 3px;
}

.antdDate-modifier .ant-calendar-picker-input {
  border-radius: 26px !important;
  background-color: #f0f0f0;
  color: #000000;
  height: 39px;
  border: unset;
}

.antdDate-modifier ::placeholder {
  color: black;
  font-size: 14px;
  font-weight: 600;
}

.cft {
  margin-left: 0px !important;
}

.modalListView .ant-modal {
  width: 650px !important;
}

.cus-mod-wid {
  width: 630px !important;
}

.btn-eForm-Next:focus,
.btn-eForm-Prev:focus {
  -webkit-transition: all 0.3s ease-in-out !important;
  -moz-transition: all 0.3s ease-in-out !important;
  -ms-transition: all 0.3s ease-in-out !important;
  -o-transition: all 0.3s ease-in-out !important;
  outline: none !important;
  border: 2px solid #e87a7a !important;
}

.roleClass {
  border: 0;
  border-bottom: solid 1px rgba(149, 149, 149, 0.5);
  border-radius: 0;
  -webkit-appearance: none;
  padding: 8px 30px 8px 0px;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  color: #000;
  font-family: 'AvenirLTStdMedium';
  width: 134px;
}

.main-head {
  font-weight: normal;
  font-size: 35px;
  line-height: 48px;
  /* identical to box height */

  text-align: center;

  color: #474747;
}

.main-head2 {
  display: inline-block;
}

.form-main-head {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.roleHeader {
  display: inline-block;
}

.course-filter {
  flex-wrap: wrap;
}

.course-filter label {
  width: 156px !important;
  margin-bottom: 11px;
}

.filter-modal .filter-status {
  display: flex;
  font-weight: bold !important;
  color: #000000;
  font-size: 18px;
}

.filter-modal .ant-radio-wrapper .ant-radio+span {
  color: #474747;
  font-size: 14px;
  font-weight: bold;
}

.ant-modal-confirm-body-wrapper {
  padding: 32px 32px 24px !important;
}

.addStaff1 .react-phone-number-input__input,
.app-ref .react-phone-number-input__input {
  border-bottom: unset !important;
}

.app-ref .status,
.status {
  margin: 5px;
  font-weight: 400;
  color: #00c1ff;
  font-size: 0.9em;
}

.resendRef {
  cursor: pointer;
  font-weight: 700;
  margin-left: 10px;
}

.resendRef:hover {
  border-bottom: #00c1ff 1px solid;
}

.mob-Invite {
  display: block !important;
  text-align: center;
}

.dis-Ref+div {
  width: 100%;
  padding: 5px;
}

.checkBoxNumber,
.checkBoxNumber+div {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.checkBoxNumber input {
  /* text-align: center; */
}

.enrollment-form .form-group textarea.not-empty+label {
  top: -18px !important;
}

.enrollment-form .form-group {
  margin-bottom: 25px;
}

.shift-log-textarea {
  padding-top: 5px !important;
  height: 60px !important;
}

.shift-log-label {
  /* color: #b0b0b0; */
  font-size: 20px;
  padding-left: 0px;
  margin-bottom: 0px;
}

.shift-log {
  /* border: 0.5px solid gray; */
  margin-top: 15px;
  padding: 15px;
  height: 85px;
  /* overflow-y: scroll; */
  background: #ffffff;
  flex: 1;
}

.shift-log .log-note {
  overflow-y: hidden;
  height: 45px;
  text-align: justify;
}

.shift-log-more {
  color: #0079fd;
  cursor: pointer;
  font-weight: 600;
  padding: 3px;
}

.shift-log-detail {
  box-shadow: 0px 2px 2px -2px;
  padding: 5px;
  width: 100%;
  margin-top: 5px;
}

.trial-start {
  border: dashed 2px gray;
  padding: 5px 20px;
  font-size: large;
  font-weight: 700;
  background: aliceblue;
  color: green;
}

.trial-end {
  /* border: dashed 2px gray; */
  /* padding: 5px 20px; */
  /* font-size: large; */
  font-weight: 700;
  /* background: aliceblue; */
  color: red;
}

.calenderFix .ant-tabs-tabpane.ant-tabs-tabpane-active {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.call-schedule .css-1hwfws3,
.remove-combo-padding .css-1hwfws3,
.combo-dropdown .css-1hwfws3 {
  padding: 0px;
}

.css-1hwfws3 {
  padding: 0px !important;
}

.grid-filter .css-1hwfws3 {
  padding-left: 10px !important;
}

.new-badge {
  color: #ff5d5d;
  /* color: #12c475; */
  background-color: #fbdcdd;
  /* background-color: #caedde; */
  border-radius: 10px;
  /* width: 45px; */
  height: 19px;
  /* margin-top: -3px; */
  padding: 0.3em 0.8em;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  /* margin-left: 5px; */
  position: absolute;
  top: -10px;
  right: 0px;
}

.card-remove {
  color: red;
  margin-right: 15px;
  cursor: pointer;
}

.card-remove+span {
  cursor: pointer;
}

.submitBtn input:disabled,
a[disabled],
button:disabled:not([role='switch']),
.students-tab-col input:disabled {
  /* display: none !important; */
  display: inline-block !important;
  background: #cccccc;
}

.isMultiHeight .css-1s2u09g-control,
.isMultiHeight .css-bg1rzq-control {
  min-height: 90px;
}

.isMultiHeight .select__control {
  height: 90px !important;
}

/* .isMultiHeight .select__control {
  height: 120px !important;
} */
.isMultiHeight .select__control .select__value-container--is-multi {
  height: 95px;
}

.privacy-policy {
  font-weight: 500;
  font-size: 1.2em;
}

.privacy-policy h2 {
  color: #474747;
  text-align: center;
}

.privacy-policy p,
.privacy-policy li {
  text-align: justify;
}

#privacy .section-1 {
  list-style-type: decimal;
  padding-inline-start: 40px;
  margin-top: 0;
  margin-bottom: 10px;
}

#privacy .section-2 {
  list-style-type: lower-alpha;
  padding-inline-start: 40px;
  margin-top: 0;
  margin-bottom: 10px;
}

#privacy .section-title {
  font-weight: 700;
}

.section-2 ol {
  counter-reset: list;
}

.section-2 ol>li {
  list-style: none;
}

.section-2 ol>li:before {
  content: counter(list) ') ';
  counter-increment: list;
}

.header .user-info .dropdown-toggle::after {
  vertical-align: 0.255em;
  border-top: 0.5em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}

.edit-user {
  padding-left: 0px;
}

.edit-user .edit-user-block {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 1px 0px;
  padding: 10px;
  margin: 0.25rem !important;
}

.edit-user .edit-user-block:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.video-log-box {
  height: 100px;
  border-radius: 9px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  padding: 18.5px 30px;
  cursor: pointer;
}

.video-log-box .video-info {
  /* display: flex; */
}

.video-log-box .video-info .title {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  margin-right: 5px;
  display: block;
}

.video-log-box .video-info .date {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  margin-right: 5px;
}

.video-log-box .video-info .dur {
  font-size: 14px;
  color: #828282;
  margin-right: 5px;
}

.video-log-box .video-info .time {
  font-size: 14px;
  color: #000000;
}

.small-Group-form input[type='text']:disabled,
.small-Group-form textarea:disabled,
.small-Group-form input[role='combobox']:disabled,
.small-Group-form .select__control--is-disabled,
.center-form input[type='text']:disabled {
  border-bottom: solid 1px rgba(149, 149, 149, 0.5) !important;
  margin-bottom: 0.5rem;
}

.growth-plan input[type='text']:disabled,
.staff-form input[type='email']:disabled,
.staff-form input[type='text']:disabled,
.staff-form input[type='number']:disabled,
.staff-form textarea:disabled,
.staff-form .select__control--is-disabled,
.staff-form input[role='combobox']:disabled {
  border-bottom: solid 1px rgba(149, 149, 149, 0.5) !important;
  margin-bottom: 0px;
}

.search-placeholder::placeholder {
  color: #bcbcbc;
}

/* Survey option */
.survey-question {
  /* max-width: 570px; */
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: left;
  font-weight: normal;
  color: #000000;
}

.survey-question .list-wrap-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.survey-question .list-wrap-item li {
  margin-bottom: 10px;
  margin-right: 10px;
  padding-right: 10px;
}

.not-apply-to-me {
  padding: 20px 0px;
  transition: all 300ms ease-in-out;
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.not-apply-to-me a {
  color: rgba(149, 149, 149, 0.85);
  text-decoration: underline;
  font-weight: 600;
  font-size: 14px;
}

/* Student Slider chart data */
.measures-slider {}

.otherParams .ant-tabs-nav-scroll {
  display: flex;
  justify-content: start;
}

.progress-slider-blue,
.progress-slider-red,
.progress-slider-green {
  margin-left: 0px;
  margin-right: 0px;
}

.progress-slider-blue .ant-slider-track {
  height: 5px !important;
  background-color: #46b4fe !important;
  box-shadow: 0 -1px 5px 0 rgba(70, 180, 254, 0.41);
  z-index: 1;
  border-radius: 7px;
}

.progress-slider-red .ant-slider-track {
  height: 5px !important;
  background-color: #ff5d5d !important;
  box-shadow: 0 -1px 5px 0 rgba(255, 93, 93, 0.41);
  z-index: 1;
  border-radius: 7px;
}

.progress-slider-green .ant-slider-track {
  height: 5px !important;
  background-color: #2fe192 !important;
  box-shadow: 0 -1px 5px 0 rgba(47, 225, 146, 0.41);
  z-index: 1;
  border-radius: 7px;
}

.progress-slider-blue .ant-slider-handle,
.progress-slider-red .ant-slider-handle,
.progress-slider-green .ant-slider-handle {
  display: none;
  /* background-color: transparent;
  border-color: transparent;
  margin-top: 0px;
  height: 5px;
  font-size: 5px;
  color: #fff; */
}

/* 
.progress-slider-blue .ant-slider-handle:before,
.progress-slider-red .ant-slider-handle:before,
.progress-slider-green .ant-slider-handle:before {
  width: 0;
  content: '';
  height: 0;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin: auto;
  height: 9px !important;
}
.progress-slider-blue .ant-slider-handle:before {
  border-bottom: solid 7px #46b4fe;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}
.progress-slider-red .ant-slider-handle:before {
  border-bottom: solid 7px #ff5d5d;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}
.progress-slider-green .ant-slider-handle:before {
  border-bottom: solid 7px #2fe192;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}
.progress-slider-blue .ant-slider-handle:focus,
.progress-slider-red .ant-slider-handle:focus,
.progress-slider-green .ant-slider-handle:focus {
  box-shadow: unset;
} */
.progress-slider-blue .ant-slider-rail,
.progress-slider-red .ant-slider-rail,
.progress-slider-green .ant-slider-rail {
  height: 5px;
  border-radius: 7px;
}

.progress-slider-blue .ant-slider-step {
  height: 5px;
  border-radius: 7px;
  background-color: #f0f9ff;
}

.progress-slider-red .ant-slider-step {
  height: 5px;
  border-radius: 7px;
  background-color: #fff2f2;
}

.progress-slider-green .ant-slider-step {
  height: 5px;
  border-radius: 7px;
  background-color: #f0f9ff;
}

.progress-slider-blue .ant-slider-dot,
.progress-slider-red .ant-slider-dot,
.progress-slider-green .ant-slider-dot {
  display: none;
}

.progress-slider-blue .ant-slider-mark,
.progress-slider-red .ant-slider-mark,
.progress-slider-green .ant-slider-mark {
  top: 20px;
}

.progress-slider-blue .ant-slider-mark-text,
.progress-slider-red .ant-slider-mark-text,
.progress-slider-green .ant-slider-mark-text {
  font-weight: 700;
  font-size: 13px;
}

/* mark css */
.line .progress-slider-blue .ant-slider-mark-text:not(:first-child) {
  color: #46b4fe;
}

.line .progress-slider-red .ant-slider-mark-text:not(:first-child) {
  color: #ff5d5d;
}

.line .progress-slider-green .ant-slider-mark-text:not(:first-child) {
  color: #2fe192;
}

.line .progress-slider-blue .ant-slider-mark-text:first-child::after,
.line .progress-slider-red .ant-slider-mark-text:first-child::after,
.line .progress-slider-green .ant-slider-mark-text:first-child::after {
  color: #46b4fe;
  width: 0;
  content: '';
  height: 0;
  position: absolute;
  top: -40%;
  right: 0;
  left: 0;
  bottom: 20px;
  margin: auto;
  height: 6px !important;
  border-bottom: solid 7px #46b4fe;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  /* box-shadow: 0 2px 4px 0 rgba(130, 130, 130, 0.43); */
}

.line .progress-slider-blue .ant-slider-mark-text:first-child,
.line .progress-slider-red .ant-slider-mark-text:first-child,
.line .progress-slider-green .ant-slider-mark-text:first-child {
  margin-left: 5px;
  size: 13px;
  color: #46b4fe;
  font-weight: 600;
}

.line .progress-slider-blue .ant-slider-mark-text:last-child,
.line .progress-slider-red .ant-slider-mark-text:last-child,
.line .progress-slider-green .ant-slider-mark-text:last-child {
  margin-left: 0px;
}

.reverse .progress-slider-blue .ant-slider-mark-text:not(:last-child) {
  color: #46b4fe;
}

.reverse .progress-slider-red .ant-slider-mark-text:not(:last-child) {
  color: #ff5d5d;
}

.reverse .progress-slider-green .ant-slider-mark-text:not(:last-child) {
  color: #2fe192;
}

.reverse .progress-slider-blue .ant-slider-mark-text:last-child::after,
.reverse .progress-slider-red .ant-slider-mark-text:last-child::after,
.reverse .progress-slider-green .ant-slider-mark-text:last-child::after {
  color: #46b4fe;
  width: 0;
  content: '';
  height: 0;
  position: absolute;
  top: -40%;
  right: 0;
  left: 0;
  bottom: 20px;
  margin: auto;
  height: 6px !important;
  border-bottom: solid 7px #46b4fe;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  /* box-shadow: 0 2px 4px 0 rgba(130, 130, 130, 0.43); */
}

.reverse .progress-slider-blue .ant-slider-mark-text:last-child,
.reverse .progress-slider-red .ant-slider-mark-text:last-child,
.reverse .progress-slider-green .ant-slider-mark-text:last-child {
  margin-left: 5px;
  size: 13px;
  color: #46b4fe;
  font-weight: 600;
}

.reverse .progress-slider-blue .ant-slider-mark-text:first-child,
.reverse .progress-slider-red .ant-slider-mark-text:first-child,
.reverse .progress-slider-green .ant-slider-mark-text:first-child {
  margin-left: 0px;
}

@media (min-width: 480px) and (max-width: 991px) {
  .measures-slider .system_observation {
    flex-wrap: wrap;
  }

  .measures-slider .system_sec {
    margin-bottom: 20px;
    margin-left: 150px;
    margin-right: 150px;
  }
}

.measures-slider .shadow_progress {
  padding: 20px 16px;
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
}

.measures-slider h3.system_title {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  padding: 0;
  margin-bottom: 15px;
}

.measures-slider .system_observation {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  flex-wrap: wrap;
}

.measures-slider .system_sec {
  padding: 25px;
  background-color: #f3f3f3;
  border-radius: 9px;
  flex: 1 auto;
  width: 100%;
  margin-bottom: 20px;
}

.measures-slider .system_sec h5 {
  color: #000;
  font-size: 16px;
  margin-bottom: 8px;
  font-family: 'AvenirLTStdMedium';
}

.measures-slider .system_sec p {
  color: #000;
  font-size: 14px;
  margin-bottom: 2px;
}

.measures-slider .main_progress {
  padding: 58px 16px 40px 16px;
  background-color: #fff;
}

.measures-slider .shadow_progress {
  padding: 20px 16px;
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
}

.measures-slider .measure {
  padding: 8px 1px;
  /* flex: 1 auto;
  margin-bottom: 14px;
  background-color: #fff;
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
  border-radius: 12px; */
}

.measures-slider .other_measure .progress {
  height: 6px;
}

.measures-slider .other_measure .progress-label {
  height: 6px;
}

.measures-slider .measure h5 {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 32px;
}

.measures-slider .other_measure .measure h5 {
  margin-bottom: 27px;
}

.measures-slider .progress_bar {
  margin-bottom: 56px;
}

.measures-slider .other_measure .progress_bar {
  margin-bottom: 52px;
}

.measures-slider .progress_bar p {
  font-size: 13px;
  color: #000;
  font-weight: 700;
  margin-bottom: 2px;
  font-weight: 400;
}

.measures-slider .other_measure .progress_bar p {
  margin-bottom: 2px;
}

.measures-slider .progress {
  background-color: #f0f9ff;
  height: 14px;
  border-radius: 20px;
  position: relative;
  width: 100%;
  overflow: visible;
}

.measures-slider .progress-label {
  background-color: #46b4fe;
  position: absolute;
  left: 0;
  right: 0;
  height: 14px;
  border-radius: 20px;
}

.measures-slider .progress-label .progress-label-current-indicator {
  right: 0;
  position: absolute;
}

.measures-slider .progress-label .progress-label-start-indicator {
  left: 0;
  position: absolute;
}

.measures-slider .progress-label span {
  font-size: 11px;
  color: #fff;
  line-height: 15px;
  text-align: center;
  width: 20px;
}

.measures-slider .progress_bar_label {
  position: relative;
  bottom: 23px;
}

.measures-slider .progress_bar_label span {
  display: inline-block;
  position: absolute;
  color: #0e0e0e;
  font-size: 16px;
  font-weight: 600;
}

.measures-slider .progress_bar_label .progress_bar_label_text {
  left: 0;
}

.measures-slider .indicator-text {
  position: relative;
}

.measures-slider .indicator-text span {
  display: inline-block;
  position: absolute;
  color: #828282;
  font-size: 13px;
  font-weight: 400;
}

.measures-slider .indicator-text span.indicator-left-text {
  left: 0;
  /* top: 15px; */
}

.measures-slider .other_measure .indicator-text span.indicator-left-text,
.measures-slider .other_measure .indicator-text span.indicator-right-text {
  top: 0;
}

.measures-slider .indicator-text span.indicator-right-text {
  right: 0;
  /* top: 15px; */
}

.measures-slider .progress-img img {
  position: absolute;
}

.measures-slider .progress-img img.red-arrow-img {
  left: 30px;
}

.measures-slider .progress-img img.green-arrow-img {
  right: 204px;
}

.measures-slider .progress-2 .progress-label {
  left: 0;
  right: 70px;
}

.measures-slider .other_measure .progress-label {
  left: 0;
}

.measures-slider .progress-label span.progress-label-start-indicator:before {
  width: 0;
  content: '';
  height: 0;
  border-bottom: solid 7px #ff5d5d;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
}

.measures-slider .progress-label span.progress-label-current-indicator:after {
  width: 0;
  content: '';
  height: 0;
  border-bottom: solid 7px #12c475;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin: auto;
}

.measures-slider .other_measure .progress-label span.progress-label-start-indicator:before,
.measures-slider .other_measure .progress-label span.progress-label-current-indicator:after {
  display: none;
}

.measures-slider .other_measure .progress-label-current-indicator {
  color: #46b4fe;
  font-weight: 700;
  font-size: 13px;
  top: calc(100% + 4px);
}

.slider-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slick-slide .name {
  font-size: 24px;
  color: #000000;
  font-weight: 600;
}

.measures-slider .arrow a {
  font-size: 13px;
  font-weight: 600;
  position: relative;
}

.measures-slider .arrow a.started {
  color: #828282;
  margin-right: 5px;
  text-decoration: none;
  cursor: default;
}

.measures-slider .arrow a.started:before {
  width: 0;
  content: '';
  height: 0;
  border-bottom: solid 7px #46b4fe;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  top: 6px;
  left: -15px;
  position: absolute;
}

/* Recovery Journey */

.journey-title {
  padding: 2.3em 0em;
}

.journey-title h5 {
  font-size: 24px;
  color: #000000;
  font-weight: normal;
}

.ant-collapse-borderless {
  background-color: #ffffff;
  border: 0;
}

/* Gray */
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel-default,
.site-collapse-custom-collapse .site-collapse-custom-panel-default {
  background: #f8f8f8;
  border-radius: 9px;
  margin-bottom: 24px;
  /* height: 86px; */
  border: 0px;
  overflow: hidden;
}

.site-collapse-custom-panel-default .panel-header {
  /* border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px; */
  margin: 10px;
}

.site-collapse-custom-panel-default .panel-header .panel-header-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-collapse-custom-panel-default .panel-header .outcome-icon {
  position: relative;
  /* height: 40px; */
  width: 30.7px;
  text-align: center;
}

.site-collapse-custom-panel-default .panel-header .outcome-icon img {
  height: 39px;
  width: 100%;
  object-fit: contain;
}

.site-collapse-custom-panel-default .panel-header .outcome-icon span {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  transform: translate3d(-50%, -50%, 0);
  font-size: 14px;
  font-weight: bold;
  color: #828282;
}

.site-collapse-custom-panel-default .panel-header .day-count {
  font-size: 24px;
  font-weight: bold;
  color: #111111;
  margin: 0 auto;
}

.site-collapse-custom-panel-default .panel-header .panel-title {
  font-size: 17px;
  font-weight: bold;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.site-collapse-custom-panel-default .panel-header .panel-title-status {
  background-color: #828282;
  color: #ffffff;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 12px;
}

.site-collapse-custom-panel-default .panel-header .panel-desc {
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ant-collapse-content .ant-collapse-content-box {
  padding-top: 4px;
  background: #f8f8f8;
}

/* Blue */
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel-blue,
.site-collapse-custom-collapse .site-collapse-custom-panel-blue {
  /* background: #46b4fe; */
  background-color: rgba(70, 180, 254, 0.1);
  border-radius: 9px;
  margin-bottom: 24px;
  /* height: 86px; */
  border: 0px;
  overflow: hidden;
  /* box-shadow: 0 5px 11px 0 rgba(70, 180, 254, 0.37); */
}

.site-collapse-custom-panel-blue .panel-header {
  /* border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px; */
  margin: 10px;
}

.site-collapse-custom-panel-blue .panel-header .panel-header-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-collapse-custom-panel-blue .panel-header .outcome-icon {
  position: relative;
  /* height: 40px; */
  width: 30.7px;
  text-align: center;
}

.site-collapse-custom-panel-blue .panel-header .outcome-icon img {
  height: 39px;
  width: 100%;
  object-fit: contain;
}

.site-collapse-custom-panel-blue .panel-header .outcome-icon span {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  transform: translate3d(-50%, -50%, 0);
  font-size: 14px;
  font-weight: bold;
  color: #46b4fe;
}

.site-collapse-custom-panel-blue .panel-header .day-count {
  font-size: 24px;
  font-weight: bold;
  color: #46b4fe;
  margin: 0 auto;
}

.site-collapse-custom-panel-blue .panel-header .panel-title {
  font-size: 17px;
  font-weight: bold;
  color: #46b4fe;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.site-collapse-custom-panel-blue .panel-header .panel-title-status {
  background-color: #46b4fe;
  color: #ffffff;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 12px;
}

.site-collapse-custom-panel-blue .panel-header .panel-date {
  font-size: 16px;
  font-weight: normal;
  color: #46b4fe;
}

.site-collapse-custom-panel-blue .panel-header .panel-desc {
  font-size: 16px;
  font-weight: normal;
  color: #46b4fe;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.site-collapse-custom-panel-blue .ant-collapse-content .ant-collapse-content-box {
  padding-top: 4px;
  padding-left: 0px;
  background: #f8f8f8;
  /* color: #ffffff; */
}

/* Green */
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel-green,
.site-collapse-custom-collapse .site-collapse-custom-panel-green {
  background-color: #f8f8f8;
  border-radius: 9px !important;
  margin-bottom: 24px;
  height: 86px;
  border: 0px;
  overflow: hidden;
}

.site-collapse-custom-panel-green .panel-header {
  /* border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px; */
  margin: 10px;
}

.site-collapse-custom-panel-green .panel-header .panel-header-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-collapse-custom-panel-green .panel-header .outcome-icon {
  position: relative;
  /* height: 40px; */
  width: 30.7px;
  text-align: center;
}

.site-collapse-custom-panel-green .panel-header .outcome-icon img {
  height: 39px;
  width: 100%;
  object-fit: contain;
}

.site-collapse-custom-panel-green .panel-header .outcome-icon span {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  transform: translate3d(-50%, -50%, 0);
  font-size: 14px;
  font-weight: bold;
  color: #828282;
}

.site-collapse-custom-panel-green .panel-header .day-count {
  font-size: 24px;
  font-weight: bold;
  color: #111111;
  margin: 0 auto;
}

.site-collapse-custom-panel-green .panel-header .panel-title {
  font-size: 17px;
  font-weight: bold;
  color: #12c475;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.site-collapse-custom-panel-green .panel-header .panel-title-status {
  background-color: #12c475;
  color: #ffffff;
  border-radius: 20px;
  padding: 5px 5px;
  font-size: 12px;
}

.site-collapse-custom-panel-green .panel-header .panel-date {
  font-size: 16px;
  font-weight: normal;
  color: #000000;
}

.site-collapse-custom-panel-green .panel-header .panel-desc {
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.site-collapse-custom-panel-green .ant-collapse-content .ant-collapse-content-box {
  padding-top: 4px;
  padding-left: 0px;
  background: #f8f8f8;
  /* color: #ffffff; */
}

@media (min-width: 960px) and (max-width: 1140px) {

  /* Gray */
  [data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel-default,
  .site-collapse-custom-collapse .site-collapse-custom-panel-default {
    background: #f8f8f8;
    border-radius: 9px;
    margin-bottom: 24px;
    height: unset;
    border: 0px;
    overflow: hidden;
  }

  /* Blue */
  [data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel-blue,
  .site-collapse-custom-collapse .site-collapse-custom-panel-blue {
    /* background: #46b4fe; */
    background-color: rgba(70, 180, 254, 0.1);
    border-radius: 9px;
    margin-bottom: 24px;
    /* height: 86px; */
    border: 0px;
    overflow: hidden;
    /* box-shadow: 0 5px 11px 0 rgba(70, 180, 254, 0.37); */
  }

  /* Green */
  [data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel-green,
  .site-collapse-custom-collapse .site-collapse-custom-panel-green {
    background-color: #f8f8f8;
    border-radius: 9px !important;
    margin-bottom: 24px;
    height: unset;
    border: 0px;
    overflow: hidden;
  }
}

/* radio */

.custom-radio label {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  color: #979797;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

[type='radio']:checked~.checkmark {
  /* box-shadow: 0px 0px 8px #56ccf2; */
  border-color: #46b4fe;
}

/* Create a custom radio button */
.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.custom-radio label:hover input~.checkmark {
  background-color: #f2f3f4;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radio .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio label input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio label .checkmark:after {
  top: 6px;
  left: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #46b4fe;
}

/* range-picker */
.range-picker .ant-input {
  border: 0px;
  background-color: unset;
  padding-left: 0px;
}

.range-picker .ant-calendar-range-picker-input {
  font-size: 16px;
  color: #959595;
}

.range-picker .ant-input ::placeholder {
  font-size: 16px;
  color: #959595;
}

/* .contract-form input[type='text']:disabled .contract-form textarea:disabled {
  border-bottom: solid 1px rgba(149, 149, 149, 0.5) !important;
  margin-bottom: 0px;
} */
.print-enrollment input[type='text']:disabled,
.contract-form input[type='text']:disabled,
.contract-form input[type='number']:disabled,
.print-enrollment input[type='number']:disabled,
.contract-form textarea:disabled,
.print-enrollment textarea:disabled,
.contract-form .select__control--is-disabled,
.print-enrollment .select__control--is-disabled,
.print-enrollment input[role='combobox']:disabled,
.contract-form input[role='combobox']:disabled {
  border-bottom: solid 1px rgba(149, 149, 149, 0.5) !important;
  margin-bottom: 0px;
}

.print-no-data {
  width: 50%;
  text-align: center;
  font-weight: 700;
  padding: 10px;
  border: 1px solid rgba(149, 149, 149, 0.5);
}

/*  */
.license-invoice {}

.license-invoice-link {
  font-size: 14px;
  font-weight: 600;
  color: #46b4fe;
  cursor: pointer;
}

.align-items-start {
  justify-content: space-between;
}

.report-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;
  color: #000000;
  margin-bottom: 15px;
  border-bottom: 1px solid #D2D2D2;
}

.invoice-list {
  margin-bottom: 10px;
}

.invoice-list h4 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.invoice-list h4 small {
  font-size: 16px;
  color: #959595;
}

.invoice-list .download {
  font-size: 15px;
  font-weight: 600;
  color: #699BF7;
  cursor: pointer;
  margin-left: 5px;
}

.report-table {}

.report-table .download {
  font-size: 1rem;
  font-weight: 800;
  color: #46b4fe;
  cursor: pointer;
}

.report-table .ant-table-thead>tr>th {
  color: #828282;
  font-weight: 800;
  text-transform: capitalize;
}

.excel-download,
.excel-download a {
  cursor: pointer !important;
  pointer-Events: auto !important;
  color: #1d6e43;
}

/* override styles when printing */
@media all {
  /* .page-break {
    display: none;
  } */
}


/* Small Group */
.small-Group-form {}

.small-Group-form .group-image-bg {
  height: 250px;
  border-radius: 12px;
  background-color: #f7f7f7;
  margin-bottom: 20px;
}

.small-Group-form .group-image-bg h6 {
  text-align: center;
  padding-top: 20px;
}

.small-Group-form .group-picture {
  display: flex;
  height: 157px;
  margin: 25px;
  border-radius: 12px;
  border: dashed 2px #4ab0fe;
  align-items: center;
  justify-content: center;
}

.small-Group-form .group-picture span {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: #4ab0fe;
  cursor: pointer;
}

.small-Group-form .profile-image-bg {
  height: 250px;
  border-radius: 12px;
  background-color: #f7f7f7;
  margin-bottom: 20px;
}

.small-Group-form .profile-image-bg h6 {
  text-align: center;
  padding-top: 20px;
}

.small-Group-form .profile-image-bg .existing {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-Group-form .profile-image-bg .existing-text {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.small-Group-form .profile-image-bg .existing-picture {
  display: flex;
  height: 65px;
  margin: 10px 25px;
  padding: 10px 36px;
  width: fit-content;
  border-radius: 12px;
  border: dashed 2px #4ab0fe;
  align-items: center;
  justify-content: center;
}

.small-Group-form .profile-image-bg .upload {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-Group-form .profile-image-bg .upload .clearfix {
  margin: 10px 25px;
}

.small-Group-form .profile-image-bg .upload-picture {
  display: flex;
  height: 65px;
  margin: 10px 25px;
  padding: 10px 36px;
  border-radius: 12px;
  /* border: dashed 2px #4ab0fe; */
  align-items: center;
  justify-content: center;
}

.small-Group-form .profile-image-bg .upload-picture .ant-upload-picture-card-wrapper {
  width: unset;
}

.small-Group-form .profile-image-bg .upload .ant-upload.ant-upload-select-picture-card {
  /* display: flex; */
  width: 410px;
  height: 85px;
  margin-right: 0px;
  margin-bottom: 0px;
  border-radius: 12px;
  border: dashed 2px #4ab0fe;
}

.small-Group-form .profile-image-bg .ant-upload.ant-upload-select-picture-card>.ant-upload {
  /* width: unset; */
  /* border: dashed 2px #4ab0fe; */
}

/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  /* width: 150px; */
  margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}

.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  color: #999;
  font-size: 32px;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: #000000;
}

.small-group-box {
  /* border-radius: 12px; */
  /* padding: 20px 24px; */
  position: relative;
  cursor: pointer;
}

.small-group-box .small-group-up {
  /* filter: drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.173022)); */
  border-radius: 12px 12px 0px 0px;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #828282;
}

.small-group-box .small-group-up .small-group-info {
  position: absolute;
  width: 100%;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)); */
  background-color: rgb(0 0 0 / 62%);
  -webkit-backdrop-filter: opacity(60%);
  backdrop-filter: opacity(60%);
  color: #fff;
  align-items: flex-end;
  padding: 10px 20px 0px 20px;
  padding-bottom: 1rem;
}

.small-group-box .small-group-up .group-info {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.small-group-box .small-group-up .small-group-info .group-name {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.small-group-box .small-group-up .small-group-info .group-tag {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.small-group-box .small-group-up .small-group-info .group-time {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  /* word-break: break-all; */
}

.small-group-box .small-group-down {
  background: #ffffff;
  box-shadow: 0px 11px 16px rgba(0, 0, 0, 0.0620356);
  border-radius: 0px 0px 12px 12px;
  height: 250px;
}

.small-group-box .small-group-down .sm-action-area {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
}

.small-group-box .small-group-down .sm-action-area .meeting-link-bg {
  width: 138px;
  height: 30px;
  line-height: 30px;
  border-radius: 50px;
  background: linear-gradient(90deg,
      #00f2fe 0%,
      #03effe 2.08%,
      #24d2fe 29.31%,
      #3cbdfe 55.38%,
      #4ab0fe 79.56%,
      #4facfe 100%);
  /* position: absolute; */
  text-align: center;
  /* top: 16px; */
  /* right: 16px; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}

.small-group-box .small-group-down .sm-action-area .past-meeting-link-bg {
  width: 138px;
  height: 30px;
  line-height: 30px;
  border-radius: 50px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #4ab0fe;
  cursor: pointer;
}

.sm-action-area .active-small-group {
  background: #12c4752e;
  /* border: 1px solid #ffffff; */
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.0467384);
  border-radius: 3px;
  color: #12c475;
  padding: 3px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.09625px;
}

.sm-action-area .in-active-small-group {
  background: #8d8d8d33;
  /* border: 1px solid #8d8d8d33; */
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.0467384);
  border-radius: 3px;
  color: #8d8d8d;
  padding: 3px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.09625px;
}

.small-group-box .small-group-down .group-description {
  /* position: absolute; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}

.small-group-box .small-group-down .group-leader-profile {
  min-width: 47px;
  margin-right: 5px;
}

.small-group-box .small-group-down .group-leader-desc {
  display: flex;
  flex-direction: column;
  height: 76px;
  flex: 1;
  /* height: 76px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%; */
}

.small-group-box .small-group-down .group-leader {
  /* display: flex; */
  /* display: flex;
  flex-direction: column; */
}

.small-group-box .small-group-down .leader {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.09625px;
  color: #000000;
}

.small-group-box .small-group-down .date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.09625px;
  color: #000000;
}

.small-group-box .small-group-down .group-action {}

.small-group-box .small-group-down .group-schedule {
  height: 55x;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  /* position: absolute; */
  bottom: 0px;
  padding: 0px 20px;
}

.small-group-box .small-group-down .group-schedule div {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
}

.small-group-box .small-group-down .group-schedule div .label-text {
  font-size: 14px;
  font-weight: normal;
  color: #828282;
  /* text-transform: uppercase; */
}

.small-group-box .small-group-down .group-schedule div .label-value {
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  /* text-transform: uppercase; */
}

.small-group-box .small-group-down .last-updated {
  padding-top: 0px;
}

.small-group-box .small-group-down .last-updated .label-text {
  font-size: 12px;
  font-weight: normal;
  color: #828282;
  /* text-transform: uppercase; */
}

.small-group-box .small-group-down .last-updated .label-value {
  font-size: 11px;
  font-weight: normal;
  color: #000000;
  /* text-transform: uppercase; */
}

.small-group {}

.published-small-group {
  background: #ffffff30;
  color: rgb(255, 255, 255);
  backdrop-filter: blur(0.2);
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.0467384);
  border-radius: 3px;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.09625px;
}

.un-published-small-group {
  background: #f56263;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.0467384);
  border-radius: 3px;
  color: rgb(255, 255, 255);
  padding: 5px 10px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.09625px;
}

.small-group-status {
  display: flex;
  /* align-items: flex-end; */
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
}

.small-group-status div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.small-group-status div .label-text {
  font-size: 14px;
  font-weight: 700;
  color: #c8c7cc;
  text-transform: uppercase;
}

.input-cost .error-text {
  position: absolute;
  bottom: -20px;
  right: 0px;
}

.filter-check {}

.filter-check .ant-checkbox-group-item .ant-checkbox+span {
  color: rgb(149, 149, 149);
  font-size: 14px;
  font-weight: 600;
  /* align-self: center; */
}

.filter-check label.ant-checkbox-wrapper span:nth-child(2) {
  align-self: center;
}

/* RCM */

/* .Activities .ant-tabs-nav > div .ant-tabs-tab,
.dashboard-tabcol .ant-tabs-nav > div .ant-tabs-tab {
  flex: 0 0 25%;
} */

.rcm-tab .ant-tabs-nav:first-of-type>div {
  display: inline-block;
}

.rcm-tab .ant-tabs-nav>div .ant-tabs-tab {
  flex: 1;
  margin: 0 !important;
  padding: 20px 10px !important;
  text-align: center;
}

.rcm-tab .ant-tabs-tab {
  width: 100%;
  text-align: center;
  background-color: white;
  padding: 20px 32px 20px 20px !important;
  height: 70px;
  vertical-align: middle;
  font-family: Nunito, sans-serif;
  font-size: 20px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: linear-gradient(to right,
      rgb(0, 241, 254) 0%,
      rgb(79, 172, 254) 50%,
      rgb(235, 235, 235) 50%,
      rgb(235, 235, 235) 100%) !important;
}

.rcm-tab .ant-tabs-nav-scroll {
  width: inherit;
  overflow: hidden;
}

.rcm-tab .ant-tabs-nav-wrap {
  /* overflow: hidden;
  width: 100%; */
}

.rcm-tab .ant-tabs-tab-arrow-show,
.rcm-tab .ant-tabs-ink-bar {
  /* display: none !important; */
}

.rcm-tab .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
  bottom: -2px;
}

.rcm-tab .ant-tabs-tab {
  border: 0;
  font-weight: 600;
  color: #828282;
  position: relative;
}

.rcm-tab .ant-tabs-tab-active {
  color: #46b4fe;
}

.rcm-tab .ant-tabs-tab-active .border-image:after {
  content: '';
  border-radius: 2.5px;
  background-image: -webkit-linear-gradient(left,
      #00f2fe,
      #03effe 2%,
      #24d2fe 29%,
      #3cbdfe 55%,
      #4ab0fe 80%,
      #4facfe);
  background-image: linear-gradient(to right,
      #00f2fe,
      #03effe 2%,
      #24d2fe 29%,
      #3cbdfe 55%,
      #4ab0fe 80%,
      #4facfe);
  width: 90%;
  margin: 0 auto;
  height: 5px;
  display: block;
  -webkit-transition: all 200ms ease-in-out !important;
  transition: all 200ms ease-in-out !important;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.rcm-tab .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-small-bar {
  /* margin-bottom: 50px; */
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);
}

.rcm-tab .ant-tabs {
  font-size: 16px !important;
  font-family: 'Nunito', sans-serif !important;
  color: #000 !important;
}

/*  */
.pat-tab .ant-tabs-nav:first-of-type>div {
  display: block !important;
}

.pat-tab .ant-tabs-tab {
  width: 100%;
  text-align: center;
  background-color: unset;
  padding: 10px 32px 10px 20px !important;
  height: 60px;
  vertical-align: middle;
  font-family: Nunito, sans-serif;
  font-size: 20px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  /* border-bottom-width: 1px; */
  /* border-bottom-style: solid; */
  /* border-bottom-color: linear-gradient(
    to right,
    rgb(0, 241, 254) 0%,
    rgb(79, 172, 254) 50%,
    rgb(235, 235, 235) 50%,
    rgb(235, 235, 235) 100%
  ) !important; */
}

/* .rcm-panel .enrollment-form .form-group .form-control-icon{
  top: 10px !important;
} */

.disable-li li {
  list-style: none;
}

.disable-li li .dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small_group_users .ant-table-row:hover,
.promo_codes .ant-table-row:hover {
  cursor: default;
}

.remaining-char {
  display: flex;
  justify-content: flex-end;
  color: #959595;
  font-size: 14px;
}

.table-bg-danger {
  background-color: rgb(254, 242, 242);
  color: rgb(255, 59, 59);
}

.table-bg-primary {
  background-color: rgb(239, 247, 254);
  color: rgb(18, 196, 117);
}

.table-bg-success {
  /* background-color: rgba(47, 225, 146, 0.2); */
  background-color: #d5edda;
  color: #12c475;
}

.select-sm-filter {
  width: 150px !important;
  margin-right: 10px;
}

.select-sm-filter .css-1szy77t-control,
.select-sm-filter .css-1s2u09g-control,
.select-sm-filter .css-bg1rzq-control {
  background-color: #f8f8f8 !important;
}

.select-sm-filter .select__control {
  height: 44px !important;
}

.validation-error {
  display: block;
  text-align: right;
  color: #ff3b3b;
  font-size: 14px;
  font-family: 'AvenirLTStdMedium';
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.editable-cell .ant-table-thead,
.editable-cell .ant-table-row {
  white-space: unset !important;
}

.copy-clipboard {
  margin-right: 10px;
}

.copy-clipboard .copy-textarea {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px #cccccc solid;
  margin: '20px';
  overflow: hidden;
  height: 70px !important;
}

.copy-clipboard .copy-textarea:hover {
  cursor: pointer;
  /* background: hsl(120, 100%, 93%); */
  background: #2ec9fe;
  /* border-color: #00c642; */
  color: #ffffff;
}

.copy-clipboard .clipboard-success {
  color: #46b4fe;
}

.deactivate-popup {
  /* // background: 'rgb(254, 242, 242)' */
  font-style: oblique;
  font-weight: 400 !important;
  /* padding: 0px 10px; */
  font-size: 22px;
}

.dashboard-analytics .card-title {
  font-size: 16px;
}

.dashboard-analytics .card-columns {
  column-gap: 0.8rem;
}

.dashboard-analytics .card {
  border: 0px;
}

.dashboard-analytics .card-body {
  padding: 1.25rem 0.5rem;
}

.dashboard-analytics .custom-header .ant-table-thead>tr>th {
  background: #33a0fc !important;
  color: #fff !important;
  line-height: 20px;
  text-align: center;
}

.dashboard-analytics .custom-header .ant-table-thead>tr:nth-of-type(2)>th {
  background: #33a0fc !important;
  color: #fff !important;
  line-height: 1px;
  text-align: center;
  border: 1px solid #e0dcdc;
}

.dashboard-analytics .custom-header .ant-table-thead,
.ant-table-row {
  white-space: unset !important;
}

.dashboard-analytics .custom-header .ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
  padding: 5px 16px;
  overflow-wrap: break-word;
}

.document-table .ant-table-row:hover,
.dashboard-analytics .ant-table-row:hover {
  cursor: default;
}

/* .distribution-table td{
  display: inline-block;
  min-width: 100px;
  text-align: center;
} */
.demographic-profile .ant-table-tbody>tr>td {
  border-bottom: 0px;
}

.distribution-td {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.distribution-table {}

.distribution-table .dis-tr {
  background: rgba(70, 180, 254, 0.35);
}

.distribution-table-text {
  /* display: inline-block; */
  min-width: 60px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */
  color: #000000;
  border-right: 0.5px solid #ffffff;
}

.distribution-table-value {
  /* display: inline-block; */
  color: #0068b7;
  font-weight: bold;
  font-size: 10px;
  min-width: 60px;
  text-align: center;
  font-style: normal;
  line-height: 16px;
  border-right: 0.5px solid #ffffff;
  padding: 0px 10px;

  /* identical to box height, or 160% */
}

.referral-table {
  width: 70%;
  margin: 0 auto;
}

/* upload-document-modal */
.upload-document-modal {}

.upload-document-modal .document-list-item {
  background: #f8f8f8;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
}

.upload-document-modal .document-list-item .css-1s2u09g-control,
.dashboard-analytics-centers .css-1s2u09g-control,
.upload-document-modal .document-list-item .css-bg1rzq-control,
.dashboard-analytics-centers .css-bg1rzq-control,
.dashboard-analytics-centers .form-control,
.upload-document-modal .document-list-item .form-control {
  background: #f8f8f8;
}

.upload-document .dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px dashed #c9c9c9;
  border-width: 2px;
  border-radius: 16px;
  /* background-color: #fafafa; */
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.upload-document .dropzone-upload-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.09625px;
  color: #000000;
}

.upload-document .dropzone-upload-text-2 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.09625px;
  color: #7a7a7a;
}

.upload-document .dropzone-upload-drag-icon {
  font-size: 16px;
  color: #46b4fe;
}

.upload-document .accepted-list {
  max-height: 300px;
  overflow-y: auto;
}

.upload-document .css-1szy77t-control:hover,
.dashboard-analytics-centers .css-1szy77t-control:hover {
  background: #f8f8f8;
}

.upload-document .css-1szy77t-control,
.dashboard-analytics-centers .css-1szy77t-control {
  background: #f8f8f8;
}

.upload-document .css-1szy77t-control:active,
.dashboard-analytics-centers .css-1szy77t-control:active {
  background: #f8f8f8;
}

.user-quota-bar .used-quota-val {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.208px;
  color: #000000;
}

.user-quota-bar .used-quota-per {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.208px;
  color: #979797;
}

.quota-alert .quota-alert-bar {
  width: 80%;
  background: rgb(235, 235, 235);
  margin: auto;
  text-align: center;
  padding: 13px 0px;
}

.quota-alert-bar .bar-text {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.208px;
  color: #979797;
}

.quota-alert .quota-alert-bar-alert {
  width: 80%;
  background: rgba(255, 59, 59, 0.15);
  margin: auto;
  text-align: center;
  padding: 13px 0px;
}

.quota-alert-bar-alert .bar-text {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.208px;
  color: #000000;
}

.upload-leader-profile {
  width: 410px;
  height: 85px;
  margin-right: 0;
  margin-bottom: 0;
  border-radius: 12px;
  border: 2px dashed #4ab0fe;
  text-align: center;
}

.thumbsContainer {
  display: flex;
  flex-flow: row wrap;
  margin-top: 16px;
}

.thumb {
  display: inline-flex;
  border-radius: 15px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 70px;
  height: 70px;
  padding: 4px;
  box-sizing: border-box;
}

.thumbinner {
  display: flex;
  min-width: 0px;
  overflow: hidden;
}

.img {
  display: block;
  width: auto;
  height: 100%;
  border-radius: 12px;
}

.leader-img-name-block {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leader-img-name {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.leader-img-size {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #979797;
}

.ant-table-placeholder {
  z-index: -1 !important;
}

textarea.cke_source {
  height: 100% !important;
}

.ant-time-picker-input[disabled] {
  cursor: default !important;
}

.searchDiv>div {
  width: 100% !important;
}

.searchDiv div {
  z-index: 9999999 !important;
}

.report-style-class {
  height: 280px;
}

.group-client-search>div {
  width: 100% !important;
}

.report-style-class-fullView {
  height: 95vh;
}

.jud-card {
  height: 220px;
}

.fc-day-past {
  background-color: #e8e8e89e;
}

.profile-thumbs-Container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
}

.treatmentplan h3 {
  font-size: 16px;
  color: white;
}

.dimension {
  background: #4aaffd;
  /* border:1px solid yellow; */
  color: white;
  width: 500px;
  height: 190px;
  display: inline-block;
  padding: 10px;
  margin-right: 5px;
  /* cursor: pointer; */
}

.problem {
  width: 228.75px;
  height: 173.45px;
  background: #ff9847;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  display: inline-block;
  /* margin-right: 10px; */
  margin: 0 auto;
}

.problem .top {
  background: #ff9847;
  color: white;
  width: 230px;
  display: inline-block;
  padding: 10px;
  z-index: -9;
}

.problem .problem-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  height: 100px;
  margin-top: 18%;
}

.problem:hover {
  background: #ff9847;
  /* background: #ff9847; */
}

.goal {
  width: 228.75px;
  height: 173.45px;
  background: #62d860;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  /* margin-right: 10px; */
  margin: 0 auto;
}

.goal .top {
  background: #62d860;
  color: white;
  width: 230px;
  display: inline-block;
  padding: 10px;
  z-index: -9;
}

.goal .top:hover {
  background: #8be98b;
  z-index: 999999999999 !important;
}

.goal .bottom {
  background: #8be98b;
  color: white;
  width: 230px;
  display: inline-block;
  padding: 10px;
  z-index: -9;
  height: 50px;
}

/* .goal .bottom:hover {
  background: #56c454;
} */
.objective {
  width: 228.75px;
  height: 173.45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  background: #fd8181;
  position: inherit;
  /* margin-right: 10px; */
  margin: 0 auto;
}

.objective .top {
  background: #fd8181;
  color: white;
  width: 230px;
  display: inline-block;
  padding: 10px;
  z-index: -9;
}

.objective .top:hover {
  background: #e87676;
  z-index: 999999999999 !important;
}

.objective .bottom {
  background: #ffa8a7;
  color: white;
  width: 230px;
  display: inline-block;
  padding: 10px;
  z-index: -9;
}

.objective .bottom:hover {
  background: #f98182;
  z-index: 999999999999 !important;
}

.intervention {
  width: 228.75px;
  height: 334.45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  background: #219ebc;
  /* margin-right: 10px; */
  margin: 0 auto;
}

.intervention .top {
  background: #25bde2;
  color: white;
  width: 230px;
  display: inline-block;
  padding: 10px;
  z-index: -9;
}

.intervention .top:hover {
  background: #219ebc;
  z-index: 999999999999 !important;
}

.intervention .bottom {
  background: #79d5ec;
  color: white;
  width: 230px;
  display: inline-block;
  padding: 10px;
  z-index: -9;
}

.intervention .bottom:hover {
  background: #22acd0;
  z-index: 999999999999 !important;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.2);
}

.mystyle {
  z-index: 99 !important;
}

.tpbtn {
  /* width: 50px;
  height: 50px; */
  border-radius: 18px;
  color: white;
}

.btn-problem {
  background-color: #ff9847;
}

.btn-goal {
  background-color: #62d860;
}

.btn-objective {
  background-color: #fd8181;
}

.btn-intervention {
  background-color: #25bde2;
}

.editBtn {
  display: none;
}

.intervention:hover .editBtn {
  display: flex !important;
  background: #25bde2;
  justify-content: center;
}

.objective:hover .editBtn {
  display: flex !important;
  background: #fd8182;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.goal:hover .editBtn {
  display: flex !important;
  background: #62d860;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.problem:hover .editBtn {
  display: flex !important;
  background: #ff9847;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.dimension:hover .editBtn {
  display: flex !important;
  background: #4aaffd;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 98%;
  bottom: 0px;
}

.editBtn div {
  background: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  box-shadow: 0px 11px 16px rgba(0, 0, 0, 0.0620356);
  margin: 5px;
  /* position: relative;
  top: 20px; */
}

.problem .top p,
.goal .top p,
.objective .top p,
.intervention .top p,
.goal .bottom div {
  font-size: 14px;
}

.problem .top p {
  display: -webkit-box;
  /* max-width: 400px; */
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  text-emphasis: left;
}

.goal .top p {
  display: -webkit-box;
  /* max-width: 400px; */
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  text-align: center;
  /* height: 32px; */
}

.goal .bottom div {
  display: -webkit-box;
  /* max-width: 400px; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  text-align: center;
}

.objective .top p {
  display: -webkit-box;
  /* max-width: 400px; */
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  text-align: center;
}

.intervention .top p {
  display: -webkit-box;
  /* max-width: 400px; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  text-align: center;
  height: 50px;
}

.problem:hover,
.goal:hover,
.objective:hover,
.intervention:hover,
.dimension:hover {
  z-index: 9;
  position: relative;
}

.ant-cascader-menu {
  max-width: 350px !important;
  min-width: 300px !important;
  height: auto !important;
}

.roles-permissions-modal {
  margin-bottom: 1.5rem;
}

.roles-permissions-modal .rolls {
  color: #000 !important;
  font-weight: 700;
  font-size: 18px;
  /* color: #46b4fe !important; */
}

.roles-permissions-modal .permissions-block {
  display: flex;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  border-bottom: 1px solid #d8d8d8;
  padding: 10px 0px;
}

.roles-permissions-modal .permissions {
  color: #bcbcbc !important;
  font-weight: 400 !important;
  font-size: 18px;
  line-height: unset !important;
  top: unset !important;
  display: flex !important;
  align-items: center !important;
}

.roles-permissions-modal .permissions-active {
  color: #46b4fe !important;
  font-weight: bold !important;
  font-size: 18px;
  /* color: #46b4fe !important; */
}

.rcm-panel .treatmentplan .assessment-main-title {
  padding: 10px 10px;
  background-color: rgb(70, 180, 254, 0.1);
  text-align: left;
  color: #46b4fe !important;
  margin-bottom: 50px;
}

/* .css-1jae07r {
  visibility: hidden !important;
} */
/* .dimension + ul.css-1jae07r::before {
	display: none;
} */
.group-client-search ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bcbcbc;
}

.intervention .notes {
  display: -webkit-box;
  color: white;
  background: rgb(37, 189, 226);
  font-size: 14px;
  padding: 5px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  text-align: left;
  height: 39px;
  cursor: context-menu;
}

/* .rcm .ant-select-selection.ant-select-selection--single {
  border: none !important;
} */
.ant-pagination-options .ant-select-selector {
  border: 0px !important;
}

.ant-pagination.mini .ant-pagination-options-size-changer {
  top: 22px !important;
}

.ant-pagination-options .ant-pagination-options-size-changer {
  top: 22px !important;
}

.ant-pagination.mini .ant-pagination-options {
  margin-left: 5px;
}

.jounal-user-note .tip {
  width: 0px;
  height: 0px;
  position: absolute;
  background: transparent;
}

.jounal-user-note .tip-comment {
  bottom: -5px;
  left: -25px;
  border: 10px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
}

.jounal-user-note .tip-reply {
  bottom: -5px;
  right: -24px;
  border: 10px solid rgb(0, 121, 253);
  border-top-color: transparent;
  border-right-color: transparent;
}

.jounal-user-note .comment-dialogbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.jounal-user-note .comment-dialogbox .comment-dialog-body {
  position: relative;
  min-width: 250px;
  height: auto;
  margin: 10px 10px 5px 10px;
  padding: 0px 5px;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 5px solid #fff;
}

.jounal-user-note .reply-dialogbox {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}

.jounal-user-note .reply-dialogbox .reply-dialog-body {
  position: relative;
  min-width: 250px;
  max-width: 70%;
  height: auto;
  /* margin: 10px 10px 5px 10px; */
  margin-left: auto;
  padding: 0px 5px;
  background-color: rgb(0, 121, 253);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 5px solid rgb(0, 121, 253);
}

.jounal-user-note .comment-dialog-body .comment-message {
  display: flex;
  min-height: 30px;
  border-radius: 3px;
  font-family: Arial;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
}

.jounal-user-note .comment-dialog-body .comment-message .message {
  display: flex;
  flex-direction: column;
  max-width: 225px;
}

.jounal-user-note .comment-dialog-body .comment-message .counselor-name {
  font-size: 1.1em;
  font-weight: 600;
  text-transform: capitalize;
}

.jounal-user-note .reply-dialog-body .reply-message {
  min-height: 30px;
  border-radius: 3px;
  font-family: Arial;
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  max-width: 225px;
}

.jounal-user-note .reply-dialog-body .reply-message {
  display: flex;
  min-height: 30px;
  border-radius: 3px;
  font-family: Arial;
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
}

.jounal-user-note .reply-dialog-body .reply-message .message {
  display: flex;
  flex-direction: column;
  max-width: 225px;
}

.jounal-user-note .reply-dialog-body .reply-message .counselor-name {
  font-size: 1.1em;
  font-weight: 600;
  text-transform: capitalize;
}

.jounal-user-note .chat-time {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #959595;
}

.jounal-user-post .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px;
  /* background-color: rgba(70, 180, 254, 0.1); */
  border-radius: 9px;
}

.jounal-user-note .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px;
  background-color: rgba(70, 180, 254, 0.3);
  border-radius: 9px;
}

.jounal-user-note .chat-form {
  /* background-color: #eeeeee; */
  align-items: flex-start;
  /* padding: 15px 15px; */
}

.jounal-user-note .chat-form .form-control {
  background-color: #eeeeee;
}

.user-note-panel .ant-collapse-content .ant-collapse-content-box {
  padding: 0px;
}

.user-note-panel .chat-form .form-group {
  position: relative;
  margin-bottom: 0px;
  /* padding-top: 1rem; */
}

.user-note-panel .chat-form .send-button {
  background: #4ab0fe;
  height: 55px;
  align-items: center;
  display: flex;
}

.user-note-panel .chat-form .send-button button {
  color: #fff;
}

.ant-tabs-nav-operations {
  display: none !important;
}


.show-tabs .ant-tabs-nav-operations {
  display: flex !important;
}

.show-tabs .ant-tabs-nav-wrap {
  display: flex !important;
}

.app-client-type-count {
  display: flex;
  justify-content: space-between;
}

/* New Dashboard CSS*/
.new-dashboard .in-house {}

.new-dashboard .dashboard-menu {
  display: flex;
  justify-content: center;
  border-bottom: 0px;
  /* border: 1px solid red; */
}

.new-dashboard .dashboard-menu * {
  /* border: 1px solid black; */
}

.cw-menu {
  display: flex;
  /* justify-content: space-between;  */
  border-bottom: 0px;
  flex-wrap: wrap;
}

.cw-menu .ant-menu-item-only-child {
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height */
  text-transform: uppercase;
  color: #000000;
  margin: 0 25px !important;
}

.cw-menu .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected {
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #46b4fe !important;
  border-bottom: 3px solid #4ab0fe !important;
}

.new-dashboard .in-house .client-type {}

.new-dashboard .in-house .client-type .client-type-header {
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
}

.new-dashboard .in-house .client-type .average-score-header {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;

  color: #000000;
}

.new-dashboard .in-house .client-type .progress-subtitle {
  font-weight: normal;
  font-size: 21px;
  line-height: 29px;
  text-align: center;

  color: #000000;
}

.new-dashboard .client-type-count,
.new-dashboard .in-house .client-type .client-type-count {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.new-dashboard .client-type-count .label-text .new-dashboard .in-house .client-type .client-type-count .label-text {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  color: #000000;
}

.textarea-height {
  height: 54px !important;
}

/* textarea.form-control {
  min-height: 60px !important;
} */

textarea::-webkit-input-placeholder {
  color: #d8d8d8 !important;
}

input.my-dictate-checkbox {
  display: none;
}

.mic-icon {
  height: 18px;
  width: fit-content;
}

.dictate-status {
  color: #4ab0fe;
  font-size: 16px;
  font-weight: bold;
}

.dictation-space {
  background-color: transparent;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  height: 50px;
  align-items: center;
  margin-bottom: 10px;
}

.dictation-space .dictate-status {
  align-items: left;
}

.mic-icon:hover {
  cursor: pointer;
}

.score-legend {
  /* position: absolute; */
  width: 161px;
  /* height: 84px; */
  padding: 5px 20px;
  background: #f8f8f8;
  border-radius: 12px;
  color: #979797;
}

.score-legend .score-bullet {
  margin: 0;
  padding: 0;
  list-style: none;
}

.score-legend .score-bullet>li {
  position: relative;
  padding-left: 20px;
}

.score-legend .score-bullet>li:before {
  content: '';
  width: 15px;
  height: 15px;
  background: #2fe192;
  display: inline-block;
  vertical-align: top;
  position: absolute;
  left: 0;
  top: 4px;
}

.score-legend .score-bullet>li:nth-child(2):before {
  background-color: #4facfe;
}

.score-legend .score-bullet>li:nth-child(3):before {
  background-color: #ff5d5d;
}

.weekly-block {
  background: #f8f8f8;
}

.breathalyzer-test-list {}

.breathalyzer-test-list .test-card {
  background: #ffffff;
  box-shadow: 0px 11px 16px rgba(0, 0, 0, 0.0620356);
  border-radius: 12px;
  padding: 10px 5px;
}

.breathalyzer-test-list .test-card .patient {
  display: flex;
  align-items: center;
  min-width: 200px;
  max-width: 200px;
}

@media (min-width: 960px) and (max-width: 1140px) {
  .breathalyzer-test-list .test-card .patient {
    display: flex;
    align-items: center;
    min-width: 150px;
    max-width: 150px;
  }
}

.breathalyzer-test-list .test-card .patient .patient-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.breathalyzer-test-list .test-card .patient-name .red-block {
  height: 10px;
  width: 10px;
  background-color: #ff6f58;
  display: inline-block;
  margin-right: 5px;
}

.breathalyzer-test-list .test-card .patient-name .red-text {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #ff6f58;
}

.breathalyzer-test-list .test-card .patient-name .green-block {
  height: 10px;
  width: 10px;
  background-color: #2fe192;
  display: inline-block;
  margin-right: 5px;
}

.breathalyzer-test-list .test-card .patient-name .green-text {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #2fe192;
}

.breathalyzer-test-list .test-card .patient-name .yellow-block {
  height: 10px;
  width: 10px;
  background-color: #fff200;
  display: inline-block;
  margin-right: 5px;
}

.breathalyzer-test-list .test-card .patient-name .yellow-text {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #fff200;
}

.breathalyzer-test-list .test-card .patient .patient-profile img {
  border-radius: 50%;
  width: 41px;
  min-width: 41px;
  height: 41px;
  margin-right: 5px;
}

.breathalyzer-test-list .test-card .last-test,
.breathalyzer-test-list .test-card .next-test {
  display: flex;
  flex-direction: column;
}

.breathalyzer-test-list .test-card .last-test .test-name,
.breathalyzer-test-list .test-card .next-test .test-name {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #979797;
  margin-right: 10px;
}

.breathalyzer-test-list .test-card .last-test .view-schedule,
.breathalyzer-test-list .test-card .next-test .view-schedule {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #4ab0fe;
  cursor: pointer;
}

.breathalyzer-test-list .test-card .last-test .test-date,
.breathalyzer-test-list .test-card .next-test .test-date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-right: 5px;
  color: #000000;
}

.repeat-weekly .ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
}

.repeat-weekly .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after,
.repeat-weekly .ant-checkbox-group-item .ant-checkbox .ant-checkbox-inner:after {
  top: 3px;
  left: 1px;
}

.repeat-weekly .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after {
  width: 12px;
  height: 6px;
}

.time-line-table .yellow-block {
  background-color: #fff200;
  color: #000;
  border-radius: 4px;
  padding: 4px;
}

.time-line-table .red-block {
  background-color: #ff3b3b;
  color: #ffffff;
  border-radius: 4px;
  padding: 4px;
}

.time-line-table .green-block {
  background-color: #2fe192;
  color: #ffffff;
  border-radius: 4px;
  padding: 4px;
}


.timeline-btn .preference-title {
  margin-right: -195px;
  margin-top: -30px;
  color: #000;
  font-size: 16px;
  font-weight: 700;
}

.timeline-btn .preference-link:first-child {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: -110px;
  border-radius: 100px 0 0 100px;
  margin-right: -1px;
}

.timeline-btn .preference-link:last-child {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 0 100px 100px 0;
  margin-left: -1px;
  margin-right: -69px;
}

.timeline-btn .preference-link {
  font-size: 16px;
  font-weight: 700;
  border: solid 1px #46b4fe;
  border-radius: 100px;
  color: #000;
  height: 52px;
  width: 152px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
}

.timeline-btn .preference-link.active {
  box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.04);
  background-image: linear-gradient(to left,
      #00f2fe,
      #03effe 2%,
      #24d2fe 29%,
      #3cbdfe 55%,
      #4ab0fe 80%,
      #4facfe);
  color: #fff;
  border-color: transparent;
  border: 0px;
}

.header {
  /* box-shadow: unset !important; */
  padding: 8px 0;
  position: sticky;
  top: 0px;
  z-index: 100;
}

.custom-pagination {
  display: flex;
  justify-content: flex-end;
}

.custom-pagination .ant-pagination-options .ant-select-selector {
  border: 0px !important;
}

.custom-pagination .ant-pagination.mini .ant-pagination-options-size-changer {
  top: 3px;
}

.custom-pagination .ant-pagination-options .ant-pagination-options-size-changer {
  top: 3px;
}

.custom-pagination .ant-pagination.mini .ant-pagination-options {
  margin-left: 5px;
}

.custom-pagination .ant-pagination-item-active a {
  background: linear-gradient(90deg, #00f1fe 0, #4facfe 50%, #ebebeb 0, #ebebeb) !important;
  background-size: 200% 100% !important;
  color: #fff !important;
}

.custom-pagination .ant-pagination-item a {
  box-sizing: border-box;
  display: inline-block;
  padding: 0.5rem 0.75rem;
  font-size: 12px;
  margin-left: 6px;
  line-height: 1.25;
  text-align: center;
  text-decoration: none !important;
  color: #333;
  border-radius: 7px;
  background: #ebebeb;
  vertical-align: top;
  background: -webkit-linear-gradient(left, #00f1fe 0%, #4facfe 50%, #ebebeb 50%, #ebebeb 100%);
  background: -ms-linear-gradient(left, #00f1fe 0%, #4facfe 50%, #ebebeb 50%, #ebebeb 100%);
  background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #ebebeb 50%, #ebebeb 100%);
  background-size: 200% 100%;
  background-position: right top;
  font-weight: 600;
}

.custom-pagination .ant-pagination-next,
.custom-pagination .ant-pagination-prev {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 0px;
}

.custom-pagination .ant-pagination-next .ant-pagination-item-link,
.custom-pagination .ant-pagination-prev .ant-pagination-item-link {
  font-size: 14px;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  text-align: center;
  /* display: inline-flex; */
  align-items: center;
  justify-content: center;
  /* margin-top: 3px; */
}




@media print {
  .fa-calendar-alt.form-control-icon {
    display: none;
  }

  h5 {
    margin-bottom: 10px !important;
  }

  .print-time-textarea-novalue {
    display: none;
  }

  .answer-options .selected {
    font-weight: 800 !important;
    color: #000 !important;
    border: 0.5px dashed #000000 !important;
  }

  .enrollment-form .select__placeholder,
  .enrollment-form .select__single-value {
    position: relative;
    top: 8px;
  }

  .print-growthplan * {
    font-size: 19px !important;
  }



  input.growth-plan-student-name.not-empty.false.form-control~.placeholder-label {
    font-size: 15px !important;
  }

  .field-array-item .form-group .placeholder-label {
    font-size: 15px !important;
    margin-top: 12px !important;
  }

  .field-array-item.field-item .form-group .placeholder-label {
    font-size: 15px !important;
    margin-top: 0 !important;
  }


  .field-array-item .form-group .placeholder-label~.form-control {
    /* margin-top: 15px !important;  */
    /* padding-top: 25px !important;  */
  }

  .print-growthplan .contract-form .student-lable {
    font-size: 49px !important;
  }

  .small-info {
    font-size: 13px !important;
    display: none;
  }

  .form-control {
    /* color: red; */
    margin-top: 10px !important;
    padding-top: 13px !important;
  }

  /* .form-control {
    font-size: 1.05rem !important;
  } */

  .custom-select.enrollment-select {
    color: red !important;
    margin-top: 10px !important;
    background-color: red !important;
  }




  .datepicker-label {
    font-size: 15px !important;
  }


  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }

  /* .page-break {
    display: block;
    page-break-before: always;
  } */

  .print-btn {
    display: none;
  }


  .discharge-print ul.answer-options li {
    list-style: none !important;
  }

  .placeholder-label {
    font-size: 15px !important;
    /* color: red !important; */
  }


  .css-1pcexqc-container {
    padding-top: 15px !important;
  }

}


.dimension-panel-border .ant-collapse-header {
  border-left: 3.5px solid rgb(74, 175, 253) !important;
  /* background: rgba(70, 180, 254, 0.373142); */
  background: #fff;
}

.problem-panel-border .ant-collapse-header {
  border-left: 3.5px solid #ff9847 !important;
  /* background: rgba(70, 180, 254, 0.373142); */
  background: #fff;
}

.goal-panel-border .ant-collapse-header {
  border-left: 3.5px solid #62d860 !important;
  /* background: rgba(70, 180, 254, 0.373142); */
  background: #fff !important;
}

.objective-panel-border .ant-collapse-header {
  border-left: 3.5px solid #fd8181 !important;
  /* background: rgba(70, 180, 254, 0.373142); */
  background: #fff;
}

.intervention-panel-border .ant-collapse-header {
  border-left: 3.5px solid #46b4fe !important;
  /* background: rgba(70, 180, 254, 0.373142); */
  background: #fff;
}

.group-name .select__single-value {
  color: rgb(24, 144, 255) !important;
}

.cursor-pointer {
  cursor: pointer !important;
  display: flex;
}

/* **************************Timeline End ****************************************/

.timeline .box .date {
  background: #e3f4ff;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #515151;
  padding: 5px 15px;
  display: inline-block;
}

.timeline .box .icon {
  background: #fff;
  box-shadow: 0px 4px 9px rgb(0 0 0 / 10%);
  border-radius: 40px;
  padding: 10px;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline .box .time {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 27px;
  text-align: center;
  color: #979797;
  word-wrap: normal;
}

/* .timeline .box .time:after {

  border-bottom: 1px solid #979797;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 39%;
  bottom: -40px;
  margin: 0 auto;
  transform: rotate(90deg);
} */
.event-list .event-list-group {
  flex-direction: column;
  display: flex;
}

.event-list .ant-checkbox-group-item {
  margin-bottom: 5px !important;
}

.event-list .event-list-group .ant-checkbox-wrapper {
  margin: 10px 0px;
}

.timetravel-filter {
  height: 450px;
  overflow-y: scroll;
}

.timeline .box div:not(:last-child) div.time:after {
  border-bottom: 1px solid #979797;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 25%;
  bottom: -45px;
  /* width: 19%;
  bottom: -34px; */
  margin: 0 auto;
  transform: rotate(90deg);
}

.timeline .box .row_default .event-details {
  background: #f8f8f8;
}

.timeline .box .row_info .event-details {
  background: #f2f8ff;
}

.timeline .box .row_success .event-details {
  background: rgba(1, 217, 121, 0.05);
}

.timeline .box .row_warning .event-details {
  background: rgba(255, 133, 0, 0.05);
}

.timeline .box .row_danger .event-details {
  background: rgba(255, 244, 244, 0.7);
}

.timeline .box .row_orange .event-details {
  background: rgba(255, 184, 0, 0.05);
}

.timeline .box .row_green .event-details {
  background: rgba(1, 217, 121, 0.05);
}

.timeline .box .event-details {
  /* background: #f8f8f8; */
  border-radius: 8px;
  padding: 23px 35px;
}

.timeline .box .row_default .event-details .header-content {
  color: #515151;
}

.timeline .box .row_info .event-details .header-content {
  color: rgba(0, 120, 255, 1);
}

.timeline .box .row_success .event-details .header-content {
  color: rgba(1, 217, 121, 1);
}

.timeline .box .row_warning .event-details .header-content {
  color: rgba(255, 133, 0, 1);
}

.timeline .box .row_danger .event-details .header-content {
  color: rgba(255, 121, 121, 1);
}

.timeline .box .row_orange .event-details .header-content {
  color: rgba(255, 184, 0, 1);
}

.timeline .box .row_green .event-details .header-content {
  color: rgba(1, 217, 121, 1);
}

.timeline .box .event-details .header-content {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  /* color: #515151; */
  display: flex;
  word-break: break-word;
}

.timeline .box .event-details .desc {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #828282;
}

.timelineDrawer .ant-drawer-body {
  background: #fff !important;
}

/* **************************Timeline End ****************************************/

.breathalyzer-block {
  position: relative;
}

.breathalyzer-blur-block {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
  pointer-events: none;
}

.breathalyzer-blur-block-btn {
  /* Fallback color */
  /* background-color: rgb(0, 0, 0); */
  /* Black w/opacity/see-through */
  /* background-color: rgba(0, 0, 0, 0.4); */
  color: white;
  /* border: 3px solid #f1f1f1; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  /* width: 50%; */
  padding: 10px;
  text-align: center;
}

.breathalyzer-tab {
  margin-top: 1rem;
}

.breathalyzer-tab .Activities .ant-tabs-nav-wrap {
  background-color: #e7e7e7 !important;
}

.breathalyzer-tab .Activities .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  justify-content: space-around;
}

.breathalyzer-tab .Activities .ant-tabs-tab-active {
  /* background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%);
  border: 1px solid linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%); */
  background-size: 300% 100%;
  color: #fff !important;
  /* padding: 10px 20px;
  border-radius: 26px; */
}

/* **************************Pricing ****************************************/
.individual-pricing {
  padding: 10px;
}

.individual-pricing .pricing-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  text-align: center;
  color: #000000;
}

.individual-pricing .pricing-section {
  display: flex;
  justify-content: space-around;
}

.individual-pricing .default-pricing-section {
  display: flex;
  flex-direction: column;
}

.individual-pricing .default-pricing-section .center-block {
  text-align: center !important;
  text-align: -webkit-center !important;
}

.default-item-section .ant-table-content {
  height: 800px;
}

.individual-pricing .cost-per-session,
.individual-pricing .monthly-cost {
  position: relative;
  width: 411.95px;
  height: 355.85px;
  border: 2px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 12px;
}

.individual-pricing .cost-per-session .price-img,
.individual-pricing .monthly-cost .price-img {
  text-align: center;
  margin: 10px;
}

.individual-pricing .cost-per-session .session-block-text,
.individual-pricing .monthly-cost .session-block-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}

.individual-pricing .cost-per-session .slider-block,
.individual-pricing .monthly-cost .slider-block {
  position: absolute;
}

.individual-pricing .cost-per-session .slider,
.individual-pricing .monthly-cost .slider {
  position: absolute;
  width: 388.42px;
  height: 125.89px;
  left: 10px;
  /* top: 547.03px; */

  background: #f8f8f8;
  border-radius: 12px;
}

.individual-pricing .cost-per-session .warning-notification-block,
.individual-pricing .monthly-cost .warning-notification-block {
  position: absolute;
  width: 411.9px;
  height: 45.88px;
  left: -2px;
  bottom: -2px;
  background: #ffe3c4;
  border-radius: 0px 0px 12px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.individual-pricing .cost-per-session .success-notification-block,
.individual-pricing .monthly-cost .success-notification-block {
  position: absolute;
  width: 411.9px;
  height: 45.88px;
  left: -2px;
  bottom: -2px;
  background: #c3ffe4;
  border-radius: 0px 0px 12px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.individual-pricing .cost-per-session .warning-notification-block .notification,
.individual-pricing .monthly-cost .warning-notification-block .notification {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #fa8602;
}

.individual-pricing .cost-per-session .success-notification-block .notification,
.individual-pricing .monthly-cost .success-notification-block .notification {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #3b8e51;
}

.individual-pricing .cost-per-session .pricing-alert-note,
.individual-pricing .monthly-cost .pricing-alert-note {
  position: absolute;
  width: 411.9px;
  height: 45.88px;
  left: -2px;
  bottom: 40px;
  /* background: #ffe3c4; */
  border-radius: 0px 0px 12px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.individual-pricing .cost-per-session .pricing-alert-note .note,
.individual-pricing .monthly-cost .pricing-alert-note .note {
  font-weight: 600;
  white-space: nowrap;
  padding: 5px;
}

.individual-pricing .cost-per-session .pricing-alert-note .note-msg,
.individual-pricing .monthly-cost .pricing-alert-note .note-msg {
  font-weight: 500;
  font-style: italic;
  color: rgb(151, 151, 151);
  font-size: 13px;
}

.pricing-tab .dashboard-menu {
  display: flex;
  justify-content: space-between;
  border-bottom: 0px;
  background-color: #e3f4ff;
}

.default-item-section .disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}

.pricing-slider-wrapper {}

/* .pricing-slider-wrapper .ant-slider-rail {
  height: 5px;
  background: #adadad;
  background: -moz-linear-gradient(
    left,
    #ff9a4b 0%,
    #ff9a4b 30%,
    #4de76f 30%,
    #4de76f 60%,
    #ff9a4b 60%,
    #ff9a4b 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, #ff9a4b),
    color-stop(30%, #ff9a4b),
    color-stop(30%, #4de76f),
    color-stop(60%, #4de76f),
    color-stop(60%, #ff9a4b),
    color-stop(100%, #ff9a4b)
  );
  background: -webkit-linear-gradient(
    left,
    #ff9a4b 0%,
    #ff9a4b 30%,
    #4de76f 30%,
    #4de76f 60%,
    #ff9a4b 60%,
    #ff9a4b 100%
  );
  background: -o-linear-gradient(
    left,
    #ff9a4b 0%,
    #ff9a4b 30%,
    #4de76f 30%,
    #4de76f 60%,
    #ff9a4b 60%,
    #ff9a4b 100%
  );
  background: -ms-linear-gradient(
    left,
    #ff9a4b 0%,
    #ff9a4b 30%,
    #4de76f 30%,
    #4de76f 60%,
    #ff9a4b 60%,
    #ff9a4b 100%
  );
  background: linear-gradient(
    to right,
    #ff9a4b 0%,
    #ff9a4b 30%,
    #4de76f 30%,
    #4de76f 60%,
    #ff9a4b 60%,
    #ff9a4b 100%
  );
  border-radius: 2px;
} */
/* .pricing-slider-wrapper .ant-slider-track {
  height: 4px;
  background-color: #91d5ff;
  border-radius: 2px;
} */
.pricing-slider-wrapper .ant-slider-step {
  height: 4px;
  background: transparent;
}

/* .pricing-slider-wrapper .ant-slider-handle {
  width: 16px;
  height: 16px;
  margin-top: -7px;
  background-color: #fff;
  border: solid 4px
    linear-gradient(
      90deg,
      #00f2fe 0%,
      #03effe 2.8%,
      #24d2fe 39.48%,
      #3cbdfe 74.59%,
      #4ab0fe 107.15%,
      #4facfe 134.68%
    );
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
} */

/* .pricing-slider-wrapper .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: linear-gradient(
    90deg,
    #00f2fe 0%,
    #03effe 2.8%,
    #24d2fe 39.48%,
    #3cbdfe 74.59%,
    #4ab0fe 107.15%,
    #4facfe 134.68%
  ) !important;
} */
/* .pricing-slider-wrapper .ant-slider-handle:focus {
  border-color: linear-gradient(
    90deg,
    #00f2fe 0%,
    #03effe 2.8%,
    #24d2fe 39.48%,
    #3cbdfe 74.59%,
    #4ab0fe 107.15%,
    #4facfe 134.68%
  ) !important;
  outline: none;
  -webkit-box-shadow: none;
  background-color: #fff !important;
  box-shadow: none;
} */
/* .pricing-slider-wrapper .ant-slider-handle.ant-tooltip-open {
  background-color: #fff !important;
  border-color: linear-gradient(
    90deg,
    #00f2fe 0%,
    #03effe 2.8%,
    #24d2fe 39.48%,
    #3cbdfe 74.59%,
    #4ab0fe 107.15%,
    #4facfe 134.68%
  ) !important;
} */
.rc-slider-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 8px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background: linear-gradient(90deg,
      #00f2fe 0%,
      #03effe 2.8%,
      #24d2fe 39.48%,
      #3cbdfe 74.59%,
      #4ab0fe 107.15%,
      #4facfe 134.68%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0620356);
  border-radius: 4px;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #2acdfe !important;
}

/* .ant-tooltip-arrow-content {
  background: linear-gradient(
    90deg,
    #00f2fe 0%,
    #03effe 2.8%,
    #24d2fe 39.48%,
    #3cbdfe 74.59%,
    #4ab0fe 107.15%,
    #4facfe 134.68%
  );
} */
.tooltipFormat {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.ant-slider-mark-text:has(.recommended-area) {
  padding: 15px;
  top: 15px;
  background-color: red;
}

/* .ant-slider-mark-text < .recommended-area {
  background-color: red;
} */
/* .ant-slider-mark-text .recommended-area :parent {
  top: 15px;
  background-color: red;
} */

/* .recommended-area {
  width: 149.43px;
  height: 45.87px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: matrix(1, 0, 0, -1, 0, 0);
} */
.recommended-area {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 10px;
}

.recommended-area .recommended-area-body {
  position: relative;
  width: 149.43px;
  height: 30.87px;
  margin-left: auto;
  padding: 0px 5px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  background: #f8f8f8;
}

.recommended-area .tip {
  width: 0px;
  height: 0px;
  position: absolute;
  background: transparent;
}

.recommended-area .tip-reply {
  top: -21px;
  left: 69px;
  border: 10px solid #d8d8d8;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}

.pricing-search-box {
  max-width: 500px;
}

.form-group.has-search {
  position: relative;
}

.pricing-search-box .has-search .form-control {
  padding-right: 50px !important;
  border-radius: 26px !important;
  border: none !important;
  color: #bcbcbc;
  font-size: 16px;
  height: 45px;
  padding-left: 25px !important;
}

/* .search-box .has-search .form-control{
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 50px !important;
  height: 37px !important; 
}

.search-box .has-search .search-icon{
  top: -8px !important;
} */
.pricing-search-box .has-search .search-icon {
  position: absolute;
  z-index: 1;
  display: block;
  width: 50px;
  height: 52px;
  text-align: center;
  color: #000;
  top: 0;
  right: 10px;
  box-shadow: none;
  background: none;
  border-radius: 0;
}

.pricing-search-box .has-search .search-icon:focus,
.search-box .has-search .search-icon:hover {
  box-shadow: none !important;
  color: #9c9c9c;
}

.pricing-search-box .has-search .form-control::placeholder {
  color: #bcbcbc;
}

.default-pricing .ant-radio-inner {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 1px #bcbcbc;
  /* display: block; */
  cursor: pointer;
  position: relative;
}

/* **************************Pricing ****************************************/

.claimIndex {
  padding-right: 15px;
  font-weight: 600;
}

.question {
  width: 100%;
}

.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #fff !important;
}

.rc-time-picker-input {
  border: none !important;
  font-size: 16px !important;
  color: black !important;
  min-height: 27px !important;
}

.rc-time-picker-clear-icon {
  display: none !important;
}

.student-client-list .ant-table-pagination.ant-pagination {
  margin: 16px 30px;
}

.danger-custom-btn {
  border: 1px solid red;
  padding: 12.5px 20px;
  line-height: 24px;
  border-radius: 26px;
  color: red;
  font-weight: bold;
  cursor: pointer;
}

/* .timetravel-filter > label.ant-checkbox-wrapper span:nth-child(2) {
  color: red !important;
} */


.timetravel-filter .ant-checkbox-group label.ant-checkbox-wrapper span:nth-child(2) {
  color: #979797;
  font-weight: 600;
  align-self: center;
}


.timetravel-filter label.ant-checkbox-wrapper span:nth-child(2) {
  align-self: center;
}


/* Purchase Analytics Package */
.purchase-analytics-package-body {
  background: #fff;
  /* height: 172px; */
  border-radius: 12px;
  padding: 1px;
  margin-bottom: 10px;
}

.purchase-analytics-package {
  background: url(/images/purchase-analytics-package.svg) no-repeat 95% center;
  /* filter: grayscale(80%); */
  height: 100%;
  padding: 2rem 2.5rem;
}

.analytics-package {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.analytics-package .list-item {
  display: flex;
}

.analytics-package .purchase-button button {
  background: linear-gradient(90.73deg, #ebc58c 0%, #dcbc4c 100%);
  border-radius: 122px;
  padding: 5px 20px;
}

/* Purchase Analytics Package */
.isPositive {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px 6px;
  /* border: 2px solid #ff5d5d; */
  color: #fff;
  text-align: center;
  font: 14px Arial, sans-serif;
  position: absolute;
  top: -15px;
  left: 32px;
}

.event-right-item .sparkline svg {
  width: 200px;
}

.sparkline circle {
  stroke: none;
  stroke-width: 0;
  fill-opacity: unset !important;
  fill: #179bef !important;
  pointer-events: auto;
  background: #179bef;
}

.autoSelectImage {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.MultipleCategoryCheckboxAll2 .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.MultipleCategoryCheckboxAll2 .ant-checkbox-group-item {
  display: flex;
  align-items: center;
}

/* //this two below classes are for broadcast invitation not needed right now
.max-content {
  width: max-content !important;
}
.slick-slide {
  width: auto !important;
} */

.app-subscriber-page .basic-single .select__control {
  height: 45px !important;
  border-bottom: 0px;
  border-radius: 5px !important;
}



.app-subscriber-page .basic-single .select__control .select__single-value,
.app-subscriber-page .basic-single .select__control .select__input-container {
  margin-left: 10px !important
}

.dynamic-grid-component .filter-dropdown .basic-single .select__control {
  height: 45px !important;
  border-bottom: 0px;
  /* border-radius: 5px !important; */
}

.broadcast-invitation .custom-redux-checkbox {
  display: flex;
  align-items: center;
  padding-left: 0.5rem !important;
}

.broadcast-invitation .custom-redux-checkbox label {
  margin-bottom: 0px !important;
}

.app-subscriber-page .css-1hwfws3 {
  padding: 10px !important;
}

.colorIndicators {
  width: 6px;
  height: 6px;
  border-radius: 4px;
}

.indicator-section .ant-popover,
.ant-popover-content,
.ant-popover-inner {
  width: 180px !important;
}

.extra-menu-item:hover {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}

.row-hover-enabled .ant-table-row:hover {
  cursor: pointer;
}

.twilio-modal .header {
  color: #828282;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
}

.twilio-modal .user-name {
  color: #000000;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 20px;
}

.twilio-modal .user-phone {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}

.twilio-notes {
  margin-top: 15px;
}

.twilio-notes .text-box {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.twilio-notes .info-text {
  font-style: italic;
  font-size: 13px;
  color: #959595;
}

textarea.form-control {
  resize: vertical !important;
  /* display: inline-block; */
  /* width: 100%; */
}

.students-page .ant-collapse>.ant-collapse-item>.ant-collapse-header,
.landM .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  display: block !important;
}

.moods .ant-popover,
.ant-popover-content,
.ant-popover-inner {
  width: 400px !important;
}

.image_picker .responsive .thumbnail {
  width: 80px !important;
  height: 80px !important;
}

.image_picker .selected .checked .icon {
  height: 15px !important;
  width: 15px !important;
}

.multi-step-form .steps-content {
  min-height: 200px;
  margin-top: 16px;
  /* padding-top: 80px; */
  /* text-align: center; */
  background-color: #fff;
  border: 1px dashed #fafafa;
  border-radius: 2px;
}

.multi-step-form .steps-action {
  /* margin-top: 24px; */
}

/* .multi-step-form .ant-steps-item-icon .ant-steps-icon {
  top: -3px;
} */

.multi-step-form .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #ffffff;
  /* top: -5px; */
}

.prospect-steps .multi-step-form .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  top: -5px;
}

.multi-step-form .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #2fe192;
  border-color: #2fe192;
}

.multi-step-form .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
  color: #ffffff;
  top: -2.5px;
}

.multi-step-form .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
  color: #ffffff;
  top: -2.5px;
}

.multi-step-form .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #b8b8b8;
  border-color: #b8b8b8;
}

.multi-step-form .ant-steps-item-wait .ant-steps-item-content .ant-steps-item-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.385882px;
  color: #b8b8b8;
}

.multi-step-form .ant-steps-item-process .ant-steps-item-content .ant-steps-item-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.385882px;
  color: #46b4fe;
}

.multi-step-form .ant-steps-item-finish .ant-steps-item-content .ant-steps-item-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.385882px;
  color: #2fe192;
}

.invite-multi .ant-steps {
  width: 66% !important;
  margin: 0 auto;
}

.Toastify__toast--success {
  width: 100% !important;
}

.Toastify__toast--success::before {
  width: 100% !important;
}

.Toastify__toast--success::after {
  width: 100% !important;
}

.Toastify__toast-container--top-center {
  width: 46% !important;
  margin: 0 auto;
}

.Toastify__toast-body div {
  width: 4% !important;
}

/* 
.pic-circle-arrow {
  border: 20px solid transparent;
  border-top-color: black;
  border-left-color: black;
  border-bottom-color: black;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
  transform: rotate(-45deg);
  margin: 0px auto;
}
.pic-circle-arrow:before {
  content: '';
  position: absolute;
  top: -20px;
  left: 80%;
  height: 0;
  width: 0;
  border-left: 30px solid black;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  transform: rotate(45deg);
} */

.pic-circle-arrow-up {
  /* border-top-color: #006400; */
  border: 2px solid transparent;
  border-top-color: rgb(47, 225, 146);
  border-left-color: rgb(47, 225, 146);
  border-bottom-color: rgb(47, 225, 146);
  /* height: 49px;
  width: 49px; */
  border-radius: 50%;
  position: relative;
  transform: rotate(38deg);
  margin: 0px auto;
  padding: 0.25rem;
}

.pic-circle-arrow-up .student-list-pic {
  transform: rotate(321deg);
}

/* .pic-circle-arrow-up:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 84%;
  height: 0;
  width: 0;
  border-left: 6px solid rgb(47, 225, 146);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  transform: rotate(45deg);
} */
.pic-circle-arrow-up:before {
  content: '';
  position: absolute;
  top: -0.5px;
  left: 83%;
  height: 8px;
  width: 1.5px;
  /* -webkit-transform: rotate(45deg); */
  background-color: rgb(47, 225, 146);
  transform: rotate(-20deg);
  border-radius: 5px;
}


.pic-circle-arrow-up:after {
  content: '';
  position: absolute;
  top: 3.5px;
  right: 11px;
  height: 8px;
  width: 1.5px;
  /* -webkit-transform: rotate(45deg); */
  background-color: rgb(47, 225, 146);
  transform: rotate(95deg);
  border-radius: 5px;
}

.pic-circle-arrow-down {
  /* border: 5px solid transparent; */
  border: 2px solid transparent;
  border-top-color: rgb(232, 122, 122);
  /* border-left-color: rgb(232, 122, 122); */
  border-bottom-color: rgb(232, 122, 122);
  border-right-color: rgb(232, 122, 122);
  /* height: 49px;
  width: 49px; */
  border-radius: 50%;
  position: relative;
  transform: rotate(325deg);
  margin: 0px auto;
  padding: 0.25rem;
}

.performance-charts .pic-circle-arrow-down,
.performance-charts .pic-circle-arrow-up {
  height: fit-content;
  width: fit-content;
  transform: rotate(0deg);
}

.performance-charts .pic-circle-arrow-up::after {
  content: '';
  position: absolute;
  top: 9.5px;
  right: 19px;
  height: 8px;
  width: 1.5px;
  /* -webkit-transform: rotate(45deg); */
  background-color: rgb(47, 225, 146);
  -webkit-transform: rotate(95deg);
  transform: rotate(107deg);
  border-radius: 5px;
}


.performance-charts .pic-circle-arrow-up::before {
  content: '';
  position: absolute;
  top: 6.5px;
  left: 84%;
  height: 8px;
  width: 1.5px;
  /* -webkit-transform: rotate(45deg); */
  background-color: rgb(47, 225, 146);
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
  border-radius: 5px;
}

.performance-charts .pic-circle-arrow-down::before {
  content: '';
  position: absolute;
  width: 1.5px;
  height: 8px;
  background-color: rgb(232, 122, 122);
  top: 0;
  left: 0;
  -webkit-transform: rotate(150deg);
  transform: rotate(193deg);
  border-radius: 5px;
  top: 7.5px;
  left: 15.1px;
}

.performance-charts .pic-circle-arrow-down::after {
  content: '';
  position: absolute;
  width: 1.5px;
  height: 8px;
  background-color: rgb(232, 122, 122);
  top: 10.8px;
  left: 18.2px;
  -webkit-transform: rotate(-150deg);
  transform: rotate(-99deg);
  border-radius: 5px;
}

.pic-circle-arrow-down .student-list-pic {
  transform: rotate(35deg);
}

/* .pic-circle-arrow-down:before {
  content: '';
  position: absolute;
  top: 31px;
  left: 77%;
  height: 0;
  width: 0;
  border-left: 6px solid rgb(232, 122, 122);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  transform: rotate(133deg);
} */
.pic-circle-arrow-down::before {
  content: '';
  position: absolute;
  width: 1.5px;
  height: 8px;
  background-color: rgb(232, 122, 122);
  top: 0;
  left: 0;
  -webkit-transform: rotate(150deg);
  transform: rotate(193deg);
  border-radius: 5px;
  top: 0.5px;
  left: 7.1px;
}

.pic-circle-arrow-down::after {
  content: '';
  position: absolute;
  width: 1.5px;
  height: 8px;
  background-color: rgb(232, 122, 122);
  top: 4px;
  left: 10px;
  -webkit-transform: rotate(-150deg);
  transform: rotate(-90deg);
  border-radius: 5px;
}

.pic-circle-neutral {
  border: 2px solid transparent;
  border-top-color: rgba(255, 168, 0, 1);
  border-left-color: rgba(255, 168, 0, 1);
  border-right-color: rgba(255, 168, 0, 1);
  border-bottom-color: rgba(255, 168, 0, 1);
  /* height: 49px;
  width: 49px; */
  border-radius: 50%;
  position: relative;
  transform: rotate(359deg);
  margin: 0px auto;
  padding: 0.25rem;
}

.meeting-checkbox .ant-switch .ant-switch-inner {
  color: #000;
}

.meeting-checkbox .ant-switch-checked .ant-switch-inner {
  color: #fff;
}

.trend-level-possitive {
  border-radius: '2px' !important;
  background: '#FF7979' !important;
  color: 'white' !important;
  padding: '2px' !important;
  font-size: '12px' !important;
}

.trend-level-negative {
  border-radius: '2px' !important;
  background: '#2FE192' !important;
  color: 'white' !important;
  padding: '2px' !important;
  font-size: '12px' !important;
}

.trend-user .member-trend-table .ant-table-content {
  overflow: unset;
}

.survey-question-date .react-datepicker-wrapper {
  width: unset;
}

.survey-answer h6 b,
.survey-answer h6,
.survey-answer h5 {
  font-weight: normal;
  font-size: 12px;
}

/* Manage Counselor Service */
.manage-counselor-service {}

.manage-counselor-service .mcs-card {
  background: #ffffff;
  box-shadow: 0px 11px 16px rgba(0, 0, 0, 0.0620356);
  border-radius: 12px;
  padding: 15px 15px;
}

.manage-counselor-service .manage-service {
  font-size: 26px;
  color: #000000;
}

.manage-counselor-service .counselor-assigned-area,
.manage-counselor-service .premium-membership-block,
.manage-counselor-service .counselor-service-block {
  background: #f8f8f8;
  border-radius: 9px;
  padding: 10px 5px;
  height: 162px;
}

.manage-counselor-service .mcs-card .no-subscriptions-area {
  height: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-counselor-service .no-subscriptions-area .no-subscriptions-text {
  color: #000000;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 25px;
}

.manage-counselor-service .counselor-assigned-area .counselor-assigned {
  border-right: 1px solid #eeeeee;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.manage-counselor-service .counselor-assigned-area .counselor-assigned .assigned-counselor-info {
  display: flex;
}

.manage-counselor-service .counselor-info .counselor {
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.manage-counselor-service .counselor-info .renewal-date {
  font-size: 16px;
  line-height: 22px;
}

.manage-counselor-service .counselor-info .renewal-date .text {
  color: #828282;
  margin-right: 5px;
}

.manage-counselor-service .counselor-info .renewal-date .date {
  color: #000000;
}

.manage-counselor-service .counselor-info .counselor-fee {
  font-size: 16px;
  line-height: 22px;
}

.manage-counselor-service .counselor-info .counselor-fee .text {
  color: #000000;
}

.manage-counselor-service .counselor-info .counselor-fee .fee {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

.manage-counselor-service .counselor-assigned-area .no-counselor-assigned {
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-counselor-service .no-counselor-assigned .no-counselor-text {
  color: #000000;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 25px;
}

.manage-counselor-service .counselor-assigned-area .counselor-change {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.manage-counselor-service .counselor-assigned-area .counselor-change .counselor-label {
  color: #46b4fe;
  border: 1px solid #46b4fe;
  padding: 2px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.manage-counselor-service .counselor-assigned-area .counselor-change .counselor-name {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.manage-counselor-service .counselor-assigned-area .counselor-change .counselor-add {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */
  color: #828282;
}

.manage-counselor-service .mcs-card .warning-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ff8c39;
  white-space: nowrap;
}

.manage-counselor-service .mcs-card .danger-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ff5d5d;
  white-space: nowrap;
}

.manage-counselor-service .mcs-card .dismiss {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #4ab0fe;
  cursor: pointer;
}

.modify-service .ant-steps {
  width: 100% !important;
  margin: 0 auto;
}

.modify-service .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  /* color: #fff; */
  top: -5px;
}

.modify-service .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
  /* color: #fff; */
  top: -2.5px;
}

.modify-service .counselor-box,
.modify-service .coach-box {
  height: 143px;
  background: #f8f8f8;
  border-radius: 9px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modify-service .counselor-box .c-info,
.modify-service .coach-box .c-info {}

.modify-service .counselor-box .c-info .name,
.modify-service .coach-box .c-info .name {
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.modify-service .counselor-box .c-info .info-text,
.modify-service .coach-box .c-info .info-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #828282;
}

.modify-service .counselor-box .c-info .fee-info,
.modify-service .coach-box .c-info .fee-info {
  font-size: 16px;
  line-height: 22px;
}

.modify-service .counselor-box .c-info .fee-info .fee,
.modify-service .coach-box .c-info .fee-info .fee {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

.modify-service .counselor-box .c-info .fee-info .text,
.modify-service .coach-box .c-info .fee-info .text {
  color: #000000;
}

.dismiss-request .name {
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.dismiss-request .date-info {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}

.dismiss-request .date-info .text {
  color: #828282;
  margin-right: 10px;
}

.dismiss-request .date-info .value {
  color: #000000;
}

.dismiss-request .fee-info .text {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.dismiss-request .fee-info .value {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin-right: 5px;
}

.enrollment-page .top-heading-row .client-header .btn {
  padding: 2px 0px !important;
  min-width: 75px;
}

.premium-membership-block .show-plan .text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #828282;
}

.premium-membership-block .show-plan .date {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.students-profile .patient-info .name {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #000000;
}

.students-profile .patient-info .id {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;

  color: #979797;
}

.ent-multi-form .survey-question {
  margin: 0;
  margin-bottom: 30px;
}

.question-preview .question {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}

.question-preview .option {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #828282;
}

.field-info {
  border-bottom: none !important;
  /* to make this field looks like information text*/
}

.field-info input[type='text']:disabled {
  border-bottom: none !important;
}

.ant-popover-inner-content p:hover {
  background-color: rgba(0, 0, 0, .09);
  font-weight: 600px;
  /* padding: 10px !important; */
}

.enroll-btn .ant-dropdown-button .ant-btn-primary:first-child {
  font-weight: bold;
  color: #fff;
  border-radius: 26px 0px 0px 26px;
  /* border-color: #0079fd; */
  text-shadow: none;
  padding: 10px 20px;
  /* line-height: 24px; */
  background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%);
  /* background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%);
      background-image: none; */
  background-size: 300% 100%;
  transition: all 300ms ease-in-out;
  height: auto;
  border: none;
}

.enroll-btn .ant-dropdown-button .ant-btn-primary:last-child {
  font-weight: bold;
  color: #fff;
  border-radius: 0px 26px 26px 0px;
  /* border-color: #0079fd; */
  text-shadow: none;
  padding: 10px 20px;
  /* line-height: 24px; */
  background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%);
  /* background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%);
      background-image: none; */
  background-size: 300% 100%;
  transition: all 300ms ease-in-out;
  height: auto;
  border-left: 0px !important;
  border: none;
}

.ant-popover-title {
  padding-top: 20px;
}

/* .alert-sort {
  width: 100px;
} */

/* .ant-popover-arrow {
  display: none;
}

 .ant-popover-inner-content {
  padding: 12px 0;
}


 .ant-popover-inner-content>div>p {
  margin: 0;
  height: 40px;
  padding: 0 16px;
  padding-top: 9px;
}

.ant-popover-inner-content>div>p:hover {
  background-color: rgba(0, 0, 0, 0.1);
  height: 40px;
} */



.date-signature-section {
  display: flex;
  /* height: inherit; */
  justify-content: space-between;
  /* height: calc(100% - 3em); */
  /* margin-top: 1.2em; */
  font-size: 15px !important;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
}

.date-signature-section .date,
.date-signature-section .signature {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 125px;
  height: 60px;
}

.date-signature-section .signature {
  /* width:200px !important; */
}


.date-signature-section .signature {
  margin-left: 15px !important;
}


.date-signature-text {
  position: absolute;
  left: 50px;
}

.date-signature-text div .date {
  position: absolute;
  right: 105px
}

.date-signature-text div .signature {
  position: absolute;
  right: -80px;
}

.enrollment-form .send-app-invite {
  font-size: 12px;
  /* font-family: 'AvenirLTStdMedium'; */
  /* font-style: italic; */
  color: #4FACFE;
  text-decoration: underline;
  font-weight: 800;
  cursor: pointer;
}

.edit-profile-pin-icon {
  background-color: transparent !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.d-flex .colorIndicators~div {
  font-size: 14px !important;
}

.d-flex .colorIndicators~div:hover {
  color: #1890ff;
}

.red {
  background-color: red !important;
}

.excel-icon {
  border: 0 !important;
  background-color: transparent;
  ;
}

.survey-section {}

.survey-section .row {
  padding: 25px 25px 15px 15px;
}

.survey-section .survey-block {
  width: 100%;
  height: 150px;
  background-color: #F7F8FA;
  box-shadow: 0px 0px 4px rgba(61, 61, 69, 0.16);
  border-radius: 2px;
  margin-bottom: 24px;
  border: 2px solid #F7F8FA;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.survey-section .survey-block br {
  border: 1px dashed #BCBCBC;
}

.survey-section .survey-block .survey-name {
  padding: 5px;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.117857px;
  color: #000000;
}

.actions-button>span.extraAction:hover {
  background: #fff !important;
}







.excel-download {
  position: relative;
  /* margin-top: 15px; */
}

.excel-download .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -35px;
  /* left: -200%; */
  transform: translateX(-50px);
}


.excel-download:hover .tooltiptext {
  visibility: visible;

}

.report-download-icon {
  width: 200px !important;
  top: 320px !important;
}


.ant-popover.report-download-icon .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  margin: 0 !important;
  width: 220px !important;
  padding: 15px !important;
}

.report-text {
  color: green;
}


/* alert box */



.sort-by-list>p,
.report-download {
  margin: 0px 10px;
  height: 40px;
  padding: 0 10px;
  padding-top: 9px;
  width: 250px !important;
}
.ant-popover.alert-box-style {
  z-index : 100!important;
}

.ant-popover.alert-box-style .ant-popover-content {
  width: fit-content !important;
}

.ant-popover.alert-box-style .ant-popover-content .ant-popover-arrow {
  display: none;
}

.ant-popover.alert-box-style .ant-popover-content .ant-popover-inner {
  width: fit-content !important;
  margin: 0 !important;
}

.ant-popover.alert-box-style .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  margin: 0 !important;
  width: fit-content !important;
  padding: 12px 0 !important;
}

.sort-by-list>p:hover {
  background-color: rgba(0, 0, 0, 0.1);
  height: 40px;
  width: 100%;
}


/* search bar in dashboard */


input.form-control.search-placeholder.dashboard-search {
  border-radius: 25px !important;
  box-shadow: 0px 3px 4px 0 rgb(0 0 0 / 5%) !important;
  height: 40px !important;
  /* >>>>>>> Stashed changes */
}

.bold {
  font-weight: 700;
}


.students-profile .students-action-button>.btn:hover {
  color: white !important;
  background-color: #46b4fe !important;
  border-color: #46b4fe !important;
}

.students-profile .students-action-button>.btn:hover .pin-icon {
  color: white !important;
  background-color: white !important;
  border-color: white !important;
}


.custom-phone-input input {
  border: none;
}

.custom-phone-input .react-tel-input .form-control {
  padding-left: 45px !important;
  width: 100% !important;
}

.react-tel-input .flag-dropdown {
  border: none !important;
  background-color: unset !important;
}

.back-link {
  font-size: 18px !important;
}

.checkBoxNumber label.placeholder-label {
  left: 15px;
}

/* .select-sm-filter .css-1pcexqc-container{
  width: 230px !important;
} */


.row.dashboard-analytics-centers .basic-single {
  width: 200px;
}


/* app subscribers -> filter drop-down */
.dynamic-grid .call-schedule .placeholder-label {
  top: -15px !important;
}

.content-area .app-subscriber-page .placeholder-label {
  left: 0 !important;
  top: -15px !important;

}


/* .dynamic-grid .search-box .has-search .search-icon {
  top: -4px !important;
} */

/* .dynamic-grid .search-box .has-search #searchBar {
      height: 40px !important;
} */

.forms .ant-tabs-nav>div .ant-tabs-tab {
  justify-content: center;
}

.forms .ant-tabs-nav .ant-tabs-tab-active {
  box-shadow: unset
}

.select__value-container {
  padding: 2px 0 !important;
}

.service-filter {
  margin-bottom: 0 !important;
}


.service-filter .basic-single .select__control {
  height: 46px !important;
}


.print-tooltip::after {
  content: 'Print';
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 15px;
  border-radius: 5px;
  font-size: 12px;
  top: -29px;
  left: 9px;
  position: absolute;
  display: none;
}

.print-tooltip::before {
  content: "";
  color: red;
  position: absolute;
  bottom: 94%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  display: none;
}


.print-tooltip:hover::after,
.print-tooltip:hover::before {
  display: inline;
  animation: iconmove 0.4s;
}

.pin-tooltip::after {
  content: 'Pin';
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 15px;
  border-radius: 5px;
  font-size: 12px;
  top: -29px;
  left: 9px;
  position: absolute;
  display: none;
}

.pin-tooltip::before {
  content: "";
  position: absolute;
  bottom: 94%;
  left: 45%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  display: none;
}


.pin-tooltip:hover::after,
.pin-tooltip:hover::before {
  display: inline;
  animation: iconmove 0.4s;
}

@keyframes iconmove {
  from {
    transform: translateY(5px)
  }

  to {
    transform: translateY(0)
  }

}

.no-announcements {
  color: rgba(0, 0, 0, 0.5);
}

.dynamic-grid .dynamic-grid-component .text-left .basic-single .select__placeholder,
.dynamic-grid .dynamic-grid-component .text-left .basic-single .select__single-value {
  margin-left: 10px !important;

}

.dynamic-grid .dynamic-grid-component .text-left .basic-single .select__input-container {
  margin-left: 10px !important;
}

.dynamic-grid .dynamic-grid-component .col-max-width {
  width: max-content !important;
  max-width: 180px !important;
  display: block;
}

.dashboard-center .dynamic-center .select__value-container {
  margin-left: 5px !important;
}

.custom-modal {
  z-index: 10000 !important;
}

.rcm-patient-table .content-area .excel-download {
  margin-top: 0 !important;
}

.enrollment-page .select__control,
.rcm-panel .select__control,
.breathalyzer-tab .select__control,
.upload-document .select__control,
.individualNotesEdit .select__control,
.center-custom-fields-drawer .select__control {
  background-color: transparent !important;
}

/* .medicalAnalysis-page .form-group .rcm-document-search{
  min-height: 44px !important;
} */

.answer-options .ant-checkbox-disabled+span {
  color: rgba(0, 0, 0, 0.6) !important;
  ;
}

.save-button {
  font-weight: bold !important;
  border-radius: 26px !important;
  height: 37px;
  border: 0 !important;
  color: #fff !important;
  padding: 0 43px;
  line-height: 24px !important;
  background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%) !important;
  background-size: 300% 100% !important;
  transition: all 300ms ease-in-out !important;
  font-size: 17px !important;
}

.save-button[disabled] {
  background: #cccccc !important;
}

.clear-btn {
  margin-left: 10px;
  margin-right: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  background: transparent;
  border: 2px solid rgb(255, 120, 117);
  color: rgb(191, 191, 191);
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.clear-btn span {
  margin-bottom: 0px;
  margin-left: 7px;
  color: rgb(255, 120, 117);
}

.rcm-tab .form-group .ant-checkbox-wrapper .ant-checkbox+span {
  margin-top: 4px !important;
}

.mic-notes {
  padding: 3px 2px 0px 3px;
  border-radius: 5px;
  border: 2px solid rgb(70, 180, 254);
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

  /* css written mainly for small device like ipad air */

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .dashboard-center>span {
    border-left: unset !important;
  }

  .new-header-css {
    display: flex;
    flex-direction: column;
  }

  .navbar-nav {
    margin-right: unset !important;
    margin-top: 8px;
  }

  .search-box {
    margin: 35px auto;
  }

  .dashboard-col-left {
    margin: 50px auto;
  }

  .dashboard-tabcol .card {
    height: 400px !important;
  }

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    margin-top: 5px !important;
  }

  .cw-menu .ant-menu-item-only-child {
    margin: 0px !important;
  }

}

.rcm-panel .permissions-block .ant-checkbox-wrapper {
  align-items: center !important;
}

.existing .ant-radio-inner {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
}

.upload .ant-radio-inner {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
}

/* .roles-permissions-modal .permissions-block .ant-checkbox {
  line-height: unset;
  top: unset;
  display: flex;
  align-items: center;

} */

.searched-item {
  cursor: pointer;
  background-color: rgb(236, 247, 253);
  /* color: rgb(79, 172, 254); */
  padding: 5px;
  margin: 10px;
}

.allergies-section {
  background-color: rgba(248, 248, 248, 1);
  padding: 10px;
  height: 350px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.treatment-plan-detail p {
  margin-bottom: 0.5em;
}

/* .vertical-tabs .ant-tabs-content {
  display: flex !important;
} */

.e-rescription {
  height: 35px;
  line-height: 12px !important;
}

.text-tooltip {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  overflow: hidden;
}

/* .student-progress .ant-tabs-tabpane.ant-tabs-tabpane-active {
  overflow-y: unset;
  overflow-x: unset;
} */

.student-progress .select__menu {
  bottom: unset !important;
}

/* .labtest-title{
  color: #979797;
  font-size: 23px;
} */

#pdf-dowload {
  display: none !important;
}

a #pdf-dowload {
  display: none !important;
}

/* .custom-tooltip .ant-tooltip-inner{
  background-color: #3D3D3F !important;
  padding: 8px 10px;
}

.lab-info{
  font-size: 22px;
  font-weight: 700;
  color:#979797;
}

.lab-modal-title{
  background-color: rgb(74, 176, 254);
    color: white;
    font-weight: 700;
    font-size: 18px;
    padding: 11px;
    padding-left: 21px;
} */

.checkBoxNumber .custom-phone-input {
  width: 100% !important;
}

.checkBoxNumber .custom-phone-input .placeholder-label {
  left: 0 !important;
}

/* Risk Manage */
#LowRisk {
  color: rgba(18, 196, 117, 1);
  display: flex;
  align-items: center;
}

#LowRisk .dot {
  width: 25px;
  height: 10px;
  background: #12C475;
  border-radius: 8px;
  margin-right: 5px;
}

#LowRisk .text {
  font-size: 16px;
  line-height: 22px;
  margin-right: 5px;
  color: #12C475;
  font-weight: bold;
}

#NoRisk {
  color: rgba(18, 196, 117, 1);
  display: flex;
  align-items: center;
}

#NoRisk .text {
  font-size: 16px;
  line-height: 22px;
  margin-right: 5px;
  color: #7F8188;
  font-weight: 400;
}

#MediumRisk {
  color: #FFAE49;
  display: flex;
  align-items: center;
}

#MediumRisk .dot {
  width: 25px;
  height: 10px;
  background: #FFD979;
  border-radius: 8px;
  margin-right: 5px;
}

#MediumRisk .text {
  font-size: 16px;
  line-height: 22px;
  margin-right: 5px;
  color: #FFAE49;
  font-weight: bold;
}

#HighRisk {
  color: rgba(255, 59, 59, 1);
  display: flex;
  align-items: center;
}

#HighRisk .dot {
  width: 25px;
  height: 10px;
  background: #FF3B3B;
  border-radius: 8px;
  margin-right: 5px;
}

#HighRisk .text {
  font-size: 16px;
  line-height: 22px;
  margin-right: 5px;
  color: #FF3B3B;
  font-weight: bold;
}

.client-screening .type-border {
  background: #F8F8F8;
  border: 1px solid #EEF1F4;
  margin-bottom: 15px;
  width: 100%;
  flex: 100%;
  max-width: 100%;
}

.client-screening .type-border input,
.client-screening .type-border textarea {
  background: #F8F8F8 !important;
}

.toggle-radio .ant-radio-inner {
  border-radius: 50%;
  margin-bottom: 5px;
}

.toggle-radio .ant-radio-checked .ant-radio-inner:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 26px !important;
  height: 26px !important;
  margin-top: -13px !important;
  margin-left: -13px !important;
  background-color: #1890ff;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
}

.toggle-radio .ant-radio-wrapper,
.toggle-radio .ant-checkbox-wrapper {
  color: #000000;
}

#NoRisk .dot-gray,
#MediumRisk .dot-gray,
#LowRisk .dot-gray {
  width: 25px;
  height: 10px;
  background: #DFDFDF;
  border-radius: 8px;
  margin-right: 5px;
}

.pre-intake-form .survery-question-box h5 {
  margin-bottom: 15px;
}

.survey-response h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: #000000;
}

.survey-response .text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #7F8188;
}

.survey-response .value {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #182838;
}

/* Augment with AI */
.gpt-question {
  margin: 10px 0px;
  padding: 10px 0px;
  display: flex;
  justify-content: end;

}

.gpt-question .ai-question {
  display: flex;
  justify-content: end;
  flex-direction: column;
  margin-right: 10px;
}

.gpt-question .updated-by {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: right;
  color: #000000;
  text-transform: capitalize;
}

.gpt-question .updated-by-profile {}

.gpt-question .updated-by-profile img {
  width: 40px;
  border-radius: 50%;
  height: 38px;
  margin-right: 10px;
  object-fit: cover;
}

.gpt-question .question {
  display: inline-block;
  text-align: right;
}

.gpt-question .question .text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: right;
  color: #182838;
  background: #E5F0FF;
  border-radius: 4px;
  padding: 5px 10px;
}

.gpt-question .date {
  font-weight: 400;
  font-size: 14px;
  text-align: right;

  color: #7F8188;
}

.ai-summary-section {
  height: 780px;
  overflow-y: scroll;
}

.ai-question-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.ai-question-button button {
  margin: 5px;
}

.gpt-answers {
  display: flex;
  margin-bottom: 10px;
}

.gpt-answers .ai-icon {
  min-width: 40px;
  margin-right: 10px;
}

.gpt-answers .ai-block {
  background-color: #F7F8FA;
  border-radius: 4px;
  padding: 20px;
  flex-grow: 1;
  /* max-width: 677px; */
}

.gpt-answers .ai-question {
  font-weight: 700;
  font-size: 16px;
  color: #182838;
}

.gpt-answers .ai-answer {
  font-weight: 400;
  font-size: 16px;
  color: #182838;
}

.ai-course .ai-action,
.gpt-answers .ai-action {
  display: flex;
  justify-content: end;
  align-items: center;
}

.ai-course .ai-action .btn,
.gpt-answers .ai-action .btn {
  width: 140px;
  height: 35px;
  padding: 5px;
  margin-right: 10px;
  line-height: 10px;
}

.survey-list-table .ant-drawer-body {
  background: #fff;
}

.gpt-answers .ai-block .ai-agreed {
  font-weight: 600;
  font-size: 16px;
  color: #00DC31;
  margin: 0px 5px;
}

.gpt-answers .ai-block .ai-disagreed {
  font-weight: 600;
  font-size: 16px;
  color: #FF2C2C;
  margin: 0px 5px;
}

.gpt-answers .ai-block .ai-updated-info {
  font-weight: 400;
  font-size: 12px;
  color: #182838;
  display: block;
}

.dynamic-grid-modal .form-group .check-box-group {
  display: flex;
  flex-wrap: wrap;
}

.students-page .title {
  font-weight: 700;
  font-size: 26px;
  line-height: 35px;
  color: #000000;
}

.students-page .students-name {
  font-weight: 700;
  font-size: 20px;
  color: #182838;
}

.students-page .students-phone {
  font-weight: 400;
  font-size: 16px;
  color: #009EF7;
}

.students-risk-chart-card,
.students-augment-card {
  height: 300px;
}

.students-augment-card {
  background-image: url(/images/tri.svg) !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
}

/* .students-augment-card::before {
  content: "";
  background-image: url(/images/tri.svg)!important;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 40%;
  opacity: 0.85;
} */
.students-augment-card .block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.students-augment-card .block-action {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.students-augment-card .ai-summary {
  width: 100%;
  height: 100%;
}

.students-augment-card .ai-summary-action {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.update-history-card .title,
.client-screening-card .title,
.students-augment-card .ai-summary .title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #182838;
}

.augmented-client-summary .text,
.students-augment-card .ai-summary .ai-summary-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #182838;
  margin-top: 10px;
  height: 170px;
  overflow-y: auto;
}

.students-augment-card .ai-summary .updated-by {
  display: flex;
  align-items: center;
}

.students-augment-card .ai-summary .updated-by img {
  width: 40px;
  border-radius: 50%;
  height: 38px;
  margin-right: 10px;
  object-fit: cover;
}

.students-augment-card .ai-summary .updated-by .name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.students-augment-card .ai-summary .updated-by .date {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #959595;
}

.students-risk-factors-card {
  height: 300px;
}

.students-client-screening {}

.students-update-card {}

.ai-btn {
  background: linear-gradient(270deg, #EE9E68 -14.91%, #E7677E 26.71%, #AD5D9E 100%);
  font-weight: bold;
  border-radius: 26px;
  border: 0;
  color: #fff;
  padding: 10px 20px;
  line-height: 24px;
  /* background-size: 300% 100%; */
  transition: all 300ms ease-in-out;
}

.ai-btn:hover,
.ai-btn:focus {
  border: 0;
  color: #fff;
  background: linear-gradient(270deg, #EE9E68 -14.91%, #E7677E 26.71%, #AD5D9E 100%);
}


.ai-btn-outline {
  color: #BF6095;
  border: solid 1px #BF6095;
  display: flex;
  align-items: center;
  background-image: none;
  cursor: pointer;
}

/* .ai-btn-outline:hover {
  background: linear-gradient(270deg, #EE9E68 -14.91%, #E7677E 26.71%, #AD5D9E 100%);
  border-radius: 26px;
  color: #fff;
} */
.ai-survey-answer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #EEF1F4;
  margin-bottom: 10px;
  padding: 10px 0px;

}

.ai-survey-answer .question {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 3px;
  color: #7F8188
}

.ai-survey-answer .answer {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;


  color: #182838;
}

.ai-summary-multiple {}

.ai-summary-multiple .survey-banner-section {
  background: #F5F8FA;
  border-radius: 4px;
  padding: 5px 20px;
}

.ai-summary-multiple .survey-banner-section .title {
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #182838;
}

.ai-summary-multiple .survey-banner-section .survey-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 0px;
}

.ai-summary-multiple .survey-banner-section .survey-buttons .survey-ai-btn-outline {
  border: 1px solid #7F8188;
  border-radius: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background-image: none;
  font-weight: bold;
  color: #000000;
  padding: 5px 20px;
  line-height: 24px;
}

.survey-ai-btn {
  background: rgba(181, 95, 154, 1);
  font-weight: bold;
  border-radius: 26px;
  margin-right: 10px;
  border: 0;
  color: #fff;
  padding: 5px 20px;
  line-height: 24px;
}

.tags-section {
  display: inline;
}

.tags-section .tag {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  background: #C7C7C7;
  border-radius: 2px;
  margin: 2px 5px;
  white-space: nowrap;
  display: inline-block;
  padding: 2px 5px;
}

.survey-ai-btn-active {
  /* background: rgba(0, 158, 247, 1); */
  font-weight: bold;
  border-radius: 26px;
  margin-right: 10px;
  background: linear-gradient(0deg, #009EF7, #009EF7), #E97579;
  border: 1.5px solid #000000;
  color: #fff;
  padding: 5px 20px;
  line-height: 24px;
}

.survey-list-item {
  background: #F7F8FA;
  border-radius: 2px;
  padding: 10px 15px;
  margin: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.student-list-menu {
  margin-top: -14px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.ai-summary-multiple .drawer-summary {
  height: 650px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.update-history-card {}

.update-history-card .box .date {
  background: #E3F4FF;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #000000;
}

.update-history-card .box .time {
  font-weight: 400;
  font-size: 19px;
  text-align: center;
  color: #979797;
}

.update-history-card .survey-event-details {
  background: #F8F8F8;
  border-radius: 8px;
}

.update-history-card .survey-event-details .note-event {
  padding: 15px;
}

.note-history .note-section .added-by,
.update-history-card .note-event .added-by {
  display: flex;
  align-items: center;
}

.update-history-card .note-event .added-text {
  font-weight: 400;
  font-size: 14px;
  color: #959595;
  margin-right: 10px;
}

.note-history .note-section .added-by img,
.update-history-card .note-event .added-by img {
  width: 35px;
  border-radius: 50%;
  height: 35px;
  margin-right: 10px;
  object-fit: cover;
}

.note-history .note-section .name-box,
.update-history-card .note-event .name-box {
  display: flex;
  flex-direction: column;
}

.note-history .note-section .name,
.update-history-card .name-box .name {
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.note-history .name-box .role,
.update-history-card .name-box .role {
  font-weight: 400;
  font-size: 12px;
  color: #959595
}

.update-history-card .note-event .event-note-box {
  display: flex;
  flex-direction: column;
}

.update-history-card .note-event .note-title {
  font-weight: 400;
  font-size: 14px;
  color: #959595;
}

.update-history-card .note-event .text {
  font-weight: 400;
  font-size: 16px;
  color: #182838;
}

.screening-questions-answered-event {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editing {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #7F8188;
}

.user-basic-detail {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.user-basic-profile .user-avatar {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 50px;
}

.user-basic-detail .basic-detail .name {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #182838;
}

.user-basic-detail .basic-detail .phone {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #182838;
  padding: 2px;
  background-color: rgba(0, 0, 0, .05);
}

.user-basic-detail .user-notification {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.user-basic-detail .user-notification .settings {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7f8188;
}

.profile-section-details>div,
.profile-section-Stats>div {
  min-height: 62px;
}

.profile-section-label {
  font-size: 14px;
  color: #7f8188;
}

.subscriber-tab-section .ant-tabs-nav-wrap {
  background: white;
  border-radius: 12px;
}

.subscriber-tab-section .ant-tabs-tab {
  flex: 1 1;
  margin: 0 !important;
  /* padding: 8px 10px !important; */
  text-align: center;
  justify-content: center;
}

.subscriber-tab-section .ant-tabs-nav-wrap {
  display: inline-block !important;
}

.subscriber-tab-section .ant-tabs-tab-btn {
  font-size: 20px;
}

.subscriber-tab-section .ant-collapse {
  background-color: #fff;
}

.subscriber-tab-section .album-list-modal {
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: 50%;
  border-radius: 5px;
}

.subscriber-tab-section .posts-list-item span {
  color: #9e9e9e;
  padding: 7px 0;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 11px;
  display: inline-block;
}

.subscriber-tab-section .posts-list-item {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  padding: 30px;
}

.subscriber-tab-section .post-text {
  font-weight: 700;
  font-size: 16px;
  font-family: AvenirLTStdMedium;
}

.subscriber-tab-section .posts-list-item .modal-container {
  margin-top: 20px;
}

.subscriber-tab-section .posts-list-item img {
  width: 25%;
  margin: 1px;
}

.subscriber-tab-section .friend-list {
  display: flex;
  align-items: center;
  padding: 18.25px 10px;
}

.subscriber-tab-section .friend-list .f-friend-name-box {
  display: flex;
  justify-content: flex-start;
  flex-basis: 100%;
  align-items: center;
  padding: 0 15px;
}

.subscriber-tab-section .friend-list .f-friend-name {
  font-size: 18px;
  font-weight: 800;
  color: #000;
  text-transform: capitalize;
}

.subscriber-tab-section .friend-list .f-friend-badge {
  font-size: 12px;
  font-weight: 600;
  padding: 3px 15px;
  margin: 0 1em;
}

.subscriber-tab-section .chat-container .c-box {
  cursor: pointer;
  display: flex;
  padding: 18.25px 10px;
  background-color: #F8F8F8;
  /* margin-bottom: 10px; */
  margin: 10px 20px;
}

.subscriber-tab-section .chat-container .c-box .c-containt {
  justify-content: flex-start;
  padding: 0 15px;
  flex: 1 1;
  width: 75%;
}

.subscriber-tab-section .chat-container .c-box .c-last-msg-time {
  font-weight: 600;
  font-size: 14px;
  color: #959595;
}

.subscriber-tab-section .chat-container .c-box .c-friend-badge {
  font-size: 12px;
  font-weight: 600;
  padding: 3px 15px;
  margin: 0 1em;
}

.subscriber-tab-section .chat-container .c-box .c-last-msg {
  margin-top: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: justify;
  font-weight: 600;
  font-size: 1.1em;
}

.subscriber-tab-section .chat-container .c-box .c-friend-name {
  font-size: 18px;
  font-weight: 800;
  color: rgb(0, 0, 0);
  text-transform: capitalize;
}

.subscriber-tab-section .friends-avatar {
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: center center;
  width: 50px;
  height: 50px;
  box-shadow: rgb(242, 242, 242) 0px 11px 6px;
  max-width: unset;
  flex-shrink: 0;
}

.subscriber-tab-section .posts-list-item .modal-container input {
  border-radius: 5px;
  border: 2px solid #9f9f9f;
  background-image: none;
  color: #474747;
}

/* TO Do */
.ToDo-box ul.alert-list {
  list-style-type: none;
  padding-left: 5px !important;
  background-color: #f8f8f8;
  margin-bottom: 0.5em !important;
}

.ToDo-box ul.alert-list:hover {
  background-color: #ECF7FF;
}

.ToDo-box ul.alert-list .title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #182838;
}

.ToDo-box ul.alert-list .date {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #7F8188;
  display: block;
}

.ToDo-box ul.alert-list li {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */

  color: #828282;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0px 10px 0px;
  cursor: pointer;
}

.ToDo-box ul.alert-list li a:hover {
  text-decoration: underline;
  background-color: #ECF7FF;

}

.ToDo-box .alert-border-left {
  border-left: 5px solid #F9BE79;
  padding-left: 10px;
}

.note-history .note-section {
  background: #F8F8F8;
  border-radius: 8px;
  padding: 15px 10px;
}

.note-section .updated-by {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.note-section .updated-by .date-time {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.note-history .note-section .added-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #828282;
}

.note-section .note {
  font-weight: 400;
  font-size: 16px;
  color: #182838;
}

.update-history-card .add-pre-intake-note .add-note {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.update-history-card .add-pre-intake-note .add-note-section {}

.update-history-card .add-pre-intake-note .add-note-section .action {
  display: flex;
  justify-content: end;
  align-items: center;
}

.episodes-dropdown .select__control {
  /* border: */
  border-radius: 7px !important;
  height: 47px !important;
  background-color: white !important;
  border: 2px solid #46b4fe !important;
}

.episodes-dropdown .select__control .select__single-value,
.episodes-dropdown .select__control .select__input-container {
  margin-left: 7px !important;
}

.episodes-dropdown .select__control .select__indicators .select__indicator {
  color: #46b4fe !important;
}

.students-performance-col .container-fluid {
  padding-left: 5px;
  padding-right: 5px;
}

.students-performance-col .container-fluid .col-12:first-child {
  padding-top: 0px !important;
}

.intake-survey-list .survey-item {
  background: #F8F8F8;
  border-radius: 9px;
  padding: 15px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.intake-survey-list .survey-item .survey-info {
  display: flex;
  flex-direction: column;
}

.intake-survey-list .survey-item .survey-info .name {
  font-weight: 400;
  font-size: 17px;
  color: #000000;
}

.intake-survey-list .survey-item .survey-info .date {
  margin-right: 25px;
}

.intake-survey-list .survey-item .survey-info .date .text {
  font-weight: 400;
  font-size: 14px;
  color: #828282;
}

.intake-survey-list .survey-item .survey-info .date .value {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.intake-survey-list .survey-item .survey-action {}

.ant-popover {
  z-index: 9 !important;
}

.type-border .score-text {
  padding: 10px;
  border: 1px solid #dee2e6 !important;
  border-radius: 10px;
  background-color: rgb(248, 248, 248);
  text-align: center;
  /* max-height: 50px; */
}

.pagination-custom {
  display: flex;
  justify-content: flex-end;
}

.pagination-custom .action {
  background-color: #4AB0FE;
  border-radius: 8px;
  border: none;
  width: 40px;
  height: 32px;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-custom .action:disabled {
  background-color: #f0f0f0;
  border-radius: 8px;
  border: none;
  width: 40px;
  margin: 0px 5px;
  height: 32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.pagination-custom .size-changer {
  width: 110px;
  border-radius: 8px;
  border: none;
  background-color: #f0f0f0;
  padding: 5px;
}

.student-list-menu .ant-menu-horizontal {
  border: none !important;
}

/* Trial Not started Module design for licensing */

.trialNotStarted {
  border-radius: 12px
}

.trialNotStarted .section {
  position: absolute;
  text-align: center;
  width: 60%;
  top: 10%;
  left: 20%
}

.trialNotStarted_SubscriberProfile .section,
.trialNotStarted_SupportGroup .section {
  position: absolute;
  text-align: center;
  width: 80%;
  top: 10%;
  left: 10%
}

.trialNotStarted .heading,
.trialNotStarted_SubscriberProfile .heading,
.trialNotStarted_SupportGroup .heading {
  font-weight: 700;
  font-size: 32px
}

.trialNotStarted .tagLine {
  font-weight: 600;
  font-size: 16px;
  width: 70%;
  margin: 10px auto;
}

.trialNotStarted_SubscriberProfile .tagLine,
.trialNotStarted_SupportGroup .tagLine {
  font-weight: 600;
  font-size: 16px;
  width: 90%;
  margin: 10px auto;
}

.trialNotStarted .labels,
.trialNotStarted_SupportGroup .labels {
  background: rgb(238, 241, 244);
  border-radius: 6px;
  padding: 10px;
  margin: 15px 10px;
  display: inline-block;
  font-weight: 600;
}


/* Trial Not started internal Module design for licensing */

.trialNotStarted {
  border-radius: 12px
}

.trialNotStarted .section {
  position: absolute;
  text-align: center;
  width: 60%;
  top: 10%;
  left: 20%
}

.trialNotStarted .heading {
  font-weight: 700;
  font-size: 32px
}

.trialNotStarted .tagLine {
  font-weight: 600;
  font-size: 16px;
  width: 70%;
  margin: 10px auto;
}

.trialNotStarted .labels {
  background: rgb(238, 241, 244);
  border-radius: 6px;
  padding: 10px;
  margin: 15px 10px;
  display: inline-block;
  font-weight: 600;
}


/* Trial Ended started Module design for licensing */

.trialEnded {
  border-radius: 12px
}

.trialEnded .section {
  position: absolute;
  text-align: center;
  width: 60%;
  top: 10%;
  left: 20%
}

.trialEnded_SubscriberProfile .section {
  position: absolute;
  text-align: center;
  width: 60%;
  top: 10%;
  left: 20%
}

.trialEnded .heading {
  font-weight: 700;
  font-size: 32px;
  color: red;
}

.trialEnded_SubscriberProfile .heading {
  font-weight: 700;
  font-size: 18px;
  color: red;
}

.trialEnded .tagLine {
  font-weight: 600;
  font-size: 16px;
  width: 70%;
  margin: 10px auto;
}

.trialEnded .labels {
  background: rgb(238, 241, 244);
  border-radius: 6px;
  padding: 10px;
  margin: 15px 10px;
  display: inline-block;
  font-weight: 600;
}

.trialEnded .iconTag {
  height: 30px;
  width: 30px;
  background-color: red;
  position: absolute;
  top: -10px;
  left: 40px;
  color: white;
  font-size: 15px;
  padding: 4px;
  border-radius: 22px;
}



.student-list-menu .select__control {
  background-color: transparent !important;
  border: none !important;
  border: 1px solid #959595 !important;
  border-radius: 10px !important;
}

.student-list-menu .select__control .select__indicator {
  color: #959595 !important;
}

.dictation-space .mic-notes {
  max-height: 29px !important;
}

.dictation-space .mic-container {
  top: 0 !important;
}

.ant-popover-placement-top .ant-popover-arrow {
  left: 20% !important;
  transform: translateY(100%) translateX(-50%);
}

.node-label {
  font-size: 12px;
  padding: 1px 4px;
  border-radius: 4px;
  background-color: red;
  user-select: none;
}

.staff-info-msg {
  color: red;
  padding: 5px;
  font-size: 14px;
  /* font-style: italic; */
  /* background-color: #d9d9d9; */
  border-radius: 4px;
  font-weight: 600;
}

.clearButton {
  margin-left: 10px;
  margin-right: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  background: transparent;
  border: 2px solid rgb(255, 120, 117);
  color: rgb(191, 191, 191);
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.select-all {
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  top: 5px;
}

.client-chart {
  position: relative;
}

.client-chart .ant-checkbox-group {
  flex-direction: column !important;
  align-items: flex-start !important;
}

.client-chart .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-group-item {
  padding: 15px !important;
  background-color: #F7F8FA;
  width: 100%;
  align-items: flex-end !important;
  margin: 10px !important;
}

.print-client-chart .ant-checkbox-group {
  width: 100% !important;
}

/* Prospects */

.rcm-panel .alpha-numeric-oneline .alphanumeric-type {
  width: 300px !important;
}

.rcm-panel .alpha-numeric-multi .alphanumeric-type {
  width: 150px !important;
}

.chat-message-container::-webkit-scrollbar {
  width: 8px;
}

/* Track */
/* .chatbot::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
} */

/* Handle */
.chat-message-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

/* Handle on hover */
.chat-message-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

.chatbot .chat-input {
  width: 100%;
  border: none;
  background-color: #f2f2f2;
  padding: 15px;
  border-radius: 30px;
  /* box-shadow: 2px 2px 3px rgb(0 0 0 / 20%); */
}

.chatbot .chat-submit {
  /* position: absolute;
    right: 20px;
    top: 11px; */
  height: 32px;
}

.chatbot .chat-messages {
  display: flex;
  align-items: start;
  white-space: break-spaces;
  word-wrap: break-word;
  /* margin: 15px 0; */
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1)
}

.vertical-tabs>.ant-tabs-content>.chatbot {
  /* background: #fff !important; */
  min-height: unset !important;
  /* box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06) !important; */
}

.chatbot .chat-userimg {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  object-fit: cover;
}

.chatbot .un-mute {
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #979797;
  filter: drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.0467384));
  border-radius: 25px;
  padding: 8px 14px;
  cursor: pointer;
}

.chatbot .mute {
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  background: #BDBDBD;
  border: 1px solid #979797;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.0467384);
  border-radius: 25px;
  padding: 8px 14px;
  cursor: pointer;

}

.chat-date {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px;
}

.ant-drawer.print-drawer {
  z-index: 980 !important
}

.lab-info-value {
  font-weight: 800;
}

.prospect-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prospect-status .status-1 {
  background-color: #EAF5FC;
  border-radius: 8px;
  padding: 10px;
  flex: 1;
  margin: 0px 10px;
}

.prospect-status .status-1 .text {
  color: #0070AE;
  font-weight: 700;
}

.prospect-status .status-3 {
  background-color: #FFEFDB;
  border-radius: 8px;
  padding: 10px;
  flex: 1;
  margin: 0px 10px;
}

.prospect-status .status-3 .text {
  color: #A95E00;
  font-weight: 700;
}

.prospect-status .status-3 .value {
  display: flex;
  align-items: center;
}

.prospect-status .status-3 .value img {
  cursor: pointer;
}

.prospect-status .status-2 {
  background-color: #ECFCEF;
  border-radius: 8px;
  padding: 10px;
  flex: 1;
  margin: 0px 10px;
}

.prospect-status .status-2 .text {
  color: #068D24;
  font-weight: 700;
}

.prospect-status .status-4 {
  background-color: #FFEDF0;
  border-radius: 8px;
  padding: 10px;
  flex: 1;
  margin: 0px 10px;
}

.prospect-status .status-4 .text {
  color: #CE0025;
  font-weight: 700;
}

.prospect-status .status-5 {
  background-color: #f7ecfc;
  border-radius: 8px;
  padding: 10px;
  flex: 1;
  margin: 0px 10px;
}

.prospect-status .status-5 .text {
  color: #d50df4;
  font-weight: 700;
}

.prospect-status .status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prospect-status .status .value {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  text-align: right;

  /* Primary Text */

  color: #182838;
}

.prospect-banner .name,
.prospect-banner .phone,
.prospect-banner .qualifiers,
.prospect-banner .flagged,
.prospect-banner .mail {
  display: flex;
  margin-right: 15px;
}

.prospect-banner .name img,
.prospect-banner .phone img,
.prospect-banner .qualifiers img,
.prospect-banner .flagged img,
.screening-status-block .status-qualifier img,
.screening-status-block .status-flagged img,
.prospect-banner .mail img {
  margin-right: 5px;
}

.prospect-banner .name .text {
  font-weight: 600;
  font-size: 18px;
  color: #182838;
}

.prospect-banner .text {
  white-space: nowrap;
  margin-right: 10px;
}

.screening-status {
  display: flex;
  padding: 15px;
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-shadow: 0px 4px 8px rgba(71, 72, 75, 0.06);
  border-radius: 4px;
}

.screening-status-block,
.screening-status .no-status,
.screening-status-block .status-qualifier,
.screening-status-block .status-flagged {
  display: flex;
  align-items: center;
}

.screening-status .no-status .text {
  font-weight: 400;
  font-size: 16px;
  color: #7F8188;
}

.screening-status-block .status-flagged {
  margin-right: 5px;
  background: rgba(255, 44, 44, 0.08);
  border-radius: 4px;
  padding: 5px 10px;
}

.screening-status-block .status-qualifier {
  margin-right: 5px;
  background: rgba(47, 225, 146, 0.08);
  border-radius: 4px;
  padding: 5px 10px;
}

.screening-status-block .status-flagged .text {
  font-weight: 700;
  font-size: 18px;
  color: #FF5D5D;
}

.screening-status-block .status-qualifier .text {
  font-weight: 700;
  font-size: 18px;
  color: #2FE192;
}

.screening-status-block .change-qualified {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.09625px;
  color: #46B4FE;
  cursor: pointer;
}

#Flagged,
#Qualifier {
  display: flex;
  align-items: center;
}

#Flagged img,
#Qualifier img {
  margin-right: 5px;
  margin-bottom: 10px;
}

#Qualifier .text {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.385882px;
  color: #2FE192;
}

#Flagged .text {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.385882px;
  color: #FF5D5D;

}

.question-lib-tab .ant-tabs-nav .ant-tabs-tab {
  padding: 18px 30px 18px 20px;
}

.question-lib-tab .ant-tabs-content {
  box-shadow: unset !important;
}

.survey-answer-view {}

.survey-answer-view .survey {
  border-bottom: 1px solid #EEF1F4;
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
}

.survey-answer-view .question {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #7F8188;
  margin-bottom: 0.8rem;
}

.survey-answer-view .answer {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #182838;
  margin-bottom: 0.8rem;
}

.survey-answer-view .answer .not-answered {
  font-style: oblique;
  font-weight: 500;
}

.survey-answer-view .note {
  background: #F8F8F8;
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
}

.survey-answer-view .note .text-t {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #7F8188;
  display: block;
}

.survey-answer-view .note .text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #182838;
}

.program-match {
  padding: 1rem 1rem 1rem 0rem;
}

.program-match .augmented-client-summary {
  background: rgba(1, 158, 247, 0.05);
  border-radius: 2px;
  padding: 1rem;
}

.program-match .augmented-client-summary .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #182838;
  display: block;
  margin-bottom: 10px;
}

.program-match .augmented-client-summary .text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #182838;
}

.program-match .title,
.program-match .care-options .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #182838;
  display: block;
}

.program-match .care-options .tags {
  display: flex;
  padding: 10px 5px;
  align-items: center;
  flex-wrap: wrap;
}

.program-match .care-options .tags .tag {
  background: #F7F8FA;
  border: 1px solid #7F8188;
  border-radius: 80px;
  margin-right: 1rem;
  color: #7F8188;
  padding: 10px 30px;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 1rem;
}

.program-match .care-options .tags .active-tag {
  background: #F2FAFF;
  border: 2px solid #009EF7;
  color: #009EF7;
  font-weight: 700;
}

.horizontal-slider .max-content .item-btn {
  border: 1px solid rgb(151, 151, 151);
  box-sizing: border-box;
  filter: drop-shadow(rgba(0, 0, 0, 0.047) 0px 9px 12px);
  border-radius: 50px;
  padding: 5px 10px;
  margin: 5px;
  font-size: 12px;
  color: rgb(151, 151, 151);
  cursor: pointer;
}

.horizontal-slider .max-content .item-btn-active {
  border: 1px solid rgb(74, 176, 254);
  box-sizing: border-box;
  filter: drop-shadow(rgba(0, 0, 0, 0.047) 0px 9px 12px);
  border-radius: 50px;
  padding: 5px 10px;
  margin: 5px;
  font-size: 12px;
  color: white;
  cursor: pointer;
  background: rgb(74, 176, 254);
}

.horizontal-slider .previous {
  background: rgb(70, 180, 254);
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
}

.horizontal-slider .next {
  transform: rotate(180deg);
  background: rgb(70, 180, 254);
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
}

.assigned-program {
  background: #F8F8F8;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  margin-top: 1rem;
}

.assigned-program .program {
  display: flex;
  flex-direction: column;
}

.assigned-program .text {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #828282;
}

.assigned-program .value {
  font-weight: 600;
  font-size: 16px;
  color: #182838;
}

.assigned-program .value-1 {
  font-weight: 800;
  font-size: 16px;
  color: #000000;
}

.assigned-program .program-detail {
  display: flex;
  margin-top: 1rem;
}

.assigned-program .program-detail .items {
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
}

.assigned-program .assigned-to-queue .assigned-lable {
  background: rgba(70, 180, 254, 0.13);
  border-radius: 4px;
  color: #46B4FE;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 10px;
}

.assign-queue-action {
  font-weight: 700;
  font-size: 14px;
  color: #4AB0FE;
  white-space: nowrap;
  cursor: pointer;
}

.prospect-time-travel .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.prospect-associations {}

.association-model .association-model {}

.association-model .new-association {
  background: rgb(255, 255, 255);
  width: 250px;
  display: flex !important;
  align-items: center;
  margin: 10px 0px;
}

.association-model .new-association .user {
  display: flex;
  flex-direction: column;

}

.association-model .new-association .user .display-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.association-model .new-association .user .selected-display-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.association-model .app-status {
  background: #F2F8FF;
  padding: 20px 10px
}

.association-model .app-status .text {
  color: #959595;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 0px 5px;
}

.survery-question-box.client-screening .ant-switch-handle {
  top: 1px !important;
}

/* .survey-pagination-button {
  position: absolute;
  right: 40px;
  bottom: 40px;
} */
.question-config-action {
  padding-top: 15px;
  min-width: 250px;
  display: flex;
  justify-content: end;
}

.numbers-row {
  margin: 0px 5px;
}

.survey-pagination-button .btn {
  min-width: 114px;
  padding: 5px 20px;
  font-weight: 500;
  font-family: 'AvenirLTStdMedium';
}

.survey-pagination-button .btn-outline-primary {
  margin-right: 5px;
}

.prospect-associations {}

.prospect-associations .ampopup-content {
  border-radius: 10px;
  min-width: 330px
}

.prospect-associations .ampopup-header {
  background-color: #0079FD !important;
  padding: 0px !important;
}

.prospect-associations .ampopup-header .ampopup-title {
  padding: 0px;
  padding-top: 15px;
}

.prospect-associations .ampopup-inside {
  padding: 0px !important;
  padding-bottom: 15px;
}

.association {
  background-color: #0079FD;
  color: #ffffff;
  padding: 0px 15px;
}

.association .user-info {
  display: flex;
}

.association .user-info .name {
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
  display: block;
}

.association .user-info .relation {
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}

.association .detail {
  padding-bottom: 10px;
  padding-top: 5px;
}

.association .detail span {
  display: flex;
  font-weight: 400;
  font-size: 14px;

}

.association .user-info .association-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.prospect .modal-content .modal-text {
  text-transform: inherit;
}

/* Token Management */
.token-management {}

.token-management .status-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.token-management .status-gray {
  background: #F8F8F8;
  border-radius: 8px;
}

.token-management .status-gray .title {
  color: #182838;
}

.token-management .status-blue {
  background: #E5F2FF;
  border-radius: 8px;
}

.token-management .status-blue .title {
  color: #0079FD;
}

.token-management .status-warn {
  background: #FDEFE7;
  border-radius: 8px;
}

.token-management .status-warn .title {
  color: #84411A;
}

.token-management .status-gray .title,
.token-management .status-blue .title,
.token-management .status-warn .title {
  font-weight: 600;
  font-size: 16px;
}

.token-management .status-gray .count,
.token-management .status-blue .count,
.token-management .status-warn .count {
  display: flex;
  font-weight: 700;
  font-size: 24px;
  color: #182838;
}

.token-statement .title,
.token-analytics .title {
  font-weight: 700;
  font-size: 26px;
  color: #000000;
}

.tokens .inputField::-webkit-outer-spin-button,
.tokens .inputField::-webkit-inner-spin-button,
.numeric-only-field::-webkit-outer-spin-button,
.numeric-only-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.tokens .inputField[type=number],
.numeric-only-field[type=number] {
  -moz-appearance: textfield;
}

.numbers-only::-webkit-outer-spin-button,
.numbers-only::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numbers-only[type=number] {
  -moz-appearance: textfield;
}

.purchase-ai-license {}

.purchase-ai-license .ai-info {
  background: #F8F8F8;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  padding: 10px;
}

.purchase-ai-license .ai-bottom-info {
  font-weight: 400;
  font-size: 16px;
  color: #182838;
}

.purchase-ai-license .text {
  font-weight: 700;
  font-size: 18px;
  color: #182838;
}

.purchase-ai-license .center-token {
  background: #F8F8F8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: 10px 0px;
}

.purchase-ai-license .center-token .action {
  display: flex;
  align-items: center;

}

.purchase-ai-license .center-token .count {
  display: flex;
  align-items: center;

}

.purchase-ai-license .center-token .action .center-name {
  font-weight: 700;
  font-size: 16px;
  color: #182838;

}

.purchase-ai-license .center-token-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #CACACA;
  padding: 15px;
}

.purchase-ai-license .center-token-total .title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #182838;
}

.purchase-ai-license .center-token-total .token-info {
  display: flex;
}

.purchase-ai-license .center-token .count .token-cost .text,
.purchase-ai-license .center-token .count .no-of-token .text,
.purchase-ai-license .center-token-total .no-of-token .text,
.purchase-ai-license .center-token-total .token-cost .text {
  font-weight: 400;
  font-size: 14px;
  color: #959595;
}

.purchase-ai-license .center-token .count .token-cost .value,
.purchase-ai-license .center-token .count .no-of-token .value,
.purchase-ai-license .center-token-total .no-of-token .value,
.purchase-ai-license .center-token-total .token-cost .value {
  font-weight: 700;
  font-size: 20px;
  color: #182838;
}

.labor-cost .license-quantity .quantity-btn {
  margin-bottom: 0px !important;
}

/* ask you universe */
.you-universe-drawer .ant-drawer-header {
  /* background: #4ab0fe !important; */
  /* box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.0620356) !important; */
}

.you-universe-drawer .ant-drawer-close {
  display: flex;
  align-items: center;
}

.custom-drawer .ant-drawer-header-title,
.suggest-cpt-drawer .ant-drawer-header-title,
.contract-drawer .ant-drawer-header-title,
.peer-recovery-drawer .ant-drawer-header-title,
.question-library-drawer .ant-drawer-header-title,
.you-universe-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
}

.question-library-drawer .question-library-drawer-title,
.you-universe-drawer .you-universe-drawer-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.question-library-drawer .question-library-drawer-title .title,
.you-universe-drawer .you-universe-drawer-title .title {
  font-weight: 700;
  font-size: 20px;
  color: #182838;
  padding-left: 10px;
}

.manage-category {}

.manage-category .library-category-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #7F8188;
}

.manage-category .library-category-list {
  list-style: none;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 15px;
  overflow-y: scroll;
  max-height: 400px;
}

.manage-category .library-category-list .category-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.ask-you-universe {}

.you-universe-btn {
  padding: 10px 22px;

  background: linear-gradient(270deg, rgba(238, 158, 104, 0.16) -14.91%, rgba(231, 103, 126, 0.16) 26.71%, rgba(173, 93, 158, 0.16) 100%);
  /* opacity: 0.16; */
  box-shadow: 0px 4px 8px rgba(86, 97, 104, 0.1);
  border-radius: 30px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  /* background: linear-gradient(270deg, #E6D6D2 -14.91%, #DCCFD9 26.71%, #DDCFD9 100%); */
}

.bg-blue .you-universe-btn {
  background: linear-gradient(270deg, #FCECE8 -14.91%, #FBE7EB 26.71%, #F2E5EF 100%);
}

.you-universe-btn img {
  margin-right: 5px;
}

.you-universe-btn span {
  font-weight: 800;
  color: #182838;
}

.ask-you-universe .youu-chat-submit {
  background: #009EF7;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.ask-you-universe .youu-chat-submit img {
  /* vertical-align: middle!important; */
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-left: 9px;
}

.youu-expanded {
  background-color: #ffffff;
  margin-top: 15px;
  margin-bottom: 25px;
}

.youu-expanded .you-universe-title {
  padding-top: 15px;
}

.chat-history {
  /* background: linear-gradient(90deg, #00F2FE 0%, #03EFFE 2.08%, #24D2FE 29.31%, #3CBDFE 55.38%, #4AB0FE 79.56%, #4FACFE 100%); */
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #009EF7;
  padding: 10px 8px;
  cursor: pointer;
}

.chat-history-footer {
  /* position: fixed;
  bottom: 20px; */
}

.chat-history-footer .footer {
  display: flex;
  align-items: center;
  color: #FF2C2C;
  font-weight: 700;
  font-size: 14px;
  border-top: 1px solid #f0f0f0;
  padding: 16px 24px;
  cursor: pointer;
}

.youu-expanded .you-universe-title .text {
  font-weight: 700;
  font-size: 26px;
  color: #182838;
  padding-left: 15px;
}

.ask-you-universe .chat-message-container .user-query {
  display: flex;
  justify-content: end;
  align-items: center;
}

.ask-you-universe .chat-message-container .response {
  min-height: 100px;
  word-wrap: break-word;
  word-break: break-word;
}

.ask-you-universe .chat-message-container .user-query .youu-chat-messages,
.ask-you-universe .chat-message-container .response .youu-chat-messages {
  white-space: break-spaces;
  word-wrap: break-word;
  padding: 5px;
  display: flex;
  align-items: center;
}

.ask-you-universe .chat-message-container .user-query .youu-chat-messages {
  justify-content: end;
}

.ask-you-universe .chat-message-container .youu-chat-messages .bookmark {
  cursor: pointer;
}

.ask-you-universe .chat-message-container .user-query .messages {
  background: #F8F8F8;
  border-radius: 4px;
  display: block;
  font-weight: 400;
  font-size: 16px;
  color: #182838;
  padding: 10px 15px;
  width: 100%;
  /* display: flex;
  justify-content: end; */
}

.ask-you-universe .chat-message-container .response .messages {
  background-color: #E5F0FF;
  border-radius: 10px;
  display: block;
  font-weight: 400;
  font-size: 16px;
  color: #182838;
  padding: 10px 15px;
  width: 70%;
  word-wrap: break-word;
}

.ask-you-universe .chat-message-container .response .edit-messages {
  background-color: #E5F0FF;
  border-radius: 10px;
  padding: 10px 15px;
  width: 70%;
}

.ask-you-universe .chat-message-container .response .edit-messages .editing {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #182838;
}

.ask-you-universe .chat-message-container .response .edit-messages #question {
  background-color: #E5F0FF;
}

.ask-you-universe .chat-message-container .user-query .messages-time,
.ask-you-universe .chat-message-container .response .messages-time {
  display: block;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  color: #7F8188;
  margin-top: 5px;
}

.ask-you-universe .messages-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ask-you-universe .bookmark-row {
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
}

.ask-you-universe .messages-actions .chat-time {
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  color: #7F8188;
  margin-top: 5px;
}

.ask-you-universe .messages-actions .chat-edited {
  white-space: nowrap;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #515151;
  padding-left: 5px;
}

.ask-you-universe .messages-actions .events span {
  background: #FFFFFF;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 0px 5px;
  position: relative;
  cursor: pointer;
}

.ask-you-universe .messages-actions .events span img {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-5px, -5px);
}

.youu-homepage {}

.youu-homepage .bookmarked-questions {
  margin-top: 15px;
}

.youu-homepage .bookmarked-questions .bookmark-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #7F8188;
}

.youu-homepage .bookmarked-questions .bookmark-list {
  list-style: none;
  padding-left: 10px;
  margin-top: 15px;
  overflow-y: scroll;
  max-height: 300px;
}

.youu-homepage .bookmarked-questions .bookmark-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 10px;
  cursor: pointer;
}

.youu-homepage .youu-chat-submit {
  margin-left: 10px;
}

.youu-homepage .youu-chat-submit img {
  vertical-align: initial;
}

.youu-homepage .bookmarked-questions .bookmark-list-item .query {
  display: flex;
  align-items: center;
}

.youu-homepage .bookmarked-questions .bookmark-list-item .query .text {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #182838;
  padding-left: 10px;
}

/* .ask-you-universe .you-universe-drawer {
  background: #ffffff !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.0620356) !important;
} */
.conversations-history-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #182838;
}

.new-conversation {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #009EF7;
  cursor: pointer;
}

/* Chat History */
.conversations-history {}

.conversations-history .conversations {
  list-style-type: none;
  margin-bottom: 0.5em !important;
  padding: 0px 5px 0px 5px;
}

/* .conversations-history ul.conversations:hover {
  background-color: #ECF7FF;
} */
.conversations-history ul.conversations li {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #828282;
  border-bottom: 1px solid #E2E2E2;
  padding: 10px 0px 10px 0px;
}

.conversations-history .conversation-item {
  display: flex;
  justify-content: space-between;
}

.conversations-history .conversation-item .text {
  cursor: pointer;
  display: flex;
  color: #182838;
  font-weight: 400;
  font-size: 14px;
}

.conversations-history .conversation-item .action {
  cursor: pointer;
  display: flex;
  min-width: fit-content;
}

.reminder-info {
  background: #F8F8F8;
  border-radius: 8px;
  padding: 15px;
}

.reminder-info .title {
  font-weight: 700;
  font-size: 16px;
  color: #182838;
  display: block;
}

.reminder-info .info {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  display: block;
}

.lab-order {}

.lab-order .test-list-text {
  margin-top: 10px;
}

.lab-order .test-list-text .cat-title {
  display: block;
  font-weight: 700;
  font-size: 17px;
  color: #3D3D3D;
}

.lab-order .test-list-text .cat-desc {
  font-weight: 400;
  font-size: 16px;
  color: #212529;
}

.lab-panel-border .ant-collapse-header {
  /* border-left: 3.5px solid rgb(74, 175, 253) !important; */
  /* background: rgba(70, 180, 254, 0.373142); */
  background: #fff;
}

.lab-panel-border .ant-collapse-content .ant-collapse-content-box {
  background: #fff;
}

.lab-panel-border .panel-children {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
  padding: 5px;
  background-color: rgb(239, 247, 254);
  border-radius: 5px;
}

.lab-order-panel .panel-children .ant-checkbox-checked {
  background-color: #fff;
}

/* Commenting the code because of new design */

/* .buildServiceBundle .ant-collapse-expand-icon {
  position: absolute;
  top: 33px;
  right: 5px;
} */

.buildServiceBundle .blocks .item {
  border-bottom: 1px solid #D8D8D8;
}

.buildServiceBundle .blocks .label {
  color: #182838;
  font-size: 12px;
  background-color: #E8E8E8;
  padding: 8px;
  border-right: 1px solid #D8D8D8;
  text-align: center;
}

.buildServiceBundle .blocks .value {
  color: #182838;
  font-size: 14px;
  background-color: rgb(248 248 248);
}

.buildServiceBundle .blocks .value-container {
  background-color: #E8E8E8;
  padding: 10px 27px;
  margin: 15px;
  display: inline-block;
  text-align: center;
  font-weight: 700;
}

.labor-cost .quantity-btn .numbers-row .number-icon {
  background: #fff;
}

.labor-cost .css-1s2u09g-control {
  background: unset !important;
}

#container {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 80px;
  top: 118px;
  bottom: 0;
  -webkit-filter: url(#threshold) blur(0.6px);
  filter: url(#threshold) blur(0.6px);
  z-index: 9999;
}

#text1,
#text2 {
  position: absolute;
  width: 100%;
  display: inline-block;
  font-size: 16pt;
  font-weight: 700;
  text-align: center;
  user-select: none;
}



.img-circle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
}


.img-circle::before {
  content: '';
  width: 100%;
  /* height: calc(100% + 10px); */
  /* background-color: #ccc; */
  /* border-radius: 10px; */
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;

}

.img-circle::after {
  content: " ";
  background-image: url('images/blank-profile.png');
  background-size: 50px 50px;
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.ai-licence-drawer .ant-drawer-close {
  display: flex;
  align-items: center;
  display: inline-block;
  margin: 0 95%;
}

.ai-marketing>div:first-child {
  background: #F0F9FF;
  padding: 17px;
}

.ai-licence-drawer .ant-drawer-body {
  padding: 0px 0px 24px 0px;
}

.ai-marketing .label {
  font-size: 20px;
  font-weight: 700;
}

.ai-licence-drawer .ant-drawer-body {
  overflow: hidden;
  position: relative;
}

/* .ai-licence-drawer
.ant-drawer-header{
  background-color: #F0F9FF;
} */

.buildServiceBundle .license-quantity .quantity-btn {
  margin: 20px 0px !important;
}

.buildServiceBundle .noOfService .license-quantity .quantity-btn {
  margin: 0px 0px !important;
}


.token-switch .ant-switch-inner {
  color: black;
}

.token-switch.ant-switch-checked .ant-switch-inner {
  color: white;
}

.purchase-ai-license .center-token .label {
  color: #959595;
  font-weight: 400;
  font-size: 14px;
}

.purchase-ai-license .center-token .count .cost .input-group-text {
  background-color: unset;
  border: none;
  font-weight: 800;
  padding-right: 0.3rem;
}

.sg-frequenncy {}

.sg-frequenncy .recurring-block {
  margin-right: 2.5rem;
}

.sg-frequenncy .recurring-block .recurring-block-label {
  color: #959595;
  font-size: 16px;
  white-space: nowrap;
}

.sg-frequenncy .recurring-block .recurring-block-value {
  color: #000000;
  font-size: 16px;
}

.sg-frequenncy .recurring-block .bi-week-note {
  background: #e9f1fc;
  padding: 20px;
}

.sg-frequenncy .recurring-block .note-text {
  font-size: 14px;
  line-height: 19px;
  color: #959595;
}

.root-smart-contract .assign-care-team,
.root-smart-contract .payer-details {
  background: #F8F8F8;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
}

.root-smart-contract .assign-care-team .label,
.root-smart-contract .payer-details .label,
.root-smart-contract .label {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;
}

.root-smart-contract .assign-care-team-option .select__control {
  border: 1px solid hsl(0, 0%, 80%) !important;
}

.root-smart-contract .details .label {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #959595;
  text-transform: unset;
}

.root-smart-contract .details .value {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #182838;
}

.ant-tabs-content-holder {
  background-color: #fff;
}

.root-smart-contract .ant-collapse-header,
.root-smart-contract .ant-collapse-content .ant-collapse-content-box {
  background-color: white !important;
}

.root-smart-contract .fee-for-service {
  background: #F8F8F8;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
}

.user-merging {
  flex-direction: column;
}

.user-merging table * {
  border: 1px solid black;
  font-size: 18px;
  padding: 5px;
}

.user-merging table {
  margin-top: 25px;
  max-width: 583px;
}

.user-merging table td {
  /* word-break: break-all; */
  white-space: break-spaces;
  max-width: 250px;
}

.root-smart-contract .buildServiceBundle .label {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.root-smart-contract .buildServiceBundle .blocks .value-container {
  padding: 10px 5px;
}

.root-smart-contract .buildServiceBundle .blocks .label {
  height: 69px;
}

.buildServiceBundle .blocks .value {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cost-summary div {
  font-size: 16px;
}

/* .buildServiceBundle .ant-collapse .ant-collapse-header {
  background: #eaeaea7a !important;
} */


/* css used for making input type number only and hiding that plus minus buttons */
.numbers-only::-webkit-outer-spin-button,
.numbers-only::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numbers-only[type=number] {
  -moz-appearance: textfield;
}

.money {
  font-weight: 700;
}

.money-main {
  text-decoration: underline;
}


.payer-dashboard .status-blue {
  background: #e5f2ff;
  border-radius: 8px;
}

.payer-dashboard .status-blue .title {
  color: #0079fd;
}

.payer-dashboard .status-red {
  background: #ffedf0;
  border-radius: 8px;
}

.payer-dashboard .status-red .title {
  color: #eb002a;
}

.payer-dashboard .status-warn {
  background: #fdefe7;
  border-radius: 8px;
}

.payer-dashboard .status-warn .title {
  color: #84411a;
}

.payer-dashboard .status-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

/* .payer-dashboard .recent-claims, .payer-dashboard .alerts{
  margin: 15px;
  max-width: 47.9% !important;
} */

.payer-dashboard .recent-claims .heading,
.payer-dashboard .alerts .heading {
  font-size: 20px !important;
  font-weight: 700;
}

.payer-dashboard .payee-img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
}

.payer-dashboard .recent-claims p {
  margin-bottom: 0 !important;
}

.payer-dashboard .alerts p {
  margin-bottom: 0 !important;
}

.payer-dashboard .recent-claims .payee-center,
.payer-dashboard .recent-claims .payee-date {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7f8188;
}

.payer-dashboard .recent-claims .payee-name,
.payer-dashboard .recent-claims .payee-cost {
  font-size: 17px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.payer-dashboard .recent-claims .payee-date,
.payer-dashboard .recent-claims .payee-cost {
  text-align: right;
}

.payer-dashboard .alerts .payer-alerts {
  /* color: #7f8188; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-decoration: underline;
}

.payer-authorizations .client-details .client-info {
  padding: 10px;
}

.payer-authorizations .client-authorization-details .client-authorization-info {
  padding: 10px;
  margin: 0 17px;
}

.payer-authorizations .client-details .client-info .client-info-heading,
.payer-audits .client-details .client-info .client-info-heading {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
}

.payer-authorizations .client-details .client-info .client-info-text,
.payer-audits .client-details .client-info .client-info-text {
  font-weight: 700;
}

.payer-authorizations .client-authorization-details .client-authorization-info .client-info-heading {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
}

.payer-authorizations .client-authorization-details .client-authorization-info .client-info-text {
  font-weight: 700;
}

/* .payer-authorizations .client-name,
.payer-authorizations  */
.payer-client-name {
  font-weight: 700;
  font-size: 23px;
}

/* .payer-authorizations .client-center,
.payer-authorizations  */
.payer-client-center {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
}

.payer-authorizations .client-authorization .client-authorization-label,
.payer-authorizations .client-authorization .client-authorization-value {
  /* border: 1px solid rgba(0,0,0,0.3); */
  padding: 10px;
}

.payer-authorizations .client-authorization .client-authorization-label {
  /* min-height: 50px; */
  /* width: 50%;
  font-size: 16px;
  height: inherit; */

  width: 50%;
  font-size: 16px;
  height: inherit;
  height: 100%;
  display: flex;
  align-self: center;
  align-content: center;
  flex-wrap: wrap;
  /* margin-top: 40px; */
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}



.payer-authorizations .client-authorization .client-authorization-value {

  width: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: 700;
  height: 100%;
  display: flex;
  align-self: center;
  align-content: center;
  flex-wrap: wrap;
  border-left: 1px solid rgba(0, 0, 0, 0.3);

}

.payer-authorizations .client-authorization .client-authorization-container {
  /* padding: 10px; */
  /* min-height: 50px; */
  border: 1px solid rgba(0, 0, 0, 0.3);
  max-width: 47.6%;
  padding: 0 !important;
  margin: 10px;
}

.btn-deny {
  font-weight: bold;
  border-radius: 26px;
  border: 1px solid red;
  color: red !important;
  padding: 4.5px 14px;
  line-height: 24px;
  background-color: white;
  background-size: 300% 100%;
  transition: all 300ms ease-in-out;
  margin: 0 10px;
  cursor: pointer;
}

.btn-approved {
  font-weight: bold;
  border-radius: 26px;
  border: 1px solid #00DC31;
  color: #00DC31 !important;
  padding: 4.5px 14px;
  line-height: 24px;
  background-color: white;
  background-size: 300% 100%;
  transition: all 300ms ease-in-out;
  margin: 0 10px;
  cursor: pointer;
}


.client-info-text.status-1 {
  background-color: #2FE192;
  color: white;
  padding: 0 5px;
  text-transform: uppercase;
}


.client-info-text.status-2 {
  background-color: #FFC700;
  color: #BC5103;
  padding: 0 5px;
  text-transform: uppercase;

}


.client-info-text.status-3 {
  background-color: #FF2C2C;
  color: white;
  padding: 0 5px;
  text-transform: uppercase;

}


.payer-card {
  width: 100%;
  background-color: #F8F8F8;
  padding: 20px;
}


.client-label {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
}

.client-text {
  font-weight: 700;
}

.client-detail-box {
  padding: 10px;
  margin: 0 17px;
}

/* Question Library  */
.question-library-drawer {}

.manage-new-question {}

.sortable-element-item {
  padding: 10px;
  background: rgb(247, 248, 250);
  display: flex;
  flex: 1 1 0%;
  align-items: baseline;
  cursor: grab;
  position: relative;
  z-index: 9;
}

.answer-options {}

.answer-options-section {
  overflow-y: scroll;
  overflow-x: hidden;

}

.threshold-value {
  border: 1px solid #AAACB5;
  border-radius: 4px;
  padding: 5px 10px;
  margin-right: 5px;
}

.answer-options .options-row {
  background-color: #F8F8F8;
  border: 1px solid #EEF1F4;
  padding: 15px 10px;
}

.answer-options .options-row .label-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.answer-options .options-row input[type=text] {
  background-color: #F8F8F8;
}

.answer-options .answer-options-checked {
  padding-bottom: 10px;
  margin: 0px 2px;
}

.answer-options .answer-options-checked .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff;
}

.answer-options .answer-options-checked .ant-checkbox-wrapper {
  padding: 10px;
  font-weight: 600 !important;
  color: #ffffff;
}

.labor-cost .css-13cymwt-control {
  background: unset !important;
}

.enrollment-page .root-smart-contract .ant-tabs-nav>div .ant-tabs-tab {
  justify-content: center !important;
}

.labor-cost .css-13cymwt-control {
  background: unset !important;
}

.masked-date .react-datepicker__close-icon {
  margin-right: 10px;
  margin-top: 5px;
}

.payer-milestone .ant-collapse {
  border: none !important;
  border-bottom: none !important;
  background-color: transparent;
}

.payer-milestone .ant-collapse>.ant-collapse-item {
  border: none !important;
  border-bottom: none !important;
}

.payer-milestone .ant-collapse-content {
  border-top: none !important;
}

.payer-milestone .ant-collapse-expand-icon {
  position: absolute;
  right: 13px;
}

.milestone-measure .client-label {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
}

.milestone-measure .client-text {
  font-size: 20px;
  font-weight: 500;
}

.payer.timetravel-filter .ant-checkbox-group {
  display: inline-block !important;
}

.payer.timetravel-filter {
  max-height: 450px;
  overflow: auto;
  height: unset;
}

.payer-milestones .payer-milestone:before {
  border-left: 2px dashed #D9D9D9;
  content: '';
  position: relative;
  left: 6%;
  display: block;
  right: 0;
  /* margin: 0 auto; */
}


/* .payer-milestone .ant-collapse-content .ant-collapse-content-box{
  border-radius: 10px;
} */

.un-sign-notes .container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.un-sign-notes .ant-table-cell {
  padding: 16px !important
}

.un-sign-notes .ant-table,
.un-sign-notes .ant-table-thead>tr,
.un-sign-notes .ant-table-thead>tr>th {
  background-color: #f8f8f8 !important;
}

.un-sign-notes {
  background-color: white !important;
  padding-top: 12px;
  /* padding-left: 12px; */
}


.un-sign-notes .ant-tabs>.ant-tabs-nav {
  margin-left: 10px;
  margin-bottom: 0;
}


.un-sign-notes .ant-tabs-small>.ant-tabs-nav .ant-tabs-tab {
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 8px 18px !important;
}

.payer-grid .row-hover-enabled .ant-table-row:hover {
  cursor: default;
}

.payer-dashboard .recent-claims .payer-item:not(:last-child),
.payer-dashboard .alerts .payer-item:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
}

.payer-dashboard .prospect-status .status .text {
  font-size: 16px;
}

.payer-authorizations .root-smart-contract .payer-details .details .label {
  font-size: 14px;
  color: #7F8188;
}

.payer-authorizations .ant-tabs-tab-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-title-absolute {
  /* position: absolute;
  top: 10px; */
  font-size: 32px;
}

.unsign-modal {
  padding-bottom: 0 !important;
}

.payer.timetravel-filter .ant-checkbox-group .ant-checkbox-wrapper span:nth-child(2) {
  align-self: end;
}

/* peer-recovery-section */
.peer-recovery-section {
  display: flex;
  justify-content: end;
  align-items: center;
}

.peer-recovery-section .text {
  font-weight: 400;
  font-size: 16px;
  color: #182838;
  margin-right: 10px;
}

.peer-recovery-section .action {
  min-width: 300px
}

.stopwatch-container {
  padding: 0px;
  max-width: 280px;
  min-width: 260px;
  text-align: right;
}

.stopwatch-container .stopwatch-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 5px 0px;
  /* border: 1.5px solid rgba(0, 0, 0, 0.20); */
  background: #FFF;
  /* border: 1.5px solid;
    border-image-source: linear-gradient(90deg, #00F2FE 0%, #03EFFE 2.08%, #24D2FE 29.31%, #3CBDFE 55.38%, #4AB0FE 79.56%, #4FACFE 100%); */
}

.stopwatch-container .stopwatch-buttons .stopwatch-time {
  margin-bottom: 0em;
  display: flex;
}

.prospect-appointments-form .call-end-summary {
  text-align: center;
}

.prospect-appointments-form .call-end-summary .call-end-time {
  font-weight: 700;
  font-size: 26px;
  color: #000000;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prospect-appointments-form .call-end-summary .call-end-text {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #182838;
}

.peer-recovery .call-end-summary .call-summary {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  background: #F8F8F8;
  border-radius: 8px;
  /* text-align: center; */
  padding: 15px;
}

.peer-recovery .call-end-summary .summary-title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  display: block;
}

.peer-recovery .call-end-summary .call-summary-text-desc {
  color: #182838;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.peer-recovery .call-end-summary .call-summary-text {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  background: #F8F8F8;
  border-radius: 8px;
  text-align: center;
  padding: 15px;
}

.peer-recovery .appointment-date {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #182838;

}

.peer-recovery-drawer .ant-drawer-footer {
  padding: 0px;
}

.peer-recovery-drawer .call-prepared-footer {
  background: #F8F8F8;
  text-align: center;
  padding: 25px 15px;
}

.peer-recovery-drawer .player-footer {
  background: #4AB0FE;
  padding: 15px 15px;
}

.peer-recovery-drawer .player-footer .rhap_container {
  background-color: transparent;
  box-shadow: none;
}

.player-footer .rhap_time,
.player-footer .rhap_button-clear {
  color: #ffffff
}

.player-footer .rhap_progress-filled,
.player-footer .rhap_progress-indicator {
  background: #ffffff;
}

.peer-recovery-drawer .footer-text {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}

.transcript .transcript-preparing {
  background: #F8F8F8;
  text-align: center;
  padding: 25px 15px;
}

.transcript .transcript-preparing-text {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}

.transcript .transcript-row {
  margin-bottom: 15px;
}

.transcript .transcript-title {
  color: var(--text-primary, #182838);
  font-size: 16px;
  font-weight: 700;
}

.transcript .transcript-row .speaker-text {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.transcript .transcript-row .transcript-text {
  color: #182838;
  font-size: 14px;
}

.peer-recovery-notes {}

.peer-recovery-notes .note-lable {
  display: flex;
  justify-content: space-between;
}

.peer-recovery-notes .note-lable .add {
  color: #959595;
  font-size: 14px;
}

.peer-recovery-notes .note-lable .clear {
  color: #9A9A9A;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.11px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.peer-recovery-notes .note-lable .save {
  color: #4AB0FE;
  text-align: right;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.11px;
  text-transform: capitalize;
  cursor: pointer;
}

.peer-recovery-notes .augmented-note-box {
  border-radius: 4px;
  border: 1px solid #AAACB5;
  background: #F8F8F8;
  padding: 10px;
}

.peer-recovery-notes .augmented-note-box .output-title {
  color: #7F8188;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.peer-recovery-notes .augmented-note-box .augmented-note {
  color: #182838;
  /* font-family: Source Sans Pro; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.peer-recovery-notes .augmented-note-box .use-this-text {
  color: #009EF7;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .peer-recovery-tab .ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: 1px solid #f0f0f0 !important;
  } */
.ant-tag {
  display: inline-flex;
  align-items: center;
}

.sortable-element-item {
  cursor: auto;
}

.drawer-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawer-title .title {
  font-weight: 700;
  font-size: 20px;
  color: #182838;
  padding-left: 10px;
}

.drawer-footer {}

/* .select__value-container { padding: 12px 0 !important; } */

.custom-drawer.client-augment .ant-drawer-body {
  padding-top: 0 !important
}

.document-upload .placeholder-label {
  background-color: transparent !important;
}

.record-call-modal {}

.record-call-modal .title-text {
  color: #828282;
  text-align: center;
  font-size: 18px;
  display: block;

}

.record-call-modal .pro-picture {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.record-call-modal .name {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: Nunito;
  font-weight: 800;
  display: block;
  margin-bottom: 15px;
}

.record-call-modal .phone {
  color: #000;
  text-align: center;
  font-size: 18px;
  display: block;
}

.record-call-modal .call-action {
  display: flex;
  justify-content: space-evenly;
}

.merge-clients .ant-radio-inner {
  border-radius: 50%;
}


.merge-clients .ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.7);
}


.merge-clients .merge-clients-field-name,
.merge-clients .merge-clients-preview,
.merge-clients-header .merge-clients-dropdown,
.merge-clients-header .merge-clients-preview {
  border: 1px #C8C6C6 solid;
  padding: 10px 20px;
  margin: 0 !important;
}

.merge-clients .merge-clients-field-name-header {
  border: 1px #C8C6C6 solid;
  border-top: 2px #C8C6C6 solid;
  border-left: 2px #C8C6C6 solid;
}

.merge-clients .merge-clients-preview-header {
  border: 1px #C8C6C6 solid;
  border-top: 2px #C8C6C6 solid;
  border-right: 2px #C8C6C6 solid;
}

.merge-clients .merge-clients-field-name {
  border-left: 2px #C8C6C6 solid;

}

.merge-clients .merge-clients-preview {
  border-right: 2px #C8C6C6 solid;
  word-break: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.merge-clients-header .merge-clients-dropdown {
  border-top: 2px #C8C6C6 solid;

}

.merge-clients-header .merge-clients-preview {
  border-top: 2px #C8C6C6 solid;
  border-right: 2px #C8C6C6 solid;

}

.merge-clients .merge-radio {
  padding: 10px 20px;
  margin: 0 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-weight: 600 !important;
  align-items: flex-start;
  height: 100%;
  word-break: break-word;
  color: #000000;

}

.merge-clients .merge-clients-radio {
  border: 1px #C8C6C6 solid;

}

.merge-clients .merge-clients-field-name {
  font-weight: 600;
  color: #474747;
}


.merge-clients .ant-radio-wrapper-checked.merge-radio {
  background-color: #4AB0FE14 !important;
}

.merge-client-container div:last-child .merge-clients-field-name,
.merge-client-container div:last-child .merge-clients-radio,
.merge-client-container div:last-child .merge-clients-preview {
  border-bottom: 2px #C8C6C6 solid;
}


.merge-clients .merge-clients-preview,
.merge-clients .merge-clients-preview-header {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.096px;
}

.merge-clients .merge-clients-view-profile {
  color: rgb(70, 180, 254);
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  text-decoration: underline;
  width: fit-content;
}

.grid-action {
  display: flex;
  align-items: center;
  min-width: max-content
}

.grid-action img {
  padding-left: 5px;
  padding-right: 5px;
}


.ant-drawer-title {
  font-size: 22px;
  color: white;
  font-weight: 700;
}

.custom-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
}

.merge-clients .merge-clients-dropdown .placeholder-label {
  font-size: 14px !important;
}

.merge-clients .merge-clients-dropdown .select__single-value {
  font-weight: 700 !important;
}

.merge-clients-modal .successful-helper {
  color: #828282;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.merge-clients-modal .ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-bottom-link ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
}

.footer-bottom-link ul li a {
  color: rgba(43, 41, 45, 0.6);
  text-decoration: none;
}

.footer-bottom-link ul li {
  /* margin-right: 15px; */
  padding: 10px;
  position: relative;
}

.footer-bottom-link ul li a:hover {
  color: #0f8eff !important;
}

.login-page {
  height: 90vh !important;
  min-height: 90vh !important;
}

.client-screening .ant-tabs-content-holder {
  background-color: transparent !important;
}

.group-banner .group .btn {
  margin-left: 0;
}



.checkbox-to-radio .ant-radio-inner::after {
  content: ' ' !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  display: block !important;
  width: 16px !important;
  height: 16px !important;
  margin-top: -8px !important;
  margin-left: -8px !important;
  background-color: #1890ff !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-radius: 16px !important;
  transform: scale(0) !important;
  opacity: 0 !important;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) !important;
  /* transform: scale(0.5) !important; */
  /* opacity: 1 !important; */
  /* transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) !important; */
}

.checkbox-to-radio .ant-radio-inner {
  top: -3px !important;
}

.checkbox-to-radio .ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.7) !important;
  opacity: 1 !important;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) !important;
}


.checkbox-to-radio .ant-radio-inner {
  border-radius: 50% !important;
}


.center-custom-fields {
  color: #4FADFF;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 88.889% */
  text-decoration-line: underline;
  cursor: pointer;
}


.center-custom-fields-drawer .ant-drawer-body {
  background-color: transparent;
}

.ant-switch-handle {
  top: 1px !important;
}

.center-custom-fields-drawer .ant-radio-wrapper,
.ant-checkbox-wrapper {
  font-weight: 600 !important;
  /* color: #474747;
  display: flex;
  align-items: end;
  margin-bottom: 10px; */
}

/* rcm-augmented-summary */

.rcm-augmented-summary {}

.rcm-augmented-summary .ai-summary {
  border-radius: 8px;
  background: #F8F8F8;
  box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.03);
  padding: 15px;
  margin: 15px;
}

.rcm-augmented-summary .ai-summary-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.rcm-augmented-summary .ai-timeline-action .title,
.rcm-augmented-summary .ai-summary-action .title {
  color: #182838;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.rcm-augmented-summary .ai-summary .ai-summary-text {
  color: var(--primary-text, #182838);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

}

.rcm-augmented-summary .ai-summary-timeline {
  /* padding: 15px; */
  margin: 15px;
}

.save-button.export-pdf {
  display: flex;
  align-items: center;
  height: 43px;
  font-weight: 500 !important;
}

.radio-nested-tables .ant-table-thead>tr>th,
.nested-tables .ant-table-thead>tr>th {
  background-color: #F2F2F2 !important;
}


.nested-tables::before {
  content: '';
  position: absolute;
  background-color: #4AB0FE;
  width: 1.75px;
  height: 100%;
  bottom: 0;
  left: 36.5px;
}

.ant-checkbox {
  top: 0em !important;
}




.contract-status {}

.contract-status .incomplete {
  border-radius: 4px;
  background: #CCC;
  color: #333;
  font-weight: 400;
  font-size: 14px;
  padding: 2px 5px;
}

.contract-status .pending {
  border-radius: 4px;
  background: #FFA500;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  padding: 2px 5px;
}

.contract-status .active {
  border-radius: 4px;
  background: #009EF7;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  padding: 2px 5px;
}

.contract-status .terminated {
  border-radius: 4px;
  background: #FF2C2C;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  padding: 2px 5px;
}

.contract-status .expired {
  border-radius: 4px;
  background: #8B0000;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  padding: 2px 5px;
}

.contract-status .delivered {
  border-radius: 4px;
  background: #1DD63F;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  padding: 2px 5px;
}

.contract-status .declined {
  border-radius: 4px;
  background: #FF2C2C;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  padding: 2px 5px;
}

.terms-condition {
  padding: 5px;
}

.terms-condition .terms-condition-detail,
.terms-condition .conditions-detail,
.terms-condition .terms-detail {
  background-color: #F8F8F8;
  padding: 10px 5px;
  margin-bottom: 10px;
}

.terms-condition .terms-condition-detail .title {
  color: #182838;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.terms-condition .terms-detail .term-title {
  color: #182838;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.terms-condition .terms-detail .term-detail-title {
  color: #182838;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.terms-condition .terms-detail .term-detail-section {
  background: #F8F8F8;
  padding: 15px 10px;
}

.terms-condition .terms-detail .terms-list {
  background-color: #ffffff;
  padding: 10px 5px;
}

.terms-condition .terms-detail .terms-list ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.terms-condition .terms-detail .terms-list .terms-list-item {
  background-color: #F8F8F8;
  border-radius: 4px;
  border: 1px solid #EAEAEA;
  background: #F8F8F8;
  margin: 10px 5px;
  padding: 10px;
}

.terms-condition .terms-detail .terms-list .description {}

.terms-condition .terms-detail .terms-list .action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85px;
}

.terms-condition .terms-detail .terms-list .action .delete {
  color: #FF2C2C;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}

.terms-condition .terms-detail .terms-list .action .edit {
  color: #46B4FE;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}

.case-row {
  background: #F8F8F8;
  padding: 15px 10px;
  margin-bottom: 10px;
}

.payer-milestone .ant-collapse-header {
  padding: 0 !important;
  align-items: center !important;
}

.payer-milestone .ant-collapse-content .ant-collapse-content-box {
  background-color: transparent;
}

.payer-milestone.target-service .ant-collapse-item {
  border: 1px solid #EAEAEA !important;
  border-radius: 4px !important;
  padding: 10px;
}

.payer-milestone.target-service .ant-collapse-expand-icon {
  left: 100% !important;
}

.payer-milestone.target-service .ant-collapse-content-box {
  padding-bottom: 0 !important;
}

.insurance-detail .insurance-section {
  border: 1px solid #EAEAEA;
  background: #F8F8F8;
  padding: 10px;
}

.insurance-detail .insurance-section .items {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.insurance-detail .insurance-section .label-text {
  color: #959595;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.insurance-detail .insurance-section .label-value {
  color: #182838;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.payer-details .ant-table-tbody>tr>td {
  padding: 12px 8px;
}

.contract-drawer-title {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.contract-details .sections {
  padding: 16px;
  background: #F2F2F2;
  margin-bottom: 20px;
  margin: 10px;
}

.contract-details .sections-action {
  display: flex;
  align-items: center;
  justify-content: end;
}

.contract-details .sections-action .denied {
  color: #FF2C2C;
  font-size: 14px;
  font-weight: 400;
}

.contract-details .sections-action .approved {
  color: #2FE192;
  font-size: 14px;
  font-weight: 400;
}

.contract-details .section-item {
  border: 1px solid #EAEAEA;
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 20px;
}

.contract-details .section-item .request-sr-no {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  width: 40px;
}

.contract-details .section-item .label {
  color: #959595;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.contract-details .section-item .value {
  color: #000;
  font-size: 18px;
  font-weight: 400;
}

.contract-details .section-item .des-value {
  color: #525252;
  font-size: 16px;
  font-weight: 400;
}

.contract-details .section-item .item-block {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}

.contract-details .section-item .request-detail {
  display: flex;
  flex-direction: column;
}

.contract-details .section-item .details {
  border-radius: 4px;
  border: 1px solid #D8D8D8;
  background: #F8F8F8;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #182838;
}

.contract-details .section-item .request-info {}

.contract-details .service-code .code-details {
  display: flex;
  flex-wrap: wrap;
}

.contract-details .service-code .total {
  color: #182838;
  font-size: 16px;
  font-weight: 700;
}




.tAndC .ant-checkbox-wrapper {
  align-items: start !important;
  font-size: 16px !important;
}

.tAndC .ant-checkbox-wrapper .ant-checkbox {
  margin-top: 4px;
  margin-right: 8px !important;
}

.ant-modal.decline-modal .ant-modal-body {
  padding: 35px !important;
  text-align: center;
}

.ant-modal.decline-modal .ant-modal-close {
  margin-top: 15px !important;
}

.warning-info-text {
  color: #FF2C2C;
  border: 1px solid #FF2C2C;
  padding: 7px;
  border-radius: 7px;
  background-color: rgba(255, 44, 44, 0.05);
}

.cost-summary .ant-collapse-expand-icon {
  position: absolute;
  right: 5px;
}





.care-radio-container .ant-radio.ant-wave-target {
  justify-self: start;
}

.care-radio-container {
  display: flex;
  flex-wrap: wrap;
}

.care-radio-container .ant-radio-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: start;
  padding: 13px;
  border-radius: 10px;
  width: 49%;
}

.care-radio-container .ant-radio-wrapper.ant-radio-wrapper-checked {
  border: 3px #46B4FE solid;
  padding: 10px;
}

.radio-info-container {
  width: 300px;
  align-items: center;
}


.main-label {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.helper-label {
  color: #979797;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.care-team-img {
  height: 50px;
  width: 50px;
  border-radius: 10px;
}

.care-team-radio.ant-radio-wrapper::after {
  display: none !important;
}

.ant-radio-wrapper.care-team-radio .ant-radio-inner {
  border-radius: 50% !important;
}

/* requested-documents-list */
.requested-documents .title {
  color: #182838;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.uploaded-documents-list,
.requested-documents-list {
  border-radius: 4px;
  border: 1px solid #EAEAEA;
  background: #F8F8F8;
  margin-bottom: 10px;
  padding: 10px;
}

.requested-documents .list-item {
  margin-bottom: 30px;
}

.requested-documents .doc-info {
  border-radius: 4px;
  border: 1px solid #EAEAEA;
  background: #FFF;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.requested-documents .doc-info .info {
  display: flex;
  flex-direction: column;
}

.requested-documents .doc-info .info .label-text {
  color: #959595;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.requested-documents .doc-info .info .value {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.requested-documents .doc-action {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.requested-documents .doc-action .doc-link {
  color: #46B4FE;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.doc-name,
.requested-documents .doc-action .doc-name {
  color: #46B4FE;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding: 0px 5px;
}

.demographics-form .enrollment-form .custom-control-label {
  font-family: unset;
}

#mydiv {
  position: fixed;
  z-index: 19;
  top: 100px;
  left: 100px;
  border-radius: 6px;
  border: 3px solid #4AB0FE;
  background: #FFF;
  box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.12);
  resize: horizontal;
  /* resize: both; */
  overflow: auto;
  min-width: 200px;
  min-height: 100px;
  max-width: 800px;
}

#mydiv .container-box {
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
}

#mydiv .container-box .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#mydiv .container-box .info .text {
  font-style: italic;
  font-size: 13px;
  color: #959595;
}

#mydivheader {
  cursor: move;
  z-index: 20;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.static-placeholder {
  position: absolute;
  font-size: 14px;
  color: #959595;
  font-weight: 500;
  transition: all 300ms ease-in-out;
  font-family: 'AvenirLTStdMedium';
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0.5rem;
  top: -5px;
}

.static-formValue {
  color: rgb(24, 144, 255);
  cursor: pointer;
  min-height: 60px;
  padding: 8px 30px 8px 0px;
  box-shadow: none;
  font-size: 1rem;
  font-family: 'AvenirLTStdMedium';
}

.bundle-panel-border .ant-collapse-header {
  border-left: 3.5px solid #46B4FE !important;
  /* background: rgba(70, 180, 254, 0.373142); */
  background: #fff;
}

.goalSection-panel-border .ant-collapse-header {
  border-left: 3.5px solid #00DC31 !important;
  /* background: rgba(70, 180, 254, 0.373142); */
  background: #fff;
}

.milestone-panel-border .ant-collapse-header {
  border-left: 3.5px solid #FFC800 !important;
  /* background: rgba(70, 180, 254, 0.373142); */
  background: #fff;
}

.task-panel-border .ant-collapse-header {
  border-left: 3.5px solid #AF4AFE !important;
  /* background: rgba(70, 180, 254, 0.373142); */
  background: #fff;
}

.service-type-panel-border .ant-collapse-header {
  border-left: 3.5px solid #FE73B1 !important;
  /* background: rgba(70, 180, 254, 0.373142); */
  background: #fff;
}

.service-code-panel-border .ant-collapse-header {
  border-left: 3.5px solid #EE9E68 !important;
  /* background: rgba(70, 180, 254, 0.373142); */
  background: #fff;
}

.client-reels .ant-tabs-nav-wrap .ant-tabs-nav-list {
  max-height: 610px;
  /* overflow: hidden; */
  width: 320px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .client-reels .ant-tabs-nav-list .ant-tabs-tab{
} */

/* .client-reels .ant-tabs-nav-wrap .ant-tabs-nav-list:hover{
  overflow-y: auto;
  overflow-x: hidden;
} */



#scrollableDiv::-webkit-scrollbar {
  width: 8px;
}


#scrollableDiv::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

/* Handle on hover */
#scrollableDiv::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

.client-reels .ant-tabs-nav-list .ant-tabs-tab {
  background-color: #F8F8F8;
  justify-content: start !important;
  text-align: left;
}

.client-reels .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #E8E8E8;
}

.client-reel-subscriber {
  background-color: #E8E8E8;
}

.client-reels .ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
  padding: 0 !important;
}

.mobile-advisor .ant-tabs {
  width: 100%;
}

.client-reel-map .concerned-person-location {
  height: 203px !important;
}

.client-reel-subscriber .pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-notes .toggle-notes-textarea {
  background-color: transparent !important;
}

.tab-number {
  background-color: #B8B8B8;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
}

.ant-tabs-tab.ant-tabs-tab-active .tab-number {
  background-color: #46B4FE;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
}


.ant-tabs-tab .tab-number {
  width: 20px;
  height: 20px;
}

.panel-header-text {
  color: var(--Primary-Text, #182838);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.toggle-notes .form-group .form-control {
  background-color: transparent;
}

.broadcast-filter .ant-collapse-content-box {
  max-height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
}

/* .emotional-graph {
  background-color: #182838 !important;
  padding: 15px;
  color: white;
} */

.strongly-agree-container {
  display: flex;
  align-items: center;
  position: relative;
  left: 50px;
  bottom: 11px;
}

.strongly-agree-container::before {
  content: 'Strongly Agree';
  position: absolute;
  font-size: 14px;
  top: 21px;
}

.strongly-agree-container::after {
  content: 'Strongly Disagree';
  position: absolute;
  left: 54%;
  top: 21px;
  font-size: 14px;
}

.strongly-agree-line {
  width: 67%;
  height: 4px;
  background: linear-gradient(to right, #FF0000, #7FFF00);
}



.strongly-agree-arrow {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 30px solid #7FFF00;
  /* Set arrow color to #7FFF00 */
}



.emotional-container {
  position: relative;
  left: 30px;
}

.emotional-container::before {
  content: 'Negative Emotion';
  position: absolute;
  font-size: 14px;
  /* top: 21px; */
  bottom: 62px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  /* right: -25px; */
  white-space: nowrap;
  left: -54px;
  bottom: 73px;
}

.emotional-container::after {
  content: 'Positive Emotion';
  position: absolute;
  font-size: 14px;
  top: 102px;
  /* bottom: 62px; */
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  white-space: nowrap;
  left: -50px;
  /* right: 15px;*/
}

.emotional-line {
  width: 4px;
  background: linear-gradient(to top, #FF0000, #FFA500, #7FFF00);
  height: 81%;
  position: relative;
  left: 12px;
  top: 25px;
}

.emotional-arrow {
  width: 0;
  height: 0;
  border-bottom: 30px solid #7FFF00;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: relative;
  top: 25px;
}

.table_file_name {
  word-wrap: break-word;
  max-width: 350px;
}

.assign-care-team-option {
  width: 210px;
}

.assign-care-team-option .select__single-value {
  top: 5px;
}

.profile-in-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-in-option .profile {
  width: '50px',
}

.profile-in-option .detail {
  display: flex;
  flex-direction: column;
}

.profile-in-option .detail .text {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* text-wrap: nowrap; */
}

.profile-in-option .detail .value {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.root-smart-contract .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 14px;
}

.enrollment-page .root-smart-contract .ant-tabs-nav>div .ant-tabs-tab {
  font-size: 14px;
}

.suggest-cpt-code {}

.suggested-cpt-code-list ul {
  list-style: none;
  padding-left: 0px;
  /* list-style: decimal-leading-zero; */
}

.suggested-cpt-code-list .item {
  border-bottom: 1px solid rgba(151, 151, 151, 0.20);
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.suggested-cpt-code-list .item .detail {
  display: flex;
  flex-direction: column;
}

.suggested-cpt-code-list .item .selection {
  margin-right: 10px;

}

.suggested-cpt-code-list .item .detail .code {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.096px;
  margin-bottom: 5px;
}

.suggested-cpt-code-list .item .detail .desc {
  color: #979797;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.096px;
}

.suggested-cpt-code-list .item .action span {
  cursor: pointer;
  color: #009EF7;
  font-size: 15px;
  font-weight: 700;
}

.payer-status .shared {
  border-radius: 4px;
  background: #FFC700;
  color: #BC5103;
  font-weight: 400;
  font-size: 14px;
  padding: 2px 5px;
}

.payer-status .not-shared {
  border-radius: 4px;
  background: #009EF7;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  padding: 2px 5px;
}

.multi-select-checkboxes .css-48ayfv,
.multi-select-checkboxes .css-1r4vtzz {
  width: -webkit-fill-available;
  background: transparent !important;
  box-shadow: unset;
  border-bottom: 1px solid hsl(0, 0%, 80%) !important;
}

.client-reels .infinite-scroll-component {
  height: unset !important;
  overflow: unset !important;
}

.clients-reel-subscriber.active {
  background-color: #E8E8E8 !important;
  border-left: 2px solid #1890ff;
}

.assign-care-team .ant-table-container .ant-table-content {
  overflow: visible !important;
}

.root-smart-contract #rc-tabs-2-panel-7,
.root-smart-contract #rc-tabs-3-panel-7,
.root-smart-contract #rc-tabs-4-panel-7,
.root-smart-contract #rc-tabs-2-panel-8,
.root-smart-contract #rc-tabs-6-panel-8,
.root-smart-contract #rc-tabs-4-panel-8,
.root-smart-contract #rc-tabs-5-panel-8 {
  overflow: visible !important;
}

.payer-selection-drawer .ant-checkbox {
  margin-bottom: 9px !important;
}

.payer-selection-drawer .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin: unset !important;
}

.emotional-graph .apexcharts-legend-series {
  padding: 5px 0;
}

.cpt-warning-title {
  text-transform: initial !important;
}

.payer-contract-terms .ant-collapse-content {
  border: none;
}

.payer-contract-terms .ant-collapse-content-box {
  border: none;
  background-color: white;
}

.payer-contract-terms .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center !important;
}

.vertical-tabs .root-smart-contract .ant-tabs-content {
  box-shadow: unset !important;
}

.grid-page-title {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  /* text-transform: capitalize; */
}

.consent-status .denied {
  border-radius: 4px;
  background: #FF2C2C;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  padding: 1px 5px;
}

.consent-status .pending {
  border-radius: 4px;
  background: #FFC700;
  color: #BC5103;
  font-weight: 400;
  font-size: 14px;
  padding: 1px 5px;
}

.consent-status .appoved {
  border-radius: 4px;
  background: #4FACFE;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  padding: 1px 5px;
}

.center-option {
  display: flex;
  justify-content: space-between;
}

.care-team-member-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.care-team-member-option .profile {
  display: flex;
  align-items: center;
}

.care-team-member-option .pic {
  border-radius: 50%;
  object-fit: cover;
  min-height: 28px;
  margin-right: 5px;
}

.care-team-member-option .info {
  /* display: flex;
  flex-direction: column; */
}

.care-team-member-option .info .name {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: block;
}

.care-team-member-option .info .care-team-type {
  font-size: 11px;
  color: 959595;
  font-weight: 400;
  font-style: normal;
  display: block;
}

.care-team-member-option .role-description {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  background-color: rgba(213, 236, 255, 0.50);
  border-radius: 50%;
  padding: 5px;
  width: 30px;
  height: 30px;
  text-align: center;
}

.care-team-member-info {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
}

.care-team-member-info .name {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: block;
}

.care-team-member-info .care-team-status {
  color: #182838;
  font-size: 12px;
  font-weight: 400;
  background-color: #D8D8D8;
  border-radius: 2px;
  text-align: center;
  font-style: italic;
  padding: 2px 5px;
}

.external-member-roles-permissions .card-body {
  padding: 20px 15px;
}

.external-member-roles-permissions .external-roles-permissions-modal {
  margin-bottom: 5px;
}

.external-member-roles-permissions .external-roles-permissions-modal .rolls {
  color: #7E8299 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
}

.external-member-roles-permissions .external-roles-permissions-modal .permissions-block {
  display: flex;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  padding: 10px 0px;
}

.external-member-roles-permissions .external-roles-permissions-modal .permissions {
  color: #182838 !important;
  font-weight: 400 !important;
  font-size: 16px;
  top: unset !important;
  display: flex !important;
  align-items: center !important;
  font-style: normal;
  line-height: normal;
}

.external-member-roles-permissions .external-roles-permissions-modal .permissions-active {
  color: #46b4fe !important;
  font-weight: bold !important;
  font-size: 18px;
  /* color: #46b4fe !important; */
}

.external-care-team-member-view {}

.external-care-team-member-view .view {
  display: flex;
  flex-direction: column;
}

.external-care-team-member-view .view .label {
  color: #7F8188;
  font-size: 14px;
  font-weight: 400;
}

.external-care-team-member-view .view .text {
  color: #182838;
  font-size: 16px;
  font-weight: 400;
}

.contract-card {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.03);
}

.contract-card .status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.contract-card .status .text {
  color: #1E1B39;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.contract-card .status .value {
  color: #4AB0FE;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.payer-portal-center .select__control {
  background-color: #E4E4E4 !important;
}

.payer-contracts-period .select__control {
  background-color: white !important;
  border: 1px solid #959595 !important;
  border-radius: 10px !important;
  max-height: 41px !important;
  padding-left: 10px !important;
}

.assign-payment-status-red,
.assign-payment-status-green {
  width: 120px !important;
}

.enrollment-form .assign-payment-status-green .select__single-value {
  color: green !important;
}

.enrollment-form .assign-payment-status-red .select__single-value {
  color: red !important;
}


.journal-augmenting .ant-drawer-title {
  background: linear-gradient(270deg, #EE9E68 -14.91%, #E7677E 26.71%, #AD5D9E 100%) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
  display: inline-block !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-fill-color: transparent !important;
  background-color: white !important;
  cursor: pointer;
}

.journal-augmenting .ant-drawer-header {
  background-color: white !important;
}

.journal-augmenting .ant-drawer-body {
  background-color: white;
}

.lab-sample-types .ant-radio-group {
  display: flex;
}


a[disabled],
button:disabled:not([role='switch']),
.students-tab-col input:disabled {
  display: inline-block !important;
  background: #cccccc;
}

.augment-regenerate-button input:disabled {
  /* display: none !important; */
  display: flex !important;
  background: #cccccc;
}

.milestones-collapsed .ant-collapse-content,
.milestones-collapsed .ant-collapse-item,
.milestones-collapsed .ant-collapse {
  border: none !important
}

.milestones-collapsed .ant-collapse {
  background-color: white !important;
}

.masterBillOfService .quantity-btn .numbers-row .number-icon,
.noOfService .quantity-btn .numbers-row .number-icon {
  background: #fff !important;
}

.reqDocNum {
  color: white;
  position: absolute;
  top: 11px;
  background: red;
  width: 17px;
  height: 17px;
  border-radius: 40px;
  font-size: 12px;
}

.clinicalTaskSection {
  /* background-color: red; */
}

.clinicalTaskSection .list-item {
  background-color: #F8F8F8;
  /* height: 275px; */
  height: auto;
}

.clinicalTaskSection .list-item .item .label {
  font-weight: 700;
  padding-right: 10px;
  font-size: 16px;
}

.clinicalTaskSection .list-item .item .value {
  font-size: 16px;
}

.note-history-list {
  max-height: 470px;
  overflow-y: auto;
}

.note-history-list .list-box {
  padding: 20px 0 15px;
  border-bottom: solid 1px #eee;
}

.note-history-list .list-box .text {
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
}

.note-history-list .list-box .date-modified {
  display: flex;
  align-items: center;
}

.note-history-list .list-box .date-modified .date {
  font-size: 13px;
  color: #959595;
  font-weight: 800;
  display: block;
  text-align: right;
}

.note-history-list .list-box .date-modified .modified-by {
  font-size: 13px;
  color: #959595;
  font-weight: 800;
  display: block;
  text-align: right;
}

.note-history-list .list-box .history-note {
  display: block;
}

.note-history-list .list-box .history-note .note {
  font-size: 16px;
  color: #828282;
  padding-bottom: 5px;
}

.appointment-slider .slick-prev,
.appointment-slider .slick-next {
  margin-top: 0px !important;
}

.persist-sort-table {
  padding-bottom: 35px;
}

/* .card-box-shadow .card, */
.vertical-tabs .nested-tabs-content .ant-tabs-content {
  box-shadow: none !important;
}

/* .roles-permissions-modal .ant-checkbox-wrapper-checked span {
  color: #46b4fe !important;
  font-weight: bold !important;
  font-size: 18px;
} */

.shared-memo-drawer .dictation-space .mic-container {
  top: 5px !important;
}

.btn-repeat {
  font-weight: bold;
  border-radius: 26px;
  border: 1px solid #4facfe;
  color: #4facfe !important;
  padding: 4.5px 14px;
  line-height: 24px;
  background-color: white;
  background-size: 300% 100%;
  transition: all 300ms ease-in-out;
  margin: 0 10px;
  cursor: pointer;
}

.select-care-team {
  display: block;
  text-align: right;
}

.select-care-team-member {
  display: block;
}

.select-care-team-member .hint {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-right: 5px;
}

.select-care-team-member .select__control {
  border-bottom: 0px !important;
  height: 50px !important;
  background: #d8d8d8;
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  border-radius: 9px !important;
  color: #000;
  font-weight: bolder;
  padding: 5px;
}

.select-care-team-member .select__dropdown-indicator svg {
  color: #000000;
}

.summary-block .blocks {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.summary-block .blocks .item {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  /* flex: auto; */
  min-width: 250px;
  margin-bottom: 25px;
}

.summary-block .blocks .item .title {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.summary-block .blocks .item .value {
  font-weight: 700;
  font-size: 22px;
  color: #4AB0FE;
}

.view-feedback-header .profile .info,
.summary-block .blocks .item .info {
  font-weight: 400;
  font-size: 14px;
  color: #6C6F81;
  display: flex;
  align-items: center;
}

.view-feedback-header {
  padding: 15px;
  border: 1px solid #979797;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-feedback-header .label {
  font-size: 15px;
  font-weight: 400;
  color: #828282;
  margin-bottom: 0px;
}

.view-feedback-header .val {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0px;
}

.view-feedback-header .profile {
  display: flex;
  min-width: 150px;
}

.view-feedback-header .reason {}

.view-feedback-header .staff {}

.view-feedback-body {
  margin-top: 15px;
}

.view-feedback-body .title-label {
  font-weight: 700;
  font-size: 14px;
  color: #7F8188;
}

.view-feedback-body .overall-comment {
  display: flex;
  flex-direction: column;
}

.view-feedback-body .overall-comment .value {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-bottom: 10px;
}

.view-feedback-body .rating-box {
  background-color: #F8F8F8;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}

.view-feedback-body .rating-box .block {
  display: flex;
  justify-content: space-between;
}

.view-feedback-body .rating-box .care-detail {
  display: flex;
  flex-direction: column;
}

.view-feedback-body .rating-box .care-detail .comment {
  display: flex;
  flex-direction: column;
}

.view-feedback-body .rating-box .care-detail .comment .text {
  font-size: 14px;
  font-weight: 400;
  color: #959595;
}

.view-feedback-body .rating-box .care-detail .comment .val {
  font: size 14px;
  font-weight: 400;
  color: #333333;
}

.categories-rated {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  border-bottom: 2px solid #D9D9D9;
}

.categories-rated .title {
  color: #182838;
  font-size: 16px;
  font-weight: 600;
  display: block;
}

.categories-rated .score {
  color: #182838;
  font-size: 20px;
  font-weight: 700;
  display: block;
}

.categories-rated .count {
  color: #979797;
  font-size: 14px;
  font-weight: 400;
  display: block;
}

.bar-chart {}

.bar-chart .bar {
  line-height: 13px;
  height: 13px;
  width: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.bar-chart .bar .cat-1,
.bar-chart .bar .cat-2,
.bar-chart .bar .cat-3,
.bar-chart .bar .cat-4,
.bar-chart .bar .cat-5 {
  height: 100%;
  /* width: 20%; */
  position: relative;
}

.bar-chart .bar .cat-1 {
  background-color: #FF7979;
  position: relative;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.bar-chart .bar .cat-2 {
  background-color: #F6B36F;
  position: relative;
}

.bar-chart .bar .cat-3 {
  background-color: #FBD569;
  position: relative;
}

.bar-chart .bar .cat-4 {
  background-color: #BDD280;
  position: relative;
}

.bar-chart .bar .cat-5 {
  background-color: #66B47C;
  position: relative;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.bar-chart .bar-rating {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}

.bar-chart .bar-rating .rating {
  display: flex;
  flex-direction: column;
}

.bar-chart .bar-rating .rating .count {
  font-size: 12px;
  font-weight: 400;
  color: #182838;
}
.fc-event-main div {
  height: 42px !important
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 118px
}
.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 118px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 132px;
}