/* The Modal (background) */
.custom-modal {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  border-radius: 6px;
  width: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.modal-body {
  padding: 2px 16px;
  border-radius: 8px;
  padding-bottom: 40px;
}

.success-custom-modal .modal-title {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-top: 10px;
}

.success-custom-modal .modal-title-text {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
}

.success-custom-modal .modal-title-msg {
  color: #828282;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
}

.send-invite-info,
.success-custom-modal .modal-title-info {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background-color: rgba(47, 225, 146, 0.08);
  margin-top: 10px;
}

.send-invite-info .info,
.success-custom-modal .modal-title-info .info {
  color: #182838;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}