/* =Import Required styles
========================================================================================*/
@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800&display=swap);


@font-face {
  font-family: 'AvenirLTStdBlack';
  src: url('fonts/AvenirLTStdBlack.eot');
  src: url('fonts/AvenirLTStdBlack.eot') format('embedded-opentype'),
       url('fonts/AvenirLTStdBlack.woff2') format('woff2'),
       url('fonts/AvenirLTStdBlack.woff') format('woff'),
       url('fonts/AvenirLTStdBlack.ttf') format('truetype'),
       url('fonts/AvenirLTStdBlack.svg#AvenirLTStdBlack') format('svg');
}
@font-face {
  font-family: 'AvenirLTStdBook';
  src: url('fonts/AvenirLTStdBook.eot');
  src: url('fonts/AvenirLTStdBook.eot') format('embedded-opentype'),
       url('fonts/AvenirLTStdBook.woff2') format('woff2'),
       url('fonts/AvenirLTStdBook.woff') format('woff'),
       url('fonts/AvenirLTStdBook.ttf') format('truetype'),
       url('fonts/AvenirLTStdBook.svg#AvenirLTStdBook') format('svg');
}
@font-face {
  font-family: 'AvenirLTStdMedium';
  src: url('fonts/AvenirLTStdMedium.eot');
  src: url('fonts/AvenirLTStdMedium.eot') format('embedded-opentype'),
       url('fonts/AvenirLTStdMedium.woff2') format('woff2'),
       url('fonts/AvenirLTStdMedium.woff') format('woff'),
       url('fonts/AvenirLTStdMedium.ttf') format('truetype'),
       url('fonts/AvenirLTStdMedium.svg#AvenirLTStdMedium') format('svg');
}



.preloader-box {
  position: fixed;
  background: #fff;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 999;
}

.preloader-box .default_loader{
  width: 60px;
  height: 60px;
}

.preloader-box .Guardian_Loader{
  width: 135px;
}

.preloader-box2 {
  position: absolute;
  background: #fff;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  min-height: 120px;
  /* z-index: 9999999; */
  z-index: 99;
}


.preloader-box2 .default_loader{
  width: 60px;
  height: 60px;
}

.preloader-box2 .Guardian_Loader{
  width: 135px;
  transform: translate(-50%, -50%);
}

.preloader,.preloader2 {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  height: 4.25em;
  margin-left: -1.875em;
  margin-top: -2.125em;
  /* transform: translate(-50%, -50%); */
}
.AIloader{
  /* position: absolute; */
  /* top: 50%; */
  /* left: 30%; */
  /* display: block; */
  /* width: 100%; */
  /* height: 4.25em; */
  /* margin-left: -1.875em; */
  /* margin-top: -2.125em; */
   position: fixed;
  /* background: #fff; */
  /* left: 0; */
  /* width: 100%; */
  top: 85px;
  height: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.preloader .slice,.preloader2 .slice {
  border-top: 1.125em solid transparent;
  border-right: none;
  border-bottom: 1em solid transparent;
  border-left: 1.875em solid #46b4fe;
  position: absolute;
  top: 0px;
  left: 50%;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
  border-radius: 3px 3px 0 0;
}



/* =Box Sizing 
========================================================================================*/
* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -o-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box; }
*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -o-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box; }
input[type="text"], input[type="password"], input[type="email"], input[type="tel"], input[type="search"], textarea, select, input[type="button"], input[type="submit"], button { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -o-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box;  }


/* =Deafult Tag & General Classes
========================================================================================*/
html, body { -webkit-font-smoothing:antialiased; -moz-font-smoothing:antialiased; -ms-font-smoothing:antialiased; font-smoothing:antialiased; /* Fix for webkit rendering */ -webkit-text-size-adjust:100%;   }
body { font-size:16px; font-family: 'Nunito', sans-serif; color:#000; background:#f8f8f8;  }
img { vertical-align:top; border:0; max-width: 100%; }
a, input[type="button"], input[type="submit"], button{ 
    transition: all 300ms ease-in-out;
}
.allanim, #mainmenu li a:after  { -webkit-transition:all 550ms cubic-bezier(0.77,0,0.175,1); transition:all 550ms cubic-bezier(0.77,0,0.175,1); }
a { color:#46b4fe; text-decoration:none;}
a:hover { text-decoration:none; color:#333; }

a.link2 { color:#000; text-decoration:none;}
a.link2:hover { text-decoration:none; color:#46b4fe; }



@media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
	  a:active{ background-color:transparent;}
}

.images-placeholder { background-position: center; background-repeat: no-repeat; background-size: cover; }
.images-placeholder .img-background { display: none ;}
.back-link { font-size: 26px; color: #000; }
.back-link:hover{ color: #46b4fe; }
.back-link .fa-arrow-left { margin-right: 14px; font-size: 22px; margin-left: 5px;}
figure{ margin: 0; }
.text-danger { color: #ff3b3b !important;}
.text-success { color: #12c475 !important; }

.success-bg{ background: #12c475 !important; color:#fff !important;}
.failed-bg{  background: #e87a7a !important; color:#fff !important;}
.completed-bg {
    background: #12c475 !important;
    color: #fff !important;
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
}
.alert {  border-radius: 13.5px;}
.alert-danger { background: rgba(232, 122, 122, 0.10); color: #ff3b3b;  }
.alert-success { background: rgba(18, 196, 117, 0.10); color: #12c475; }

.alert-panel{ border-radius: 6px; font-size: 17px; position: absolute; top:85px; left: 0; right: 0; margin: 0 auto; max-width: 90%; padding: 18px 20px; z-index: 10; }
.alert-panel.alert-success{ background: #d5edda; color: #06834c; border-color: #12c475;}
.alert-panel.alert-danger{ background:#ffdcdc; color: #ff3b3b; border-color: #ff3b3b;}

.table td, .table th { font-size: 18px; border-top:0;     white-space: nowrap; }
.table-deactive, .table-deactive>td, .table-deactive>th {  background-color: transparent;color: rgba(0,0,0,0.20)}

.table th { color: #828282; font-weight: 600; }
.table th .fa-sort{ color: #b2b2b2; font-size: 14px; margin-left: 5px; }
.table th.ascending .fa-sort { color: #46b4fe;}
.table td { border-bottom:  1px solid #d8d8d8}
.table-lg.table td, .table-lg.table th { padding: 20px 5px;}
.btn{ font-weight: bold; border-radius: 26px;   border:0; color: #fff; padding: 10px 20px;  line-height: 24px;
 background: -webkit-linear-gradient(left, #00f1fe 0%, #4facfe 50%, #00f1fe 100%)!important;
background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%);    background-size: 300% 100%; transition: all 300ms ease-in-out; }
:focus{ outline: none;  }
.btn.focus, .btn:focus{ box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.05); }
.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus { box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.05);}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle{
background-color: transparent; color: inherit;  border-color: inherit; }
.form-control:focus{ box-shadow: none; } 
select.form-control{  text-indent: -1.1px; font-family: 'Nunito', sans-serif;}
select option { font-family: 'Nunito', sans-serif; }

.btn-outline-primary { color: #46b4fe; border: solid 1px #0079fd; background-image: none;  }
.btn-outline-danger { color: #FF2C2C; border: solid 1px #FF2C2C; background-image: none;  }
.btn:hover{color: #fff;  border-color: #46b4fe; outline: none; background-position: 100% 0; }
.btn-outline-primary:hover{ color: #fff;  border-color: #46b4fe; background: #46b4fe; }
.btn-outline-danger:hover{ color: #fff;  border-color: #FF2C2C; background: #FF2C2C; }
.pagination{ border-radius: 0; }
.pagination .page-link{ font-size: 12px; color:#000; font-weight: 600; border-radius: 7px;  margin: 0 6px; border:0; outline: none; box-shadow: none; transition: all 300ms ease-in-out; background: -webkit-linear-gradient(left, #00f1fe 0%, #4facfe 50%, #ebebeb 50%, #ebebeb 100%);
background: -ms-linear-gradient(left, #00f1fe 0%, #4facfe 50%, #ebebeb 50%, #ebebeb 100%);
background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #ebebeb 50%, #ebebeb 100%);  background-size: 200% 100% ; background-position: right top; }
.pagination .page-link:hover,.pagination .active .page-link { background-position: left top; color: #fff; }
.pagination .page-item:first-child .page-link,.pagination .page-item:last-child .page-link{ border-radius: 50%; width: 26px; height: 26px; text-align: center; display: flex; align-items: center; justify-content: center; }
.pagination .page-item.disabled .page-link{ opacity: 0.5;  background-color: #ebebeb; }
.pagination .page-item .fas { font-size: 8px;}



.container-fluid { padding-left: 45px; padding-right: 45px;}
.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6{  font-weight: 800; }
.font-weight-bold { font-weight: 800!important; }
.font-weight-semibold {  font-weight: 600!important;  }
.custom-select { font-size: 16px; font-weight: 700; color: #000; border-radius: 9px; border-color: #959595; padding: 6px 28px 6px 15px; 
  background:url(images/down-arrow.svg) no-repeat 98% center; background-size: 25px 29px; max-width: 140px; margin-left: auto;     display: flex; }
.custom-select:focus{ box-shadow: none; border-color: inherit}

table .edit-icon { color: #9a9a9a; font-size: 14px; }
table .edit-icon:hover{ color: #46b4fe;}
table.dataTable thead .sorting:after { content: "\f0dc";  font-family:'Font Awesome 5 Free'; color:#b2b2b2 ; display: inline-block; vertical-align: top; padding-left: 10px; cursor: pointer;
     
}
table.dataTable thead .sorting_asc:after {
    content: "\f0de"; font-family:'Font Awesome 5 Free'; display: inline-block; vertical-align: top; padding-left: 10px; color: #000; cursor: pointer;
}
table.dataTable thead .sorting_desc:after {
    content: "\f0dd";  font-family:'Font Awesome 5 Free'; display: inline-block; vertical-align: top; padding-left: 10px; color: #000; cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate {
    float: right; text-align: right; padding: 20px 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box; display: inline-block; padding: .5rem .75rem; font-size: 12px; margin-left:6px; line-height: 1.25; text-align: center; text-decoration: none !important; color: #333 ; border-radius: 7px; background: #ebebeb;  vertical-align: top;
    background: -webkit-linear-gradient(left, #00f1fe 0%, #4facfe 50%, #ebebeb 50%, #ebebeb 100%);
background: -ms-linear-gradient(left, #00f1fe 0%, #4facfe 50%, #ebebeb 50%, #ebebeb 100%);
background: linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #ebebeb 50%, #ebebeb 100%);  background-size: 200% 100% ; background-position: right top;  font-weight: 600; }
.dataTables_wrapper .dataTables_paginate .paginate_button:hover:not(.disabled){
       background-position: left top; color: #fff; 
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background-position: left top; color: #fff; 
}
.dataTables_wrapper .dataTables_paginate .paginate_button.next,.dataTables_wrapper .dataTables_paginate .paginate_button.previous {
   font-size: 0; border-radius: 50%; width: 26px; height: 26px; text-align: center; display: inline-flex; align-items: center; justify-content: center;  margin-top: 3px; }
.dataTables_wrapper .dataTables_paginate .paginate_button.next:before,.dataTables_wrapper .dataTables_paginate .paginate_button.previous:before{    content: "\f060";     -webkit-font-smoothing: antialiased; display: inline-block; font-style: normal; font-variant: normal; text-rendering: auto; line-height: 1;    font-family: 'Font Awesome 5 Free';  font-weight: 900;  font-size: 9px;
} 
.dataTables_wrapper .dataTables_paginate .paginate_button.next:before { content: "\f061";}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default; opacity: 0.5
}
.dataTables_wrapper .dataTables_paginate .paginate_button:active { outline: none; }

table.dataTable thead .sorting-disable:after{ display: none !important;}





/*============*/

.header{ box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06); padding: 8px 0;}
.header a.navbar-brand{  max-width: 150px;cursor: pointer; }
.header .navbar-light .navbar-nav .nav-link  { color:#000; font-size: 16px; font-weight: 800; }
.header .navbar-light .navbar-nav .nav-link:hover  { color:#46b4fe; }
.header .navbar-light .navbar-nav li.active .nav-link { color:#46b4fe; }
.header-nav > ul > li { margin-left: 20px;}
.header .user-info .user-name { display: block; font-size: 13px; color: #828282;}
.header .user-info .dropdown-toggle:after{ position: relative; top:-9px; margin-left: 10px; }
.header .sub-menu .dropdown-menu, .header .user-info .dropdown-menu
{ box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.12); border: 0; }
.header .user-info .dropdown-item { font-size: 13px; font-weight: bold; padding: 6px 15px; }
.header .user-info .dropdown-item > i { color: #9a9a9a; margin-right: 8px; transition: all 300ms ease-in-out; }
.header .user-info .dropdown-item.active,.header .user-info  .dropdown-item:active { background: #46b4fe}
.header .user-info .dropdown-item.active i,.header .user-info  .dropdown-item:active i {color: #fff; }

.search-box { max-width:500px;}
.form-group.has-search { position: relative; }
.search-box .has-search .form-control { padding-right: 50px; border-radius:26px; border: none; color:#bcbcbc; font-size:16px; height: 52px; box-shadow:  0 7px 28px 0 rgba(0, 0, 0, 0.04); padding-left: 25px; } 
.search-box .has-search .search-icon { position: absolute; z-index:1; display: block; width: 50px; height: 52px;text-align: center; color: #000; top:0;  right: 10px; box-shadow:none; background: none; border-radius: 0; }
.search-box .has-search .search-icon:focus,.search-box .has-search .search-icon:hover { box-shadow: none !important; color: #9c9c9c; }
.search-box .has-search .form-control::placeholder { color: #bcbcbc; } 

.preference-btn .preference-title {  margin-right: -195px; margin-top:-30px; color: #000; font-size: 16px; font-weight: 700; }
.preference-btn .preference-link:first-child{ margin-top:80px;margin-right: -110px; border-radius:100px 0 0 100px; margin-right: -1px; }
.preference-btn .preference-link:last-child{margin-top:80px; border-radius:0 100px 100px 0; margin-left: -1px; margin-right: -69px; }
.preference-btn .preference-link { font-size: 16px; font-weight: 700; border: solid 1px #46b4fe;   border-radius: 100px ; color:#000; height: 52px; width: 152px; display: flex; justify-content: center; align-items: center; background: #fff; cursor: pointer; }
.preference-btn .preference-link.active{ box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.04); background-image: linear-gradient(to left, #00f2fe, #03effe 2%, #24d2fe 29%, #3cbdfe 55%, #4ab0fe 80%, #4facfe); color: #fff; border-color: transparent;border: 0px; }
.page-title { font-size:26px;text-transform: capitalize;}

.card {box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.03); border-radius: 12px;}
.card-box.avg-score { min-height: 130px; }
.card-print {box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.03); border-radius: 12px; margin: 2em;
word-wrap: break-word;background-color: white}
.card-print .students-name {
  border-bottom: 2px solid #c1c1c1;padding-bottom: 10px;margin-left: 10px;justify-content: space-between;
    display: flex;
}
.card-print .students-name span{
  color: #908e8e;
}
.inner-content .value{ font-size:44px; white-space: nowrap; }
.inner-content .value > em{ color: #474747; font-size: 30px; font-style: normal; }
.inner-content > ul { margin:0; padding:0; list-style:none; }
.inner-content > ul > li { position: relative; padding-left: 16px; font-size: 18px;}
.inner-content > ul > li:before{ content: ''; position: absolute; left: 0; background: #f98283; width: 8px; height: 8px; top: 8px; }
.inner-content > ul > li.free:before , .inner-content ul > li.stu-active:before { background: #47b5fc; }
.student-info h2 { font-size: 50px; margin-bottom: 0; }
.student-info span { text-transform:uppercase; color:#333; font-weight:800; }

.chart-bullet{ margin: 0; padding: 0; list-style: none; }
.chart-bullet > li { position: relative; padding-left: 15px; }
.chart-bullet > li:before{ content: ""; width: 8px; height: 8px;  background: #f98484; display: inline-block; vertical-align: top; position: absolute; left: 0; top: 8px; }
.chart-bullet > li:nth-child(2):before{background-color: #46b4fe}
.chart-bullet > li:nth-child(3):before{background-color: #aed9f7}

.judicial-chart-bullet{ margin: 0; padding: 0; list-style: none; }
.judicial-chart-bullet > li { position: relative; padding-left: 15px; }
.judicial-chart-bullet > li:before{ content: ""; width: 8px; height: 8px;  background:#46b4fe; display: inline-block; vertical-align: top; position: absolute; left: 0; top: 8px; }
.judicial-chart-bullet > li:nth-child(2):before{background-color: #f98484}

.announcements-card { box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.17); background-color: #f8f8f8; border-radius: 0; }
.announcements-card .date{ font-size:12px; color: #474747; display: block; margin-bottom: 5px; }
.announcements-card p{ color:#828282;}
.announcements-card .images-placeholder{ box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.17); padding-top: 61.983471%; background-color:#eee ; }

.view-all { display: inline-flex; align-items: center; transition: all 200ms ease-in-out;     padding-top: 5px; }
.view-all:hover .angle-right{ border-image-source: linear-gradient(to right, #000, #000);}
.view-all .angle-right{ display: inline-block; vertical-align: top; width: 7px; height: 7px; border-style: solid; border-width:2px 2px 0 0; border-image-source: linear-gradient(to right, #00f2fe, #03effe 2%, #24d2fe 29%, #3cbdfe 55%, #4ab0fe 80%, #4facfe); border-image-slice: 1; transform: rotate(45deg);     margin-left: 6px;}

.site-tab .nav-item { margin-bottom: 0; }
.site-tab .nav-link{ border: 0; font-weight: 600; color: #828282; position: relative; }
.site-tab .nav-link:after{ content:""; border-radius: 2.5px; background-image: linear-gradient(to right, #00f2fe, #03effe 2%, #24d2fe 29%, #3cbdfe 55%, #4ab0fe 80%, #4facfe); width: 0; margin: 0 auto; height: 5px; display: block; margin-top: 10px;transition: all 200ms ease-in-out; }
.site-tab .nav-link.active:after {width: 100%; }
.site-tab .nav-link.active,.site-tab .nav-link:hover{ color: #46b4fe;}
.site-tab .nav-link.active em{  color: #fff; background-image: linear-gradient(to right, #00f2fe, #03effe 2%, #24d2fe 29%, #3cbdfe 55%, #4ab0fe 80%, #4facfe);}
.site-tab .nav-link em{ font-size: 12px; border-radius: 50%; font-style: normal; background: #ececec; width: 24px; height: 24px; display: inline-flex;
  justify-content: center; align-items: center; font-weight: 900; }
.vertical-tab { display: flex; flex-wrap: wrap; }
.vertical-tab .tab-content{ width: calc(100% - 215px); background: #fff; box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06); padding: 36px 20px 150px 20px; }

.site-tab-vertical { position: relative; z-index: 1;  max-width: 210px; width: 100%; }
.site-tab-vertical .nav-link{ font-size: 16px; color: #b8b8b8; border-left: 2px solid transparent; font-weight: bold; padding: 15px 16px; }
.site-tab-vertical .nav-link.active { background: #fff; border-color:#46b4fe; color: #46b4fe; }

.task-box { border-radius: 9px; background: #f8f8f8; padding:21px 15px 21px 15px; display: flex; flex-wrap: wrap; justify-content: space-between ;}
.task-box h6{ font-weight: 600; font-size: 17px; }
.custom-checkbox { margin: 0;}
.custom-checkbox .checkmark {  width: 29px; height: 29px; border-radius: 4px; border: solid 1px #bcbcbc; display: block ; cursor: pointer; position: relative; }
.custom-checkbox .checkmark:after{ content: "" ; width: 18px; height: 8px; border-bottom: 3px solid #46b4fe; border-left: 3px solid #46b4fe; display: block; 
 transform: rotate(-45deg); position: absolute; top: 8px; left: 5px; opacity:0; transition: all 300ms ease-in-out; }

.custom-checkbox input[type=checkbox]{ opacity: 0; position: absolute; cursor: pointer; }
.custom-checkbox input[type=checkbox]:checked ~ .checkmark{ background: #46b4fe; border-color: #46b4fe;  }
.custom-checkbox input[type=checkbox]:checked ~ .checkmark:after {  opacity: 1; border-color: #fff; }
.task-box { margin-bottom: 14px; position: relative; }
.alert-danger-dot {  width: 6px; height: 6px; box-shadow: 0 2px 4px 0 rgba(255, 59, 59, 0.23); background-color: #ff3b3b; border-radius: 50%; position: absolute; top:8px; right:8px; }
.alert-green-dot { width: 6px; height: 6px; box-shadow: 0 2px 4px 0 rgba(47, 223, 144, 0.23); background-color: #06834c; border-radius: 50%; position: absolute; top:8px; right:8px; }
.alert-blue-dot{ width: 6px; height: 6px; box-shadow: 0 2px 4px 0 rgba(70, 180, 254, 0.23); background-color: #46b4fe; border-radius: 50%; position: absolute; top:8px; right:8px; }
.alert-orange-dot{ width: 6px; height: 6px; box-shadow: 0 2px 4px 0 rgba(255, 165, 0, 0.23); background-color: #FFA500; border-radius: 50%; position: absolute; top:8px; right:8px; }
.task-box .course-name { position: relative; padding: 0 8px; }
.task-box .course-name + .course-name { padding-left: 0;}
.task-box .course-name .custom-checkbox { position: absolute; left: 0; top:5px;}
.task-box .course-name h6 { font-size: 17px; font-weight: 600; margin-bottom: 0; }
.task-box .course-name .due-date { font-size: 14px; display: inline-block; vertical-align: top;   color: #000000;}
.task-box .course-name .due-date > em{ font-style: normal; color: #828282; margin-right: 2px; display: inline-block; vertical-align: top; }
.task-box .studentinfo {display: flex;  align-items: center ; padding: 0 5px 0 5px;}
.task-box .studentinfo > .student-name{ font-size: 14px;   font-weight: 600;text-transform: capitalize; margin-left: 10px; max-width: calc(100% - 48px); }
.un-Assign-alert { font-size: 12px; color: #e87a7a; position: absolute; right: 12px; top:12px; font-weight: bold; text-transform: uppercase; }




.actions-button{ display: flex; }
.actions-button > span{ width: 34px; height: 34px; border-radius: 50%; background: #fff; margin-left: 8px; box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);     display: flex; align-items: center; justify-content: center; cursor: pointer; transition: all 300ms ease-in-out; }
.actions-button > span:first-child{ margin-left: 0; }
.actions-button > span:hover{ background:#46b4fe; }
.actions-button > span:hover .fa-times { color: #fff;}
.actions-button > span:hover .fa-pencil-alt { color: #fff;}
.actions-button > span:hover .fa-plus { color: #fff;}
.actions-button > span:hover .select-icon { border-image-source: linear-gradient(to right, #fff, #fff 100%); }
.actions-button > span .fa-times{ color: #e87a7a; transition: all 300ms ease-in-out;font-size: 18px;}
.actions-button > span .fa-pencil-alt{ color: #46b4fe; transition: all 300ms ease-in-out;}
.actions-button > span .fa-plus{ color: #46b4fe; transition: all 300ms ease-in-out;}
.actions-button > span .select-icon{ width: 16px; height: 10px; display: block;  border-style: solid; border-width:3px; border-image-source: linear-gradient(to right, #00f2fe, #03effe 2%, #24d2fe 29%, #3cbdfe 55%, #4ab0fe 80%, #4facfe); border-image-slice: 1; border-top: 0; border-right: 0; transform: rotate(-48deg); position: relative; top: -2px; background-size: 300% 100%; }
.course-box { border-radius: 12px; background-color: #f8f8f8; margin-bottom: 25px; padding: 20px 24px; position: relative;}   
.course-box .d-flex{ align-items: flex-end; flex-wrap: wrap; width: 100%;}
.course-box .course-info { color: #474747; }
.course-box .course-info .course-name { font-size: 17px; font-weight: 600; color: #000;     display: block;  }
.course-box .course-date{  font-size: 14px; color: #828282; max-width: 110px; width: 110px;   }
.course-box .course-date > em{  font-style: normal; color: #000; display: block; }
.course-box .alert{  margin-bottom:10px; padding:4px 11px; font-size: 14px; width: auto; display: inline-block; vertical-align: top;  }
.course-box .studentinfo {display: flex;  align-items: center ;}
.course-box .studentinfo > .student-name{ font-size: 14px;   font-weight: 600; text-transform: capitalize; margin-left: 10px; max-width: calc(100% - 48px); }
.course-box .course-box-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-note-form .form-control:not(textarea){ height: 50px;}
.add-note-form .form-control {  border-radius: 4px; border: solid 1px #bcbcbc; padding: 10px 20px;}
.add-note-form .custom-switch .custom-control-label { color: #474747;}
.add-note-form .custom-switch .custom-control-label::before { background:#bcbcbc; border:0; box-shadow: none; }
.add-note-form .custom-switch .custom-control-label::after { background: #fff; }
.add-note-form .custom-switch .custom-control-input:checked~.custom-control-label::before { background: #46b4fe}
.add-note-form .btn{ padding: 5px 18px; }

.note-box{   border-bottom: solid 1px #eee; padding:20px 0 15px; }
.note-box:last-child { margin-bottom: 10px; }
.note-box .note-type { font-size: 18px; font-weight: 700; margin-bottom: 0; }
.note-box .note-date{  font-size: 13px; color: #959595; font-weight: 800; display: block; text-align: right; }
.note-box .note-author { font-weight: 300; font-size: 13px; }
.note-box .note-text{ font-size: 16px; color: #828282; padding-bottom: 5px; }
.note-box .alert{  margin-bottom:10px; padding:6px 11px 6px; font-size: 14px; width: auto;  vertical-align: baseline; font-weight: 600; position: relative; }
.confidential-alert .fa-lock { font-size: 12px; vertical-align: top; margin-top: 2px; margin-right: 5px;}

.note-box .contract-name { font-size: 18px; font-weight: 700; margin-bottom: 0; display: flex;}
.note-box .contract-label{ font-size: 16px; color: #000000; padding-bottom: 5px;margin-bottom: 0!important;width: 100%!important; font-weight: 600; }
.note-box .contract-text{ font-size: 16px; color: #828282; padding-bottom: 5px; margin-bottom: 0!important;width: 100%!important;}
.note-box .cont-row{ display: flex!important;flex-wrap: wrap!important; border-bottom: solid 1px rgba(149, 149, 149, 0.5);
    border-radius: 0;margin-bottom: 10px;}

.print-contract{text-decoration: underline;
    cursor: pointer;}
.modal-section .addnew-button { font-weight: 500; font-size: 16px; border-radius: 25px; border: 1px solid #0079fd; padding: 12px 30px; display: inline-flex;}
.modal-section .addnew-button:hover{ color: #fff; background: #46b4fe; border-color: #46b4fe; }
.modal-dialog { width: 600px; max-width:calc(100% - 16px); box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.03); background-color: #fff; }
.modal-content .close{ font-size: 35px; padding: 0;margin: -12px -4px 0 auto;     outline: none; }
.modal-content .modal-title{ font-size: 24px; font-weight: bold;     text-align: center  }
.modal-content .btn{ min-width: 162px; height: 44px;  }
.modal-content .btn:hover{color: #fff;     background-position: 100% 0; }
.modal-content .form-group{ margin-bottom: 22px; position: relative; }
.modal-content .form-group .form-control-icon { position: absolute; right: 8px; top:13px; color: #9a9a9a; pointer-events: none; }
.modal-content .form-control { height: 40px; border: 0;   border-bottom: solid 1px rgba(149, 149, 149, 0.5); border-radius: 0;  -webkit-appearance: none; 
 padding:6px 30px 6px 0px; box-shadow: none;  appearance: none; -moz-appearance: none; -ms-appearance: none; color: #000; }
.modal-content .modal-tab > li > a{ font-size: 15px; color: #000; display:inline-block; vertical-align: top; }
.modal-content .modal-tab > li > a.active { color: #46b4fe; }
.modal-content .modal-tab > li > a.active .radio-circle{ border-color: #46b4fe}
.modal-content .modal-tab > li > a.active .radio-circle:after{transform: scale(1);}


.radio-circle { width: 22px;height: 22px; border: 1px solid #bcbcbc; display:inline-block; vertical-align: top; border-radius: 50%; margin-right: 10px; position: relative; transition: all 300ms ease-in-out; }
.radio-circle:after{ content: ""; width: 12px; height: 12px; display:inline-block; vertical-align: top; border-radius: 50%; position: absolute; background: #46b4fe; top:0; left: 0; right: 0; bottom: 0; margin: auto;    transform: scale(0); transition: all 300ms ease-in-out;  }

.status-label{ color: #474747; }


.ui-datepicker { background: #46b4fe; width:300px; max-width: 100%; padding:0; display: none; border: solid 1px rgba(149, 149, 149, 0.5); box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.05); }
.ui-datepicker-calendar { width: 100%; }
.ui-datepicker-calendar td,.ui-datepicker-calendar th{ text-align: center;}
.ui-datepicker-calendar td {background: #fff; }

.ui-datepicker-calendar td a{ display:inline-flex; width: 30px; height: 30px; color: rgba(0, 0, 0, 0.7); justify-content: center; align-items: center; }
.ui-datepicker-calendar td a:hover{ background: #7f7f7f; color: #fff; }
.ui-datepicker-calendar td.ui-datepicker-today a {background: #46b4fe; display:inline-flex; color: #fff; width: 30px; height: 30px;  justify-content: center; align-items: center; }
.ui-datepicker-calendar td.ui-datepicker-current-day a{ background: #7f7f7f; color: #fff; }
.ui-datepicker-header { display:flex; justify-content: space-between; padding:5px 5px 5px; color: #828282; font-weight: 700; }
.ui-datepicker-header a{color:#46b4fe !important; cursor: pointer; width: 28px; height: 28px; display: flex; justify-content: center; align-items: center; padding-top: 7px;}

.ui-datepicker-header a.ui-datepicker-prev .ui-icon{ font-size: 0; width: 10px; height: 10px; border:2px solid #fff; display: block; border-top: 0; border-right: 0; border-radius: 1px; transform: rotate(45deg); transition: all 300ms ease-in-out; }
.ui-datepicker-header a.ui-datepicker-next  .ui-icon {font-size: 0; width: 10px; height: 10px; border:2px solid #fff; display: block; border-bottom: 0; border-left: 0; border-radius: 1px; transform: rotate(45deg); transition: all 300ms ease-in-out;}
.ui-datepicker-header a:hover .ui-icon { border-color: #000;}
.ui-datepicker-header a:hover{color: #333 !important; }
.ui-datepicker-next { order:3;}
.ui-datepicker .ui-datepicker-title { margin-top: .4em; margin-bottom: .3em; font-weight: bold; color: #fff; }
.ui-datepicker th { padding: 1em 0; color: #fff; font-size: 13px; font-weight: 600; border: none; border-top:1px solid rgba(255, 255, 255, 0.3); }
.ui-datepicker-prev span:before { content: "\f107"; font-family: FontAwesome; position: relative; }
.ui-datepicker-header .ui-state-hover { background: transparent; border-color: transparent; cursor: pointer; border-radius: 0; }

.login-page { background:#f8f8f8 url(images/login-bg.png) no-repeat right bottom; height: 100vh; display: flex; align-items: center; }
.login-box-main { max-width: 300px; width: 100%; }
.login-box-main .login-logo{ margin-bottom: 80px}
.login-box-main h5{ font-size: 26px; }
.login-box-main .login-form .form-control{ height: 40px; border: 0; border-bottom: solid 1px rgba(149, 149, 149, 0.5); border-radius: 0; -webkit-appearance: none; padding: 6px 30px 6px 0px; box-shadow: none; color: #000; background-color: transparent; font-size: 16px;  }
.login-box-main .login-form .forgot-password{ display:table; margin-left: auto; color: #474747; font-size: 14px; font-weight: 600; margin-bottom: 25px; }
.login-box-main .login-form .forgot-password:hover{ text-decoration: underline; color: #46b4fe; }
.login-box-main .login-form .btn-login{ width: 100%; }

.table  .status-dropdown{ font-size: 14px; border-color: #e6e6e6; border-radius: 4px; height: 28px; line-height: 1; margin: 0;  font-weight: 600; }
.top-table-search .back-link{ font-size: 18px;display: inline-flex;}
.top-table-search .back-link img{ font-size: 18px; margin-right: 5px;}
.top-table-search .back-link .fa-arrow-left{ font-size: 18px; margin-right: 5px;}
.top-table-search .btn{ padding: 9.5px 30px; margin-left: 10px; }

.enrollment-page .top-heading-row .btn{     min-width: 114px; padding: 5px 20px; font-weight: 500; font-family: 'AvenirLTStdMedium'; }
.enrollment-page .top-heading-row .btn-outline-primary{ margin-right: 5px;}

.enrollment-form .form-group { position: relative; margin-bottom: 20px; }
.enrollment-form .form-group .placeholder-label{ color:#959595; font-size: 16px; font-family: 'AvenirLTStdMedium'; font-weight: 500; position: absolute; top: 15px; transition: all 300ms ease-in-out; pointer-events: none; white-space: nowrap; width: 100%; overflow: hidden; }
.enrollment-form .form-group .form-control:focus ~ .placeholder-label,.enrollment-form .form-group .form-control.not-empty ~ .placeholder-label{  font-size: 14px!important; top: -5px; }
.enrollment-form .form-group .form-control { min-height: 60px; border: 0; border-bottom: solid 1px rgba(149, 149, 149, 0.5); border-radius: 0; -webkit-appearance: none; padding: 8px 30px 8px 0px; box-shadow: none; appearance: none; -moz-appearance: none; -ms-appearance: none; color: #000; font-family: 'AvenirLTStdMedium'; font-size: 1.05rem; }
.enrollment-form .form-group .textarea-box{}
.enrollment-form .form-group .form-control.error{ border-color:#ff3b3b; }
.enrollment-form .form-group .error-text{ display: block; text-align: right;  color:#ff3b3b; font-size: 14px; font-family: 'AvenirLTStdMedium';  }
.enrollment-form .custom-select { margin: 0; width: 100%; max-width: 100% ; height: 55px; border: 0; border-bottom: solid 1px rgba(149, 149, 149, 0.5); border-radius: 0; padding: 8px 30px 8px 0px; box-shadow: none; font-weight: 500; font-family: 'AvenirLTStdMedium'; }
.enrollment-form .small-info{ font-size: 12px; font-family: 'AvenirLTStdMedium'; font-style: italic; color: #bcbcbc;}
.enrollment-form .phone-code .custom-select{ width: 80px; margin-right: 10px;}
.enrollment-form .delete-contact{ color:#e87a7a; font-size: 14px; font-weight: 800; margin-left: auto; display: table; text-transform: uppercase;  }
.enrollment-form .delete-contact:hover{  color: #000;}
.enrollment-form .add-contact{  color:#46b4fe; font-size: 16px; font-weight: 800;   margin-left: auto; display: table; text-transform: uppercase; }
.enrollment-form .add-contact:hover{ color: #000;}
.enrollment-form .remove-all {  color:#e87a7a; font-size: 16px; font-weight: 800;   margin-left: auto; display: table; text-transform: uppercase; }
.enrollment-form .remove-all:hover{ color: #000;}

.enrollment-form .custom-control-label {  font-family: 'AvenirLTStdMedium';}
.custom-control-input:focus~.custom-control-label::before{ box-shadow: none;}
.custom-control-input:checked~.custom-control-label::before { background-image: linear-gradient(to right, #00f2fe, #03effe 2%, #24d2fe 29%, #3cbdfe 55%, #4ab0fe 80%, #4facfe); border-color: transparent; }
.enrollment-form .form-group .form-control-icon { position: absolute; right: 0; top: 20px; color: #9a9a9a; pointer-events: none; }
.pagination-button { position: absolute; right: 40px; bottom: 40px; }
.pagination-button .btn{ min-width: 114px; padding: 5px 20px; font-weight: 500; font-family: 'AvenirLTStdMedium'; }
.pagination-button .btn-outline-primary{ margin-right: 5px;}

.students-profile .students-detail .info-label { display: block; font-size: 15px; color: #828282; margin-bottom: 2px; }
.students-profile .students-profile-img{ width: 84px; height: 84px; background-color: #d8d8d8; border-radius: 10%;object-fit: cover; }
.students-profile .students-action-button > .btn {  margin-right: 10px; }
.students-profile .students-action-button > .btn > .action-button-icon {  display: inline-block; vertical-align: top; }
.students-profile .students-action-button > .btn:hover { color: #000; background-color: transparent; border-color: #000; }

.surveys-card{ background: #fff; border-radius: 0 0 11px 11px; box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.06); overflow: hidden; margin-bottom: 30px; width: 200px;  }
.surveys-card:hover .surveys-hover{ opacity: 1;  transform: translateY(0); visibility: visible; }

.surveys-card .surveys-hover{ position: absolute; left: 0; top:0; bottom: 0; right: 0; transform: translateY(-100%); display: flex; flex-wrap: wrap; background: rgba(0, 0, 0, 0.71); padding:5px 18px; justify-content: center;  align-items: center; opacity: 0;
 transition: all 300ms ease-in-out; visibility: hidden;  }
.surveys-card .surveys-hover .btn{ width: 100%; min-width: 0; margin: 4px 0; font-size: 15px; font-weight: 500; padding: 5px 20px; color: #fff; border-color: #fff; height: 34px; }
.surveys-card .surveys-hover .btn:hover{ border-color: #46b4fe;}

.surveys-card .images-placeholder{ height: 124px; box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.17); margin-bottom: 20px; position: relative; }
.surveys-card .surveys-info{  padding: 0 16px 10px;}
.surveys-card .surveys-info .department-name{ font-size: 16px; color: #959595; display: block; }
.surveys-card .surveys-date-box { padding: 10px 16px 15px; border-top: 1px dashed #eaeaea; }
.surveys-card .surveys-date-box .surveys-status{ display: block; font-size: 14px; font-weight: 300; color: #000;  }
.surveys-card .surveys-date-box .surveys-date{ font-size: 15px; font-weight: bold; color: #000;}
.surveys-card .surveys-date-box .surveys-date .fa-calendar-alt { color: #848484; font-size: 13px; margin-right: 5px; }
.surveys-card .surveys-date-box .alert-danger-dot,
.surveys-card .surveys-date-box .alert-orange-dot{ position: static;    display: inline-block; vertical-align: top; margin-top: 5px; margin-left: 2px;}

.survery-question-page .students-profile-img{ width: 50px; height: 50px; border-radius: 10px;}
.survery-question-page .validate-pin-box{  max-width: 260px;  width: 100%; margin:100px auto 0; }
.survery-question-page .validate-pin-box h5{ font-size: 20px; margin-bottom: 25px; font-weight: 600; color: #1a1a1a;}
.survery-question-page .validate-pin-box .text-box{ border-radius: 26px; box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.04); border:0; display: table; margin: 0 auto 45px; padding: 26px 22px; width: 100%;  }
.survery-question-page .validate-pin-box .val-pin{ width: 100%; padding: 13px 20px }
.survery-question-box{ max-width: 910px; margin: 20px auto; width: 100%;  }
.survery-question-box .question-no{ font-size: 16px; font-weight: 600; text-align: left; color: #46b4fe; padding-top: 15px; padding-bottom:15px;  }
.survery-question-box .question-no em{ font-style: normal; font-weight: 900; font-size: 20px; text-decoration: underline; }
.survery-question-box h5{ font-size: 18px; margin-bottom: 31px;     padding-top: 15px;  }
.survery-question-box .answer-options{ margin: 10px 0 20px 0; padding: 0; list-style: none; }
.survery-question-box .answer-options > li{ position: relative; margin-bottom: 12px; }
.survery-question-box .answer-options > li input[type=radio]{ position: absolute; left: 0; top:0; bottom: 0; right: 0; opacity: 0; z-index: 1; width: 100%; height: 100%; cursor: pointer; }
.survery-question-box .answer-options > li > span{ font-size: 16px; font-weight: 600; background: #f8f8f8; display: block; border-radius: 12px; padding: 20px ; transition: all 300ms ease-in-out; max-width: 570px; width: 100%; margin: 0 auto; position: relative; }
.survery-question-box .answer-options > li input[type=radio]:checked + span { color: #fff;  z-index: 1 ;}
.survery-question-box .answer-options > li > span:after { content: ""; background-image: linear-gradient(to right, #00f2fe, #03effe 2%, #24d2fe 29%, #3cbdfe 55%, #4ab0fe 80%, #4facfe); position: absolute; top: 0; bottom: 0; left: 0; right: 0; transform: scale(0); transition: transform 250ms ease-in-out; transform-origin: center center; z-index: -1; border-radius: 10px;}
.survery-question-box .answer-options > li input[type=radio]:checked + span:after {transform: scale(1);  }
.survery-question-box > .card { min-height: 450px;}
.answer-options .answer-box{ width: 145px; height: 145px; border-radius: 12px; background-color: #f8f8f8; display: inline-block; vertical-align: top; padding: 24px 5px 10px; cursor: pointer; margin:  0 6px 15px; }
.answer-options .answer-box .icon{ width: 62px; height: 62px; border-radius: 50%; display: inline-flex; justify-content: center; align-items: center; margin-bottom: 15px; }
.answer-options .answer-box h6{ font-size: 18px; }
.answer-options .answer-yes h6{  color: #12c475; }
.answer-options .answer-box strong{ display: block; font-size: 15px;}
.answer-options .answer-yes .icon{ transform: rotate(180deg);}
.answer-options .answer-no h6{ color: #e87a7a; }


.quantity-btn .numbers-row{ display:inline-flex; }
.quantity-btn .numbers-row .quantity-button{ background-color: #eee; width: 65px; height: 65px; display: inline-flex; justify-content: center; align-items: center; cursor: pointer;  font-size: 0 }
.quantity-btn .numbers-row .number-icon{ order: 2; background: #f8f8f8; border: 0; text-align: center; font-size: 22px; font-weight: 700; width: 140px; }
.quantity-btn .numbers-row .quantity-button.remove{ order: 1; border-radius: 12px 0  0 12px; background:#eee url(images/minus-icon.svg) no-repeat center; }
.quantity-btn .numbers-row .quantity-button.add{ order: 3; border-radius:0 12px 12px 0; background:#eee url(images/plus-icon.svg) no-repeat center; }



.complete-selfie-box{ padding: 60px 0;}
.complete-selfie-box h3{ font-size: 26px; margin-bottom: 60px;}
.complete-selfie-box .selfie-img{ width: 910px; height: 580px; max-width: 100%;  background-color: #000; display: flex; justify-content: center; align-items: center; margin: 0 auto 60px; }
.complete-selfie-box .btn{ min-width: 180px;}
.complete-selfie-box span{ font-size: 16px; font-weight: 600;color: #d9d9d9; padding: 20px 0; }
.admin-page{ padding-top: 100px; }
.admin-page > h1 { font-weight: 600; color: #474747; margin-bottom: 50px; font-size: 35px;  }
.admin-page-link2{ display: flex; flex-wrap: wrap; padding: 0; list-style: none; justify-content: center; max-width: 980px; width: 100%; margin: 0 auto; }
.admin-page-link2 li{ width: 25%;}
.admin-page-link2 li a{ font-size: 24px;  color: #474747; text-align: center; display: block; margin-bottom: 60px; transition: all 300ms ease-in-out; }
.admin-page-link2 .admin-link-icon{ width: 153px; height: 153px; display:inline-flex; background: #fff; border-radius: 30px;  box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.06); justify-content: center; align-items: center; margin-bottom: 22px; transition: all 300ms ease-in-out; position: relative; overflow: hidden; }
.admin-page-link2 .admin-link-icon:before{ content: ""; background-image: linear-gradient(to right, #00f2fe, #03effe 2%, #24d2fe 29%, #3cbdfe 55%, #4ab0fe 80%, #4facfe);position: absolute; top: 0; bottom: 0; left: 0; right: 0;transform:scale(0); transition:transform 250ms ease-in-out; transform-origin:center center; z-index:0; border-radius: 30px;  }
.admin-page-link2 .admin-link-icon svg{ position: relative; z-index: 1; transition: all 300ms ease-in-out;}
.admin-page-link2 .admin-link-icon path{  transition: all 300ms ease-in-out; }
.admin-page-link2 li a:hover svg path{ fill:#fff;}
.admin-page-link2 li a:hover .admin-link-icon{   box-shadow: 0 9px 15px 0 rgba(79, 172, 254, 0.25);}
.admin-page-link2 li a:hover .admin-link-icon:before{transform: scale(1); }
.deactivate-link{ text-transform: uppercase;  color: #e87a7a; font-size: 16px; font-weight: 700; display: inline-block; margin-bottom: 10px; border: solid 1px #e87a7a; padding: 10px 20px;border-radius: 25px; box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.05);cursor: pointer;}
.reactivate-link{ text-transform: uppercase;  color: #46b4fe; font-size: 16px; font-weight: 700; display: inline-block; margin-bottom: 10px;border: solid 1px #46b4fe; padding: 10px 20px;border-radius: 25px; box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.05);cursor: pointer;}
.deactivate-model h4.modal-title{ color:#828282; font-size: 18px;}
.deactivate-model h4.modal-title strong{ color:#000;     display: inline-block; vertical-align: top}
.contract-page .add-contact{ padding-top: 22px }
.multiple-select select { position: absolute!important; bottom: 0; left: 50%; display: block!important; width: .5px!important; height: 100%!important; padding: 0!important; opacity: 0!important; border: none; z-index: 0!important; }
.multiple-select .btn{ background: transparent url(images/down-arrow.svg) no-repeat 98% center !important;  border-radius: 0; border-bottom: solid 1px rgba(149, 149, 149, 0.5); width: 100%; color: #000; text-align: left; height: 55px; padding: 8px 30px 8px 0px; font-weight: 500; font-family: 'AvenirLTStdMedium'; box-shadow: none !important; }
.multiple-select .dropdown-toggle:after{ display: none; }
.multiple-select .dropdown-menu{ width: 100%; box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.03);}
.multiple-select .dropdown-menu .dropdown-menu.inner{ position: static; float: none; border: 0; padding: 0; margin: 0; border-radius: 0; -webkit-box-shadow: none; box-shadow: none;  }
.multiple-select .dropdown-menu.inner .dropdown-item{ font-size: 16px; font-weight: 500; font-family: 'AvenirLTStdMedium'; padding:8px 46px 8px 24px; position: relative; color: #000; }
.multiple-select .dropdown-menu.inner .dropdown-item.selected{color: #000;}
.multiple-select .dropdown-menu.inner .dropdown-item.selected:after{ opacity: 1; }
.multiple-select .dropdown-menu.inner .dropdown-item:after{ content:""; background: url(images/tick-gradient.svg) no-repeat center; width: 22px; height: 22px; display: block; position: absolute; right: 15px; top: 9px; opacity: 0; transition: all 300ms ease-in-out; }
.multiple-select .dropdown-menu.inner .dropdown-item:hover,.multiple-select .dropdown-menu.inner .dropdown-item:active{ background-color: transparent; }

.settings-content .panel-collapse{ padding-top: 25px; padding-left: 50px;}
.settings-content .panel-collapse .btn{ min-width: 146px; padding: 5px 20px; }
.contact-box{ max-width: 350px; margin: 120px auto 0; width: 100%; }
.contact-box .contact-label{ font-size: 18px; font-weight: 600; color: #828282; }
.contact-box .separator{ border-top: 1px solid #ededed; text-align: center; position: relative; margin: 40px 0 20px 0;}
.contact-box .separator > span{    background: #fff; text-align: center; display: inline-block; position: relative; top: -15px; padding: 0 15px; font-size: 18px; color: #828282; font-weight: 800; }
.contact-box .contact-time{ margin-bottom: 30px; }
.contact-box .contact-time > h5{ font-size: 22px;}
.contact-box .contact-number{background: url(images/phone.svg) no-repeat 95% center; background-size: 26px; padding-right: 42px; }
.contact-box .contact-email{ background: url(images/mail.svg) no-repeat 95% center; background-size: 26px 20px; padding-right: 42px; }
.contact-box .card-body .link2{ font-size: 20px; font-weight: 700; }

.notify-block{ display: none; }

.help-videos-grid{ display: flex; margin: 0 10px; justify-content: space-between; flex-wrap: wrap; }
.help-videos-grid > .video-col{ width:calc(33.3% - 10px); border-radius: 15px; box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06); overflow: hidden; }
.help-videos-grid > .video-col iframe{ border: 0; display: block;  width: 100%; height: 244px; }

.faqs-list{ margin: 0; padding: 30px 0 0 20px ; list-style: none; counter-reset: faqs-counter; }
.faqs-list > li:before{  counter-increment: faqs-counter; content:counter(faqs-counter)".";  font-size: 19px; font-weight: 800;     position: absolute; left: 5px; top: -3px;}
.faqs-list > li{ padding-left: 25px; margin-bottom: 60px; position: relative;  }
.faqs-list > li h6{ font-size: 19px; font-weight: 800; }

.templates-page .table-responsive p{ white-space: normal; margin-bottom: 0; }
.action-dropdown > a{ display: flex;     justify-content: center; transform: rotate(90deg); }
.action-dropdown > a:after{ display: none; }
.action-dropdown .dropdown-menu{  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06);}
.action-dropdown .dropdown-menu > a { display: block; padding: 8px 15px; color: #000; line-height: 28px; }
.action-dropdown .dropdown-menu > a:hover{ background-color:#46b4fe;  color: #fff; }
.templates-page .table-responsive td{ position: relative; }
.templates-page .table-responsive .favourites{ width: 20px; height: 20px; position:absolute; display: block; background-size: cover; background: url(images/favourites-icon.svg) no-repeat center; left: -40px; top:12px; }


.user-role-management-page .site-tab .nav-link{ padding:20px 32px 20px; font-size: 20px; }
.user-role-management-page .site-tab .nav-link:after{ position: absolute; bottom: 0; left: 0; right: 0; margin: 0 auto;  }
.user-role-management-page .panel-title{ display: none; }


.admin-manage-row{ display: flex; justify-content: space-between;}
.admin-manage-row > .admin-manage-col{box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06); background-color: #ffffff; padding-bottom: 50px; margin-bottom: 20px; }
.admin-manage-col .title{ text-transform: uppercase; font-size: 15px; color:#000;   border-bottom: solid 2px #d8d8d8; padding: 25px 15px 25px 34px; font-weight: 600; margin-bottom: 20px; }
.admin-permissions { width:20%; max-width: 100%;}
.admin-permissions > ul{ margin: 0; padding: 0; list-style: none;}
.admin-permissions > ul > li{ display: block;  margin-bottom: 5px;}
.admin-permissions > ul > li > a{ font-size: 14px; color: #000;  padding: 10px 15px 10px 34px; display: block; }
.admin-permissions > ul > li > a:hover{color:#46b4fe; }

.admin-job-title{ width: 20%; max-width: 100%; }
.admin-job-title > ul{ margin: 0; padding: 0; list-style: none;}
.admin-job-title > ul > li{ display: block; margin-bottom: 5px;}
.admin-job-title > ul > li > a{ font-size: 18px; color: #000;  padding: 7px 15px 7px 34px; display: block; display: flex; justify-content: space-between; font-weight: 600; }
.admin-job-title > ul > li > a:hover,.admin-job-title > ul > li.active > a{background:#46b4fe; color: #fff; }
.admin-job-title > ul > li > a .actions{ opacity: 0; transition: all 300ms ease-in-out; color: #fff;}
.admin-job-title > ul > li > a .actions .fas{ font-size: 14px; margin: 0 5px; }
.admin-job-title > ul > li > a:hover .actions,.admin-job-title > ul > li.active > a .actions{ opacity: 1; }
.admin-job-roles{ max-width: 100%; width: calc(60% - 40px); }

.admin-manage-col .permissions-list{  margin: 0; padding: 10px 15px 10px 34px; list-style: none; }
.admin-manage-col .permissions-list > li{ border-bottom: 2px solid #d8d8d8;}
.admin-manage-col .permissions-list > li:last-child{ border-bottom: 0;}
.admin-manage-col .permissions-list > li > h6{ font-size: 18px; font-weight: 600; color: #000; margin-bottom: 0 }
.admin-manage-col .permissions-list > li .custom-checkbox{ display: block;  padding: 20px 30px 20px 0; position: relative;  }
.admin-manage-col .permissions-list > li .permissions-title{ font-size: 18px; font-weight: 700;}
.admin-manage-col .permissions-list > li .checkmark{ position: absolute; right: 0; }

.admin-manage-col .add-job-title{ font-size: 16px; font-weight: 700; }
.custom-checkbox input[type=checkbox]:checked ~ .permissions-title{ color: #46b4fe; }

.manage-roles-row .admin-job-title { width: 25%;}
.manage-roles-row .admin-job-roles { width: calc(75% - 20px); }
#delete-job-title-model h4{ color: #828282; text-align: center; font-size: 18px; } 
#delete-job-title-model h4 span{ color: #000;} 
/* Add -new-css 04-11-2019 */
.create-password h6 { font-size: 16px; margin: 40px 0 30px 0 !important; }
.create-password { margin-bottom: 50px; }
.login-page { height: auto; min-height: 100vh; }
.payment { background: url(images/payment-img.png) no-repeat left bottom; min-height:calc(100vh - 124px); background-size: 488px auto; padding-top: 116px; }
.payment-box { display: flex; align-items: center; background: #fff; justify-content: space-between; padding: 20px 40px; border-radius: 12px; margin-bottom: 28px; box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.03); }
.payment-box .payment-content{ width: calc(100% - 150px);}
.payment-content p { font-size: 14px; color: #828282; margin-bottom: 0;}
.payment-content .price { color: #46b4fe;  margin-bottom: 5px; }
.payment-box .payment-btn .btn{ padding: 6px 24px; font-size: 14px; font-weight: normal;}
.payment-detail h2 { font-size: 36px; color: #000; margin-bottom: 70px; }

.mobile-slider { position: relative; padding: 0 0 20px 0; }
/* .mobile-slider:after { content: ''; background: url(images/mobile-slide.png) no-repeat 0 0; position: absolute; max-width: 474px; width: 100%; height: 100%; top: 0; left: 0; z-index: 2; } */
.app-logo { max-width: 92px; }
.app-logo img { width: 100%; }
.app-slider { max-width: 1200px; margin: 0 auto; }
.app-content span { font-size: 16px; color: #a2a2a2;}
.app-content p { font-size: 30px; color: #000; font-weight: bold; margin-bottom: 0; }
.app-content { margin-left: 20px; }
.mobile-slider-box { max-width: 400px; overflow: hidden; }
.mobile-slider.owl-carousel .item { width: 400px; }
.owl-carousel .owl-item img { width: 100%; display: block; }
.application-detail { display: flex; align-items: center; margin-bottom: 12px; }
.mobile-slider .owl-dots .owl-dot { display: inline-block; border-radius: 50%; background: #d3d3d3; width: 10px; height: 10px; margin: 0 4px;
    -moz-transition: all 450ms cubic-bezier(0.45, 1, 0.32, 1);
    -webkit-transition: all 450ms cubic-bezier(0.45, 1, 0.32, 1);
    -o-transition: all 450ms cubic-bezier(0.45, 1, 0.32, 1);
    -ms-transition: all 450ms cubic-bezier(0.45, 1, 0.32, 1);
    transition: all 450ms cubic-bezier(0.45, 1, 0.32, 1);
}
.mobile-slider .owl-dots .owl-dot.active, .mobile-slider .owl-dots .owl-dot:hover { background-image: linear-gradient(to right, #00f2fe 0%, #03effe 2%, #24d2fe 29%, #3cbdfe 55%, #4ab0fe 80%, #4facfe 100%); }
.mobile-slider .owl-dots { position: absolute; bottom: 30px; z-index: 10; font-size: 0; left: 26px; max-width: 400px; width: 100%; text-align: center; margin: 35px 0 0 0; }
.google-play-store{ margin-bottom: 85px;}
.google-play-store ul li a figure { margin: 0; max-width: 110px; }
.google-play-store ul li { display: inline-block; list-style: none; padding: 0 6px; }
.google-play-store ul {  padding: 0; margin: 0 -12px; }
.app-store-content { margin: 26px 0 32px 0; }
.application-info { margin: 60px 0 0; }
.app-store-content { font-size: 16px; max-width: 500px; }
.application-info .promo-code{ font-size: 16px; font-weight: 300; color: #000;}
.app-store-page { background: #fff; }
.payment-content h3 { font-size: 26px; color: #000; font-weight: 600; margin-bottom: 0; }
.link-sent p { max-width: 470px; margin: 0 auto 20px; font-size: 18px; color: #828282; font-weight: 600; }


/*maintain-licenses*/
.maintain-box {background: #fff; padding:20px 30px; margin-bottom: 30px; box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06); }
.user-month-content span { font-size: 18px; }
.user-month-content span.license-price { font-size: 30px; font-weight: 600; }
.user-license h3 span { font-size: 18px; color: #46b4fe; text-transform: none; padding-left: 6px; font-weight: normal; }
.user-license p {color: #959595; margin-bottom: 8px; }
.user-license h3 { text-transform: uppercase; font-size: 20px; font-weight: 800; }
.user-license-cost h4 { font-size: 16px; color: #000; font-weight: 600;}
.month-price {  color: #474747;  }
.month-price span { font-size: 20px; color: #46b4fe; font-weight: bold; margin-left: 10px; }
.user-license-cost .license-note { font-size: 16px; color: #959595; font-weight: 500; font-style: italic; }
.user-license-cost .license-note span { color: #46b4fe; }
.user-license-cost { text-align: right; }
.user-month-content { margin-bottom: 22px; }
.total-price , .total-amount { display: flex; justify-content: space-between; } 
.total-amount { padding-top: 24px; }
.total-price { border-bottom: 1px solid #c7c7c7; padding-bottom: 14px; }
.total-price .sales-price{ text-align: right; }
.total-price span { display: block; margin-bottom: 10px; }
.sub-total span , .total-amount span { color: #828282; font-size: 20px; font-weight: 600; }
.total-amount span.amount-price { color: #46b4fe; font-size: 26px; font-weight: bold; }
.total-amount .btn{ width:188px; max-width: 100%; }
.sales-price span { font-size: 18px; color: #000; font-weight: 600; }
.license-quantity .quantity-btn{ margin-bottom: 20px;}
.license-quantity .quantity-btn .numbers-row .quantity-button{ width: 42px; height: 42px; background-size: 20px; } 
.license-quantity .quantity-btn .numbers-row .number-icon{ width: 70px;}
.license-quantity .quantity-btn .numbers-row .quantity-button.add{ border-radius:0 4px 4px 0 ; }
.license-quantity .quantity-btn .numbers-row .quantity-button.remove{ border-radius: 4px 0  0 4px; }




/*maintain-licenses-01*/
.payment-dashboard-detail { background: #fff;  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.06); padding: 32px; margin-bottom: 30px; }
.payment-dashboard-detail .payment-label { font-size: 15px; color: #000; text-transform: uppercase; margin-bottom: 24px; font-weight: 600;text-align: center; }
.payment-dashboard-detail .custom-checkbox .status-label { font-size: 14px;}
.order-summary .total-price { padding: 14px 0 4px 0; }
.order-summary .total-price:last-child { border:none; }
.order-summary .total-amount { margin-bottom: 50px; }
.order-summary .total-price + .total-price { border:none; }
.order-summary .btn {  margin-bottom: 10px; width: 300px; max-width: 100%; }
.billing-detail { display: flex; justify-content: space-between; align-items: center; }
.visa-card  { display: flex; flex-wrap: wrap; background: #f8f8f8; padding: 24px 30px; max-width: 700px; align-items: center; margin-top: 20px; }
.visa-card a:hover  { color: #46b4fe; }
.visa-card a ,.visa-card span { color: #000; }
.visa-card a { margin-left: auto; font-size: 16px; }
.visa-card span { padding-left: 10px; font-size: 18px; }
/*.credit-card-detail input.btn-radio { opacity: 0; position: absolute; }
.credit-card-detail input:checked ~ .radio-circle { border:1px solid #46b4fe; }
.credit-card-detail input:checked ~ .radio-circle:after {  transform: scale(1); }
.credit-card-detail input:checked ~  span { color: #46b4fe; }*/
.credit-card-detail .radio-circle {  width: 30px; height: 30px;  }
.credit-card-detail .radio-circle:after { width: 14px; height: 14px; }
.credit-card-detail { margin-bottom: 24px; }
.credit-card-detail .nav-tabs > li:not(:first-child) { margin-left: 50px; }
.credit-card-detail .nav-tabs > li > a { font-size: 20px; font-weight: bold; color: #000; }
.credit-card-detail .nav-tabs > li > a.active { color: #46b4fe; }
.credit-card-detail .nav-tabs > li > a.active .radio-circle { border-color:#46b4fe;  }
.credit-card-detail .nav-tabs > li > a.active .radio-circle:after { transform: scale(1); }
.payment-dashboard .custom-select , .payment-dashboard .enrollment-form .form-group .placeholder-label { color: #959595; }




/*pop-up*/
.right-icon { max-width: 100px; margin: 0 auto; }
.link-sent { text-align: center; background: #fff; }
.applicant-popup .modal-lg{ width: 860px; max-width: 100%;  }
.link-sent p { max-width: 470px; margin: 16px auto 22px; font-size: 18px; color: #828282; font-weight: 600; }
.link-sent h3 { font-size: 26px; color: #000; font-weight: 600; }

.applicant-popup h2 { margin-bottom: 70px; font-size: 36px; color: #000; }
.applicant-popup .btn-send-link { margin-top: 60px; font-weight: 500;  width: 300px; max-width: 100%; }
.applicant-popup .btn-resend-link{ font-weight: 500;  width: 200px; max-width: 100%;}
.enrollment-page .applicant-popup .enrollment-form{ margin: 0 -15px;}
.pop-up-form .enrollment-form .form-group { margin-bottom: 36px; }
.pop-up-form .enrollment-form .placeholder-label { color: #959595; }
.pop-up-form .enrollment-form .custom-select { color: #959595; }
.top-table-search .search-box .form-group { margin-bottom:0; }
.top-table-search .search-box .has-search .form-control { height:44px; }
.top-table-search .search-box .has-search .search-icon { width: 44px; height: 44px; }
.students-profile .EditImage  { margin-bottom:0; }
.students-profile .EditImage .images-placeholder2 { margin-bottom:0 !important; }
div.custom-switch  { padding-left:0; }
.row.pagination-button { right:20px; }
.progress-textbox { font-size: 20px; color:#000; }
.progress-textbox strong { font-weight:600; font-size:26px; display:block; padding-bottom:5px; }
.students-tab-col .ant-tabs-nav, .dashboard-tabcol .ant-tabs-nav { width:100%; }
.students-tab-col .ant-tabs-nav > div, .dashboard-tabcol .ant-tabs-nav > div  {  display:flex;}
.students-tab-col .ant-tabs-nav > div .ant-tabs-tab, .dashboard-tabcol .ant-tabs-nav > div .ant-tabs-tab { flex:1; margin:0 !important; padding:8px 10px !important; text-align:center; justify-content: center;}
.ant-modal-body .ant-radio-group { display:flex; }
.ant-modal-body .ant-radio-group .ant-radio-wrapper { display:flex; align-items:center; margin-right:20px; }
.ant-modal-body .modal-content .btn { min-width:108px; }
.view-profile .ant-tabs-nav-container{background-color:#f8f8f8; color: #b8b8b8;font-weight: 800!important;line-height: 3;}
.view-profile .ant-tabs-tab{font-size: 20px;}
/*-----------Responsive media width queries-----------*/

@media (min-width: 768px) {
  .tab-responsive .panel-title { display: none;  }
  .tab-responsive .panel-collapse.collapse { display: block; }
}


@media ( max-width:1650px){
    .inner-content .value { font-size: 44px; }
}

@media ( max-width:1500px){
 .inner-content .value { font-size: 44px; color: rgb(71, 71, 71); }
 .inner-content .value span{ font-size: 38px;font-weight: 600; color: #000!important;}
 .inner-content .value > em { font-size: 30px;}
 .student-info h2{ font-size: 40px;}
 .students-action-button{ max-width: 400px; }
 .students-profile .students-action-button > .btn { margin-bottom: 10px;}
 .site-tab .nav-link{ padding: 8px 12px; }
  .survery-question-page .validate-pin-box{margin-top: 80px;}
  
  /* Add-new-responsive-css 04-11-2019 */
  .sub-total span, .total-amount span { font-size: 18px; }
 
}

@media ( max-width:1200px){
 .inner-content .value { font-size:24px; }
 .inner-content .value > em { font-size: 18px;}
 .inner-content h6{ font-size: 14px;}
 .course-box{ padding:18px; }
 .header-nav > ul > li{ margin-left: 8px;}
 .header .navbar-light .navbar-nav .nav-link{ font-size: 15px;}
 .container-fluid { padding-left: 15px; padding-right: 15px;}
 .un-Assign-alert{right:8px; top:8px; }
 .students-action-button{ max-width: 100%; }
 .students-profile .students-action-button > .btn { margin-bottom:0;}
 .login-page{ background-size: 600px auto;}
  .top-table-search .search-box{ max-width: 300px;}
  .surveys-card{ width: 100%; max-width: 300px; margin: 0 auto 30px;}
  .survery-question-page .validate-pin-box{margin-top: 70px;}
  .complete-selfie-box .selfie-img{ height: 450px; margin-bottom: 30px;}
  .complete-selfie-box h3{  margin-bottom: 30px;}
  .help-videos-grid > .video-col{ width:calc(50% - 10px); margin-bottom: 20px; }

  /* Add-new-responsive-css 04-11-2019 */
    .payment-box { padding: 20px; }
    .payment{ background-size: 350px auto}
    .payment-content h2 { font-size: 22px; }

    /*pop-up*/
    .link-sent h3 { font-size: 24px; }

    /*maintain-licenses-01*/
    .order-summary .btn { padding: 12px 48px; }
    .payment-dashboard-detail .total-amount span.amount-price { font-size: 22px; }

 

 
}
@media ( max-width:1023px){
  /* Add-new-responsive-css 04-11-2019 */
    s
    .payment-detail h5 { margin-bottom: 50px; font-size: 32px; }
    .payment-content span { font-size: 22px; }
    .mobile-slider.owl-carousel .item { width: auto; }
    .mobile-slider:after { background-size: 100% auto; }
    .mobile-slider-box { margin: 0 auto; }

  }


@media ( max-width:991px){
    .login-page{ background-size: 400px auto; }
    .students-performance-col{ order: 2; }
    .admin-page-link2 li a{ margin-bottom: 35px;}
      .admin-page-link2 li{ width: 25%; }
    .help-videos-grid > .video-col{ width:100%; margin-bottom: 20px; }
    .admin-manage-row{ flex-wrap: wrap; }
    .admin-permissions{ width: 100%; }
    .admin-permissions > ul{ display: flex; flex-wrap: wrap; }
    .admin-permissions > ul > li{ width: 33.3%; }
    .admin-permissions > ul > li > a{ padding: 5px 15px 5px 34px; }
    .manage-job-row  .admin-job-title{  width: 35%;}
    .manage-job-row  .admin-job-roles{    width: calc(65% - 20px);}
    .manage-roles-row .admin-job-title{  width: 35%;}
    .manage-roles-row  .admin-job-roles{    width: calc(65% - 20px);}

    /* Add-new-responsive-css 04-11-2019 */
    .payment-box { margin-bottom: 16px; }
    .payment{ padding-top: 50px;}
    .app-content span { font-size: 15px; }
    .app-content p { font-size: 26px; }
    .mobile-slider-box { margin: 0 auto 34px; }
    .app-store-content { font-size: 15px; margin: 10px 0 20px 0; }
    .application-detail { margin-bottom: 0; }
    .mobile-slider { padding: 0; }
    .user-license-cost{ text-align: left; padding-top: 15px; }

    /*pop-up*/
    .applicant-popup h2 { margin-bottom: 40px; font-size: 32px; }
    .applicant-popup .btn { margin-top: 32px; }
    .pop-up-form .enrollment-form .form-group { margin-bottom: 20px; }
   .applicant-popup .modal-lg{ width: 600px;}
}



@media ( max-width:767px){
 .preference-btn { flex-wrap: wrap; }
 .preference-btn .preference-title{ margin-right: 0; margin-bottom: 10px; width: 100%; text-align: center; }
 .preference-btn .custom-control-label{  width: 135px; }
 .task-box .course-name{ width:100%; margin-bottom: 10px; }
 .task-box .studentinfo > .student-name{ margin-left: 10px;}
 .task-box{ padding: 12px;  }
 .task-box .studentinfo { width: 100%; margin-bottom: 10px;}
 .header{ padding: 0 20px; }
 .header-nav > ul > li{ margin-left: 0; }
 .header .navbar-light .navbar-toggler {border-color: #46b4fe;     outline: none;}
 .header-nav{ border-top: 2px solid rgba(70, 180, 254, 0.3); margin-top: 5px; padding-top: 5px;}
 .course-box .course-date{ width: 50%; }
 .course-box .course-info{ width: 100%; max-width: 100%;  margin-bottom: 10px; }
 .course-box { padding: 12px 12px 55px;}
 .actions-button{ left:8px; bottom: 10px; top:inherit; right: inherit; }
 .view-all { margin-bottom: 15px; }
 .coach-dashboard .search-box{ max-width: 100%;}
 .preference-btn .user-btnbox{ margin-bottom: 10px;}
 .student-info h2{ font-size: 30px; margin-top: 15px; }
 .student-info span.h5{ font-size: 16px;}
 .tab-responsive > .tab-content > .tab-pane { display: block; }
 
 .tab-responsive > .nav-tabs { display: none; }
 .tab-responsive h4.panel-title{ font-size: 16px;  margin-bottom: 2px; background:#f8f8f8;  border:  1px solid #ddd; font-weight: 600; }
 .tab-responsive .panel-collapse{ border:  1px solid #ddd; padding:20px 15px 20px 15px; margin-top: -3px; margin-bottom: 10px; }
 .tab-responsive .tab-pane:last-child h4.panel-title{   border-bottom-color: #ddd;}
 .tab-responsive h4.panel-title a{  padding:12px 12px; display: block; color: #46b4fe;}
 .tab-responsive h4.panel-title a.collapsed{color: #828282;}
 .tab-responsive h4.panel-title a:not(.collapsed) em.no{     background-image: linear-gradient(to right, #00f2fe, #03effe 2%, #24d2fe 29%, #3cbdfe 55%, #4ab0fe 80%, #4facfe); color: #fff;}
 .tab-responsive h4.panel-title em.no {     font-size: 12px; border-radius: 50%; font-style: normal; background: #ececec; width: 24px; height: 24px; display: inline-flex; justify-content: center; align-items: center;  font-weight: 900;}
 .header .navbar-light .navbar-nav .nav-link{ padding: 12px; }
 .note-box .note-date{ text-align:left; }
 .note-box .alert{ top:0 ; margin-top: 10px; display: table; }
 .add-note-form{ margin-bottom: 10px; }
 .students-profile .students-action-button > .btn { margin-bottom: 10px;}
 .students-profile .students-profile-img { margin: 0 auto; }
 .back-link{ font-size: 20px;}
 .back-link .fa-arrow-left {     margin-right: 8px; font-size: 16px;}
  .header{ padding-left: 20px; padding-right: 20px;}
 .pagination .page-link{ margin: 0 3px; }
 .custom-select{  margin-left: 0}
  .login-page{ background-size: 150px auto;  align-items: flex-start; }
  .login-box-main{ padding: 30px 20px 20px 20px; max-width: 100%;  }
  .login-box-main .login-logo{ margin-bottom: 20px; }
  .login-box-main .login-logo img{ max-width: 110px;}
  .login-box-main h5{ font-size: 22px;}
  .top-table-search .search-box{ max-width: 100%;}
  .vertical-tab .tab-content{ max-width: 100%; width: 100% ; padding: 15px;}
  .vertical-tab .site-tab-vertical{ display:none; }
  .pagination-button{ position: static; margin-bottom: 15px; }
  .enrollment-form .form-group{ margin-bottom: 10px; }
  .pagination-button .btn{ min-width: 100px;;}
  .enrollment-form .add-contact{  font-size: 15px; }
  .admin-page-link2 li{ width: 50%; }
  .admin-page{ padding-top: 30px;}
  .admin-page > h1{ margin-bottom: 30px; font-size: 28px; }
  .admin-page-link2 li a{ margin-bottom: 30px;}
  .admin-page-link2 li a span{ font-size: 18px;}
  .admin-page-link2 .admin-link-icon{ width: 130px; height: 130px;}

  .contract-page .add-contact{ padding-top:0; padding-bottom:18px;}
  .survery-question-box h5{ font-size: 18px;}
  .survery-question-box .question-no{ font-size:14px; }
  .survery-question-box .question-no em{ font-size: 18px;}
  .survery-question-box .answer-options > li > span{ padding: 12px; font-size: 15px;}
  .survery-question-page .validate-pin-box{margin-top: 35px;}
  .survery-question-page .validate-pin-box h5{ margin-bottom: 35px;}
  .survery-question-page .validate-pin-box .text-box{ margin-bottom: 35px;}
  .complete-selfie-box .selfie-img{ height:320px; }
  .complete-selfie-box h3{  font-size: 22px;}
  .complete-selfie-box{ padding:30px 0 10px; }
  .help-videos-grid{ padding-top: 15px; }
  .survery-question-box > .card{ min-height: 0;}
 .answer-options .answer-box{ width:100%; padding:15px 10px 10px 10px; margin: 0 0 10px; height: auto; }
 .answer-options .answer-box .icon{ margin-bottom: 10px; width: 40px; height: auto; }
 .answer-options .answer-box strong{ font-size: 14px;}
 .quantity-btn .numbers-row .number-icon{ width: 110px; font-size: 18px;}
 .quantity-btn .numbers-row .quantity-button{ width: 50px; height: 50px; }
 .contact-box{ margin: 30px 0; max-width: 100%;}
 .contact-box .card-body .link2{ font-size: 16px; }
 .contact-box .contact-number{ padding-right: 0}
 .contact-box .contact-label{ font-size: 15px;}
 .contact-box .contact-time{ margin-bottom: 20px; }
 .templates-page .table-responsive .favourites{ position: static; margin-right: 10px; display: inline-flex; }
 .manage-job-row  .admin-job-title{  width:100%;}
 .manage-job-row  .admin-job-roles{    width: 100%;}
 .admin-manage-row > .admin-manage-col{ padding-bottom: 20px;}
 .manage-roles-row .admin-job-title{  width: 100%;}
 .manage-roles-row  .admin-job-roles{    width: 100%;}
 .user-role-management-page .panel-title{ display: block;     font-size: 16px; margin-bottom: 2px; background: #f8f8f8; border: 1px solid #ddd; font-weight: 600;  }
 .user-role-management-page .panel-title a.collapsed { color:#828282;}
 .user-role-management-page .panel-title a{ padding: 12px 12px;  display: block; color: #46b4fe;}
 .user-role-management-page .tab-content>.tab-pane { display: block; }
 
 
 /* Add-new-responsive-css 04-11-2019 */
    .payment-box { display: block; padding: 12px; }
    .payment-box .payment-content{ width: 100%;}
    .payment-box.payment-method { display: flex; align-items: center; }
    .payment-icon figure img{ max-width: 30px;}
        
    
    .payment-content p {  margin-bottom: 10px; }
    .payment-content span { font-size: 20px; } 
    .payment-detail h2 { margin-bottom: 25px; font-size: 26px; } 
    .payment{ background-size: 200px auto !important; min-height: calc(100vh - 90px)}
    .app-content span { font-size: 14px; }
    .app-content p { font-size: 18px; line-height: 1.3; }
    .google-play-store{ margin-bottom: 30px;}
    .app-logo { max-width: 60px; }
    .payment-content h3 { font-size: 18px; }
    
     /*pop-up*/
    .link-sent h3 { font-size: 20px; }
    .link-sent p { margin: 10px auto 12px; font-size: 16px; }
    .link-sent .btn { padding: 8px 30px; }
    
    .enrollment-page .applicant-popup .enrollment-form{ margin: 0;}
    .applicant-popup h2 { margin-bottom: 10px; font-size: 26px; }
    .applicant-popup .modal-lg{ width: auto;}
    .applicant-popup .btn { margin-top: 26px; padding: 8px 60px; } 
    .pop-up-form .enrollment-form .form-group { margin-bottom: 10px; }
    .mobile-slider-box{ max-width:180px;}
    .mobile-slider .owl-dots{ left: 10px;}
    .application-info{ margin-top: 40px; margin-bottom: 40px;}
    .payment-dashboard-detail .sub-total span,.payment-dashboard-detail .total-amount span  ,.payment-dashboard-detail .sales-price span{ font-size: 17px; }
    .sub-total span, .total-amount span{ font-size: 18px;}
    .user-license h3 span{ display: block; padding-top: 10px; padding-left: 0;}
    .payment-dashboard-detail .total-amount span.amount-price { font-size: 17px; }
    .order-summary .total-amount { margin-bottom: 26px; padding-top: 6px; }
    .main-content { margin-bottom: 12px; }
    .visa-card { padding: 20px 12px; }
    .credit-card-detail .custom-radio span { font-size: 17px; } 
    .visa-card a { font-size: 15px; }
    .visa-card span { font-size: 14px; padding-left: 4px; }
    .credit-card-detail .custom-radio { margin-right: 20px; }
    .payment-dashboard-detail { padding: 25px; margin-bottom: 20px; }
   
 
 
}

@media ( max-width:575px){
 .btn { padding: 10px 20px; } 
 .top-table-search .btn { padding: 10px 20px; } 
 
 .modal-content .btn{ min-width: 0; width: 100%; } 
 .students-profile .students-name{ text-align: center; }
 .students-profile .students-action-button > .btn { margin-right: 0; width: 100%; }
 .students-profile .info-detail { font-size: 16px;}
 .students-profile .students-detail .info-label{ font-size: 14px;}
 .settings-content .panel-collapse .btn{ min-width: 120px; }
  .admin-permissions > ul > li{ width: 100%; }

  /* Add-new-responsive-css 04-11-2019 */

  .application-detail { display: block; }
  .app-content { margin-left: 0; margin-top: 14px; }
 
  .payment-dashboard-detail .total-amount span.amount-price { font-size: 14px; }
  .order-summary .btn { padding: 12px 38px; }
  .billing-detail { display: block; }
  .custom-radio { width: 100%; }
  .credit-card-detail .custom-radio { margin-bottom: 14px; }
  .credit-card-detail .nav { display: block; }
  .credit-card-detail .nav-tabs > li:not(:first-child) { margin-left: 0; }
  .credit-card-detail .nav-tabs > li { margin-bottom: 14px; }
  .credit-card-detail .nav-tabs > li > a { font-size: 18px; }

 
}


/*-----------Responsive media height queries-----------*/


@media (max-height:450px) {
    .login-page{ background-image: none;}
    .surveys-card{margin-bottom: 15px;}
    
    
}

.favourites {
  width: 22px;
  height: 20px;
  display: block;
  background-size: cover;
  background: url(images/favourites-icon.svg) no-repeat center;
}

@media (min-width:1023px) {
  .vertical-tabs .ant-tabs-vertical, .ant-tabs-vertical { overflow: inherit; }
  .vertical-tabs .ant-tabs-small-bar, .ant-tabs-vertical .ant-tabs-left-bar { margin-left: -20px;  }
}




